import { sleep } from '../../utils/language/async.ts';
import { Constructor, SecondParameter } from '../../utils/language/types.ts';
import { Server } from '../server/server.ts';
import { Component } from '../component/component.ts';
import { CreateRoomParams, QueueEventParams, RoomApiCapabilities, RoomApiResetParams, RoomApiState, WaitForServerResponseParams } from './room-api-types.ts';
import { RoomEventCallback } from './room-event.ts';
import { RoomManager } from './room-manager.ts';
import { GetRoomClient, Room, RoomClient } from './room.ts';
import { AnimationQueue } from '../animation/animation-queue.ts';
import { RoomWrapper } from './room-wrapper.ts';
import { Collection, CollectionItem, collectionToArray } from '../../utils/language/collection.ts';
import { ClientApiInteraction } from '../client/client-api-interaction.ts';
import { PromiseWithResolvers } from '../../utils/language/promise.ts';
import { GetComponentResolveType, ResolvableComponent, isComponent } from '../component/component-types.ts';
import { GracefulAbort } from '../../utils/language/error.ts';
import { ClientData } from '../client/client-data.ts';
import { NATIVE_PROMPT_SCHEMA, getAnyDataSchema } from '../client/client-data-types.ts';
import { TypeSchemaLike } from '../../utils/type-schema/type-schema-like.ts';
import { ServerData } from '../server/server-data.ts';
import { RoomInfo } from './room-manager-types.ts';
import { ConfigureServerParams } from '../server/server-types.ts';
import { GenericButtonAction, GenericButtonCombination, WaitForUserInputParams, getIsEnabledCallback } from '../user-input/user-input-types.ts';
import { USER_INPUT_SCHEMA, UserInput, UserInputData, unwrapUserInputData, wrapUserInput } from '../user-input/user-input.ts';
import { evalFunction } from '../../utils/language/function.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { ComponentModifier, componentModifierToCallback } from '../component/component-modifier.ts';
import { QueueId } from '../transition/transition-queue.ts';
import { ViewFragmentPriority } from '../view/view-types.ts';

/**
 * Set of methods passed to a {@link Component}'s methods, which give access to the framework's capabilities.
 * @category Core
 */
export class RoomApi extends ClientApiInteraction {
    private roomManager: RoomManager;
    private server: Server | null;
    private capabilities: RoomApiCapabilities = 'none';
    private roomWrapper!: RoomWrapper;
    private interactionPath: string[] | null = null;
    private event: object | null = null;
    private clientId: string | null = null;
    private state: RoomApiState = RoomApiState.Ongoing;
    private source: [Component, string] | null = null;
    private serverData: ServerData[] = [];
    private lastEventPromise: Promise<void> | null = null;
    private didUserInteractFlag: boolean = false;
    private hasStartedFlag: boolean = false;
    private onComplete: PromiseWithResolvers | null = null;
    private localServerResponse: boolean = false;
    private transitionQueueId: QueueId = null;

    constructor(roomManager: RoomManager) {
        let client = roomManager.getClient();
        let server = roomManager.getServer();

        super(client);
        this.roomManager = roomManager;
        this.server = server;
    }

    get roomId(): string {
        return this.roomWrapper.roomId;
    }

    get room(): Room {
        return this.roomWrapper.room;
    }

    get clients(): Map<string, RoomClient> {
        return this.roomWrapper.clients;
    }

    reset(params: RoomApiResetParams): this {
        super.reset(params);
        this.capabilities = params.capabilities;
        this.roomWrapper = params.roomWrapper;
        this.interactionPath = params.interactionPath ?? this.interactionPath;
        this.event = params.event ?? null;
        this.clientId = params.clientId ?? null;
        this.source = params.source ?? null;
        this.state = RoomApiState.Ongoing;
        this.serverData = params.serverData?.slice() ?? [];
        this.lastEventPromise = null;
        this.didUserInteractFlag = false;
        this.onComplete = params.onComplete ?? null;
        this.localServerResponse = false;
        this.hasStartedFlag = false;

        return this;
    }

    destroy() {
        if (!this.preLoadedData && this.client && this.source) {
            this.client.cancelUserInput(this.source);
        }

        super.destroy();
    }

    getState(): RoomApiState {
        return this.state;
    }

    setAborted() {
        this.state = RoomApiState.Aborted;
    }

    getActiveClientId(): string | null {
        return this.roomManager.getActiveClientId();
    }

    getActiveClient(): RoomClient | null {
        return this.roomWrapper.clients.get(this.getActiveClientId() ?? '') ?? null;
    }

    getEvent(): object | null {
        return this.event;
    }

    uuid(): string {
        return crypto.randomUUID();
    }

    emitRoomEvent(eventCallback: (api: RoomApi) => void): void;
    emitRoomEvent<T extends RoomEventCallback>(eventCallback: T, event: SecondParameter<T>, roomId?: string): void;
    emitRoomEvent<T extends RoomEventCallback>(eventCallback: T, event: SecondParameter<T> = undefined as any, roomId: string = this.roomWrapper.roomId): void {
        this.queueEvent({
            methodName: 'emitEvent',
            shouldWaitForCompletion: this.shouldWaitForCompletion(null, roomId),
            dataCallback: () => {},
            eventCallback: () => {
                return this.roomManager.queueEmitRoomEvent({
                    eventData: event,
                    eventPath: [],
                    eventCallback,
                    roomId,
                    serverData: null
                });
            }
        });
    }

    createRoom<R extends Constructor<Room, []>>(roomConstructor: R): string;
    createRoom<R extends Constructor<Room>>(roomConstructor: R, params: CreateRoomParams<R>): string;
    createRoom<R extends Constructor<Room>>(roomConstructor: R, params?: Partial<CreateRoomParams<R>>): string {
        let roomId = this.queueEvent({
            methodName: 'createRoom',
            shouldWaitForCompletion: false,
            dataCallback: (server) => server.generateRoomId(),
            eventCallback: (server, roomId) => {
                let room = new roomConstructor(...params?.constructorArgs ?? []);

                return this.roomManager.queueCreateRoom({ roomId, room });
            }
        });

        for (let client of params?.clients ?? []) {
            this.addClientToRoom(roomConstructor, roomId, client);
        }

        this.startRoom(roomConstructor, roomId);

        return roomId;
    }

    deleteRoom<R extends Room>(roomConstructor: Constructor<R>, roomId: string) {
        return this.queueEvent({
            methodName: 'deleteRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(null, roomId),
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueDeleteRoom({ roomConstructor, roomId })
        });
    }

    startRoom<R extends Room>(roomConstructor: Constructor<R>, roomId: string) {
        return this.queueEvent({
            methodName: 'startRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(null, roomId),
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueStartRoom({ roomConstructor, roomId })
        });
    }

    addClientToRoom<R extends Room>(roomConstructor: Constructor<R>, roomId: string, client: GetRoomClient<R>) {
        return this.queueEvent({
            methodName: 'addClientToRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(client.id, roomId),
            dataCallback: () => {},
            eventCallback: () => {
                let clientId = client.id;
                let clientData = this.roomManager.getSerializer().serialize(client).sliceUint8Array();

                return this.roomManager.queueAddClientToRoom({ roomConstructor, roomId, clientId, clientData });
            }
        });
    }

    removeClientFromRoom<R extends Room>(roomConstructor: Constructor<R>, roomId: string, clientId: string) {
        return this.queueEvent({
            methodName: 'removeClientFromRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(clientId, roomId),
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueRemoveClientFromRoom({ roomConstructor, roomId, clientId })
        });
    }

    authenticateClient(currentClientId: string, newClientId: string) {
        return this.queueEvent({
            methodName: 'authenticateClient',
            shouldWaitForCompletion: this.getActiveClientId() === currentClientId || this.getActiveClientId() === newClientId,
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueAuthenticateClient({ currentClientId, newClientId })
        });
    }

    deauthenticateClient(clientId: string) {
        return this.queueEvent({
            methodName: 'deauthenticateClient',
            shouldWaitForCompletion: this.getActiveClientId() === clientId,
            dataCallback: () => {},
            eventCallback: (server) => {
                let newClientId = server.generateUuid();

                return this.roomManager.queueAuthenticateClient({ currentClientId: clientId, newClientId });
            }
        });
    }

    private queueEvent<T = void>(params: QueueEventParams<T>): T {
        let { methodName, shouldWaitForCompletion, eventCallback, dataCallback } = params;

        this.requireServer(methodName);

        let { eventId, data } = this.retrieveServerData(methodName, server => {
            let data = dataCallback(server);
            let eventId = eventCallback(server, data);

            return { eventId, data };
        });

        if (eventId && shouldWaitForCompletion) {
            this.lastEventPromise = this.roomManager.getEventResolvePromise(eventId);

            // console.log(`wait for: ${methodName}`);
            // this.lastEventPromise.then(() => console.log('ok: ' + methodName));
        }

        return data;
    }

    /**
     * Send the current interaction to the server, causing it to run the same `runInteraction` method on the same component server-side.
     * Items retrieved with {@link RoomApi.waitForUserInput} are sent via their index in `selectableComponents` and `shortcuts`.
     * 
     * If a callback is specified, it is run on the server only, and its return value is returned by `waitForServerResponse` to both the
     * server and the client.
     * 
     * This method the only way to send information from a client to the server. See {@link Component.runInteraction} for more information.
     * @param params 
     * @returns 
     */
    async waitForServerResponse<T = void>(params: (() => T | Promise<T>) | WaitForServerResponseParams<T> = {}): Promise<T> {
        if (!this.interactionPath) {
            throw new Error('cannot call `waitForServerResponse` outside an interaction callback');
        }

        let formattedParams: WaitForServerResponseParams<T> = typeof params === 'function' ? { callback: params } : params;

        let {
            callback,
            local = false
        } = formattedParams;

        this.requireClient('waitForServerResponse', true);
        this.capabilities = 'server';
        this.hasStartedFlag = true;
        this.localServerResponse = local;

        if (this.client && this.getActiveClientId() === this.clientId) {
            let clientData = (await Promise.all((this.savedData ?? []).map(async (data) => await data))).map(x => x) as ClientData[];
            let result = await this.client.sendInteractionToServer({
                clientData: clientData,
                interactionPath: this.interactionPath,
                roomId: this.roomWrapper.roomId,
                onComplete: this.onComplete!
            });

            if (!result.isOk()) {
                let errorMessage = result.errorMessage
                    ? `request failed: "${result.errorMessage}"`
                    : `request failed`;

                console.error(new Error(errorMessage));

                throw new GracefulAbort();
            }

            this.serverData = result.data!;

            return this.loadServerData('response');
        } else if (this.server) {
            let data = await callback?.();

            this.storeServerData('response', data);

            return data as T;
        } else {
            return this.loadServerData('response');
        }
    }

    async waitForLocalServerResponse<T = void>(callback?: () => T | Promise<T>): Promise<T> {
        return this.waitForServerResponse({ callback, local: true });
    }

    isLocalServerResponse(): boolean {
        return this.localServerResponse;
    }

    /**
     * Wait for the user to perform the specified action.
     * This method heavily relies on type inference to return the correct type based on what is specified.
     * You should not specify the type parameters yourself.
     * 
     * It must exclusively be called in {@link Component.runInteraction} and will throw an error if called anywhere else.
     * Check the {@link WaitForUserInputParams} documentation to have more details.
     * @param params 
     * @returns 
     * @example
     * class ChildComponent implements Component { ... }
     * 
     * class FirstComponent implements Component {
     *     items = {
     *         children: new ItemArray<ChildComponent>()
     *     }
     * 
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         // Wait for the user to click on a child
     *         let child = await api.waitForUserInput({
     *             selectableComponents: this.items.children.unwrap(),
     *         });
     * 
     *         // `child` as type `ChildComponent`
     *     }
     * }
     * 
     * class SecondComponent implements Component {
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         let input = await api.waitForUserInput({
     *             captureKeyboard: true
     *         });
     * 
     *         // `input` has type `KeyboardInput`
     *     }
     * }
     * 
     * class ThirdComponent implements Component {
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         let jump = { action: 'jump' };
     *         let shoot = { action: 'shoot' };
     * 
     *         let input = await api.waitForUserInput({
     *             shortcuts: {
     *                 'Space': jump,
     *                 'Enter': shoot,
     *             },
     *             captureScroll: true,
     *         });
     * 
     *         // `input` has type `ScrollInput | { action: string }`
     *     }
     * }
     */
    async waitForUserInput<
        A extends Collection<Component> = never,
        B = never
    // >(params: WaitForUserInputParams<A, B> | ((input: UserInput<never>) => boolean)): Promise<UserInput<ReturnType<typeof getUserInputValue<A, B>>>> {
    >(params: WaitForUserInputParams<A, B>): Promise<UserInput<CollectionItem<A> | B>> {
        this.requireClient('waitForUserInput', true);

        this.didUserInteractFlag = true;

        let fmtParams = (typeof params === 'function' ? { predicate: params } : params) as WaitForUserInputParams<A, B>;
        let result = await this.retrieveClientData<Promise<UserInputData>>({
            kind: 'user-input',
            schema: USER_INPUT_SCHEMA,
            callback: async (client) => {
                let userInput = await client.waitForUserInput(this.source!, this.getPriority(), fmtParams);

                return wrapUserInput(userInput, fmtParams)
            }
        });

        let userInput = unwrapUserInputData(result, fmtParams);
        let selectable = collectionToArray(evalFunction(fmtParams.selectable));
        let isEnabled = getIsEnabledCallback(fmtParams);

        if (fmtParams.checkIsEnabledOn === 'client') {
            isEnabled = () => true;
        }

        if (isComponent(userInput.selection) && selectable.includes(userInput.selection) && !isEnabled(userInput.selection)) {
            throw new GracefulAbort(`component not enabled`);
        }

        this.hasStartedFlag = true;

        return userInput;
    }

    async waitForShortcut<T>(shortcuts: Partial<{ [Key in GenericButtonCombination]: T }>): Promise<UserInput<T>> {
        this.requireClient('waitForShortcut', true);

        return this.waitForUserInput<never, T>({ shortcuts });
    }

    async waitForItemSelection<T extends Collection<Component>>(components: T | (() => T)): Promise<UserInput<CollectionItem<T>>> {
        this.requireClient('waitForItemSelection', true);

        return await this.waitForUserInput<T, never>({
            selectable: components,
        });
    }

    async waitForButtonPress(button?: GenericButtonCombination | GenericButtonCombination[], layerId?: LayerId): Promise<UserInput<never>> {
        return this.waitForButton(button, layerId, 'down');
    }

    async waitForButtonRelease(button?: GenericButtonCombination | GenericButtonCombination[], layerId?: LayerId): Promise<UserInput<never>> {
        return this.waitForButton(button, layerId, 'up');
    }

    private async waitForButton(
        button: undefined | GenericButtonCombination | GenericButtonCombination[],
        layerId: LayerId | undefined,
        action: GenericButtonAction
    ): Promise<UserInput<never>> {
        this.requireClient('waitForKeyPress', true);

        return this.waitForUserInput<never, never>({
            layerId,
            predicate: (input) => {
                if (input.action !== action || !input.combination) {
                    return false;
                }

                if (!button) {
                    return true;
                } else if (typeof button === 'string') {
                    return input.combination === button;
                } else if (Array.isArray(button)) {
                    return button.includes(input.combination);
                } else {
                    // unreachable
                    return false;
                }
            }
        });
    }

    async waitForScroll(): Promise<UserInput<never>> {
        this.requireClient('waitForScroll', true);

        return this.waitForUserInput({
            predicate: (input) => input.action === 'scroll'
        });
    }

    /**
     * Prompt the user for some text using [window.prompt](https://developer.mozilla.org/en-US/docs/Web/API/Window/prompt).
     * If the user cancels the prompt, it returns `null`.
     * 
     * If `localStorageKey` is specified, stores the result in the [local storage](https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage).
     * The next time `prompt` is called wth the same `localStorageKey`, the method will return immediately with the stored value.
     * 
     * The method throws en error if is called server-side, unless it is in {@link Component.runInteraction}
     * _before_ a call to {@link RoomApi.waitForServerResponse}.
     * @param message 
     * @param localStorageKey 
     * @returns 
     */
    nativePrompt(message: string, localStorageKey?: string): string | null {
        this.requireClient('nativePrompt', false);

        return this.retrieveClientData({
            kind: 'native-prompt',
            schema: NATIVE_PROMPT_SCHEMA,
            callback: client => client.prompt(message, localStorageKey)
        });
    }

    getClientData<T>(callback: () => T, schema?: TypeSchemaLike<Awaited<T>>): T {
        this.requireClient('getClientData', false);

        return this.retrieveClientData({
            kind: 'client-data',
            schema: schema ?? getAnyDataSchema(),
            callback
        });
    }

    withActiveClient(callback: () => void) {
        if (this.client && this.clientId === this.getActiveClientId()) {
            callback();
        }
    }

    setQueue(queueId: QueueId): this {
        this.transitionQueueId = queueId;

        return this;
    }

    update<T extends Component = Component>(
        component: Collection<T>,
        callback?: (component: T, index: number) => (ComponentModifier<T> | ComponentModifier<T>[] | null | void)
    ): this {
        let components = collectionToArray(component);
        let sourceId = this.client?.getNextRenderSourceId() ?? 0;

        if (callback) {
            for (let i = 0; i < components.length; ++i) {
                let component = components[i];
                let modifier = callback(component, i);

                if (this.client && modifier) {
                    let view = this.client.updateView(sourceId, component, null);

                    view.addFragment(sourceId, ViewFragmentPriority.Self, componentModifierToCallback(modifier as ComponentModifier<Component>));
                }
            }
        }

        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);
        let views = components.map(component => this.client!.updateView(sourceId, component, null));

        for (let view of views) {
            view.fillSelfFragment(sourceId);
        }

        for (let view of views) {
            view.setTransitionEndTime(queue.usedTransitionEndTime);
            view.enableFragment(sourceId);
            queue.savedTransitionEndTime = Math.max(queue.savedTransitionEndTime, view.getTransitionEndTime());
            queue.usedTransitionEndTime = Math.max(queue.usedTransitionEndTime, view.getTransitionStartTime());
        }

        return this;
    }

    waitForTransition(): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);

        queue.usedTransitionEndTime = queue.savedTransitionEndTime;

        return this;
    }

    waitForDuration(durationMs: number): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);
        
        queue.usedTransitionEndTime += durationMs;

        return this;
    }

    isClientConnected(clientId: string): boolean {
        return this.retrieveServerData('isClientConnected', server => server.isClientConnected(clientId));
    }

    isClientInRoom(clientId: string, roomId: string): boolean {
        return this.retrieveServerData('isClientInRoom', server => this.roomManager.isClientInRoom(clientId, roomId));
    }

    /**
     * Returns whether the component's method is run on the client or the server.
     * @returns 
     */
    getExecutionContext(): 'client' | 'server' {
        if (this.client) {
            return 'client';
        } else {
            return 'server';
        }
    }

    /**
     * Indicates if the method is run on the client.
     * @returns 
     */
    isClientContext(): boolean {
        return !!this.client;
    }

    /**
     * Indicates if the method is run on the server.
     * @returns 
     */
    isServerContext(): boolean {
        return !!this.server;
    }

    getServerCurrentTime(): number {
        return this.retrieveServerData('getServerCurrentTime', server => server.getClock().getCurrentTime());
    }

    getServerTickDuration(): number {
        return this.retrieveServerData('getServerTickDuration', server => server.getClock().getElapsedDurationSinceLastTick());
    }

    getRandomNumber(): number {
        return this.retrieveServerData('getRandomNumber', () => Math.random());
    }

    async prompt<T extends ResolvableComponent>(component: T, schema?: TypeSchemaLike<Awaited<GetComponentResolveType<T>>>): Promise<GetComponentResolveType<T>> {
        this.requireClient('prompt', true);

        return this.getClientData(async () => {
            this.update(component);

            let client = this.client!;
            let data = await component.getResolvePromise();

            client.destroyView(component);

            return data;
        }, schema);
    }

    assert(value: any) {
        if (!value) {
            throw new GracefulAbort();
        }
    }

    configureServer(params: ConfigureServerParams) {
        this.server?.configure(params);
    }

    didUserInteract(): boolean {
        return this.didUserInteractFlag;
    }

    hasStarted(): boolean {
        return this.hasStartedFlag;
    }

    getInteractionCompletionPromise(): Promise<void> | null {
        return this.lastEventPromise;;
    }

    getServerData(): ServerData[] {
        return this.serverData;
    }

    getRoomInfo(roomId: string): RoomInfo | undefined {
        return this.retrieveServerData('getRoomInfo', () => this.roomManager.getRoomInfo(roomId));
    }

    getAllRoomInfo(): RoomInfo[] {
        return this.retrieveServerData('getAllRoomInfo', () => this.roomManager.getAllRoomInfo());
    }

    

    private requireOngoing() {
        if (this.state !== RoomApiState.Ongoing) {
            throw new Error(`RoomApi already destroyed`);
        }
    }

    private requireServer(methodName: string) {
        this.requireOngoing();

        if (!this.server && this.capabilities !== 'server') {
            throw new Error(`\`${methodName}\` can only be called in an event callback, or after calling \`waitForServerResponse\``);
        }
    }

    private requireClient(methodName: string, isUserInteraction: boolean) {
        this.requireOngoing();

        if (this.capabilities !== 'client') {
            throw new Error(`\`${methodName}\` can only be called in an interaction callback, and before calling \`waitForServerResponse\``);
        }

        if (isUserInteraction) {
            this.didUserInteractFlag = true;
        }
    }

    private storeServerData<T>(kind: string, data: T): T {
        this.serverData.push({ kind, data });

        return data;
    }

    private loadServerData(kind: string): any {
        let item = this.serverData.shift();

        if (!item) {
            throw new Error(`server data: no more data to retrieve from`);
        } else if (item.kind !== kind) {
            throw new Error(`server data: expected "${kind}", got "${item.kind}"`);
        }

        return item.data;
    }

    private retrieveServerData<T>(kind: string, callback: (server: Server) => T): T {
        if (this.server) {
            let data = callback(this.server);
            this.storeServerData(kind, data);

            return data;
        } else {
            return this.loadServerData(kind);
        }
    }

    private shouldWaitForCompletion(clientId: string | null, roomId: string | null): boolean {
        let activeClientId = this.roomManager.getActiveClientId();
        let activeRoomWrapper = this.roomManager.getActiveRoomWrapper();

        if (!activeClientId) {
            return false;
        }

        if (clientId && clientId === activeClientId) {
            return true;
        }

        if (roomId && activeRoomWrapper?.roomId === roomId && activeRoomWrapper.clients.has(activeClientId)) {
            return true;
        }

        return false;
    }
}
globalThis.ALL_FUNCTIONS.push(RoomApi);