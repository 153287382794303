import { Client } from '../client/client.ts';
import { Room } from '../room/room.ts';
import { StartGameParams } from '../start-game.ts';
import { View } from '../view/view.ts';
import { setupMockWindow } from './mock.ts';

class MockRoom implements Room {
    render(view: View): void {
        
    }
}

export async function startBot(botCallback: (client: Client) => Promise<void>) {
    if (typeof window !== 'undefined' && !(window as any).MOCK) {
        return;
    }

    setupMockWindow(globalThis);

    let client = new Client({
        createRootRoom: () => new MockRoom()
    });

    await client.start();
    await botCallback(client);
}
globalThis.ALL_FUNCTIONS.push(MockRoom);