import { Vector } from '../../utils/geometry/vector.ts';
import { Collection, collectionToArray, collectionToSet, iterateCollection } from '../../utils/language/collection.ts';
import { GracefulAbort } from '../../utils/language/error.ts';
import { evalFunction } from '../../utils/language/function.ts';
import { mapObjectValues } from '../../utils/language/object.ts';
import { PromiseWithResolvers, makePromise } from '../../utils/language/promise.ts';
import { DEFAULT_DRAG_THRESHOLD } from '../client/client-constants.ts';
import { Client } from '../client/client.ts';
import { ComponentModifier } from '../component/component-modifier.ts';
import { isComponent } from '../component/component-types.ts';
import { Component } from '../component/component.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { ViewModifier } from '../view/view-modifier.ts';
import { GenericButtonName, PressData, USER_INPUT_HIGHLIGHTS, UserInputHighlight, UserInputIsEnabledTarget, UserInputTargetData, UserInputTrigger, WaitForUserInputParams, getIsEnabledCallback } from './user-input-types.ts';
import { UserInput } from './user-input.ts';

export class UserInputEntry {
    client: Client;
    sourceId: number;
    priority: number;
    params: WaitForUserInputParams<any, any>;
    shortcuts: Record<string, any>;
    isComponentEnabled: (component: Component) => boolean;
    checkComponentEnabledOn: UserInputIsEnabledTarget;
    selectionTrigger: UserInputTrigger[];
    selectionButton: GenericButtonName[];
    shortcutTrigger: UserInputTrigger[];
    allowFocus: boolean;
    capture: boolean;
    layerId: LayerId;
    predicate: (input: UserInput) => boolean;
    predicateOnly: boolean;
    selectableComponents: Set<Component> = new Set();
    groups: { [Key in UserInputHighlight]: ViewModifier };
    allModifiers: ViewModifier[];
    hoveredComponent: Component | null = null;
    pressLocations: Map<any, PressData> = new Map();
    focusChain: Component[] = [];
    onComplete: PromiseWithResolvers<UserInput> = makePromise();
    isCompletedFlag: boolean = false;
    textEditionComponent: Component | null = null;
    
    constructor(client: Client, sourceId: number, priority: number, params: WaitForUserInputParams<any, any>) {
        this.client = client;
        this.sourceId = sourceId;
        this.priority = priority;
        this.params = params;
        this.shortcuts = params.shortcuts ?? {};
        this.isComponentEnabled = getIsEnabledCallback(params);
        this.checkComponentEnabledOn = params.checkIsEnabledOn ?? 'both';
        this.selectionTrigger = [...collectionToArray(params.selectionTrigger ?? 'click'), 'unknown'];
        this.selectionButton = collectionToArray(params.selectionButton ?? 'MouseLeft');
        this.shortcutTrigger = collectionToArray(params.shortcutTrigger ?? 'down');
        this.allowFocus = this.selectionTrigger.includes('text');
        this.capture = params.capture ?? false;
        this.layerId = params.layerId ?? null;
        this.predicate = params.predicate ?? (() => true);
        this.predicateOnly = !!params.predicate && !params.selectable && !params.shortcuts;
        this.groups = mapObjectValues(USER_INPUT_HIGHLIGHTS, ([priority, methodName]) => client.addViewModifier({
            modifier: new Function(`view`, `component`, `component.${methodName}?.(view);`) as ComponentModifier,
            priority,
        }));
        this.allModifiers = Object.values(this.groups);
    }

    private resolve(trigger: UserInputTrigger, target: UserInputTargetData, userInput: UserInput) {
        let requiredTrigger = target.isShortcut ? this.shortcutTrigger : this.selectionTrigger;

        if (!requiredTrigger.includes(trigger) || this.isCompletedFlag) {
            return;
        }

        userInput.selection = target.value;
        userInput.trigger = trigger;

        if (!this.predicate(userInput)) {
            return;
        }

        this.isCompletedFlag = true;
        this.onComplete.resolve(userInput);
    }

    cancel() {
        if (this.isCompletedFlag) {
            return;
        }

        this.isCompletedFlag = true;
        this.onComplete.reject(new GracefulAbort());
    }

    destroy() {
        for (let modifier of this.allModifiers) {
            this.client.removeViewModifier(modifier);
        }

        this.client.disableTextEdition(this.sourceId);
    }

    update() {
        let selectableComponents = collectionToSet(evalFunction(this.params.selectable));

        for (let component of selectableComponents) {
            let enabled = this.isComponentEnabled(component);

            if (enabled && !this.groups.enabled.has(component)) {
                this.groups.disabled.delete(component);
                this.groups.enabled.add(component);
            } else if (!enabled && !this.groups.disabled.has(component)) {
                this.groups.enabled.delete(component);
                this.groups.disabled.add(component);
            }
        }

        for (let component of this.selectableComponents) {
            if (!selectableComponents.has(component)) {
                for (let modifier of this.allModifiers) {
                    modifier.delete(component);
                }
            }
        }

        if (this.textEditionComponent && (!this.groups.enabled.has(this.textEditionComponent) || this.textEditionComponent !== this.client.getFocusedComponent())) {
            this.client.disableTextEdition(this.sourceId);
            this.textEditionComponent = null;
        }

        if (this.allowFocus) {
            this.focusChain = [...this.groups.enabled.values()];
        }

        this.selectableComponents = selectableComponents;
    }

    isCompleted(): boolean {
        return this.isCompletedFlag;
    }

    doesCapture(): boolean {
        return this.capture;
    }

    getFocusChain(): Component[] {
        return this.focusChain;
    }

    getPriority(): number {
        return this.priority;
    }

    getResolvePromise(): Promise<UserInput> {
        return this.onComplete.promise;
    }

    getSourceId(): number {
        return this.sourceId;
    }

    processUserInput(userInput: UserInput) {
        let hoveredComponent = this.client.getHoveredComponent();

        userInput.position = this.client.getPointerPosition(this.layerId);

        if (hoveredComponent !== this.hoveredComponent) {
            if (this.hoveredComponent && this.groups.hovered.has(this.hoveredComponent)) {
                this.groups.hovered.delete(this.hoveredComponent);
                this.resolve('unhover', { value: this.hoveredComponent }, userInput);
            }

            if (hoveredComponent && this.groups.enabled.has(hoveredComponent)) {
                this.groups.hovered.add(hoveredComponent);
                this.resolve('hover', { value: hoveredComponent }, userInput);
            }

            this.hoveredComponent = hoveredComponent;
        }

        let target = this.getTarget(userInput);

        if (target && target.isShortcut && userInput.nativeEvent) {
            userInput.nativeEvent.preventDefault();
        }

        if (userInput.action === 'down' && target) {
            this.pressLocations.set(target.value, {
                value: target.value,
                position: this.client.getRealPointerPosition(),
                timestamp: this.client.getCurrentTime(),
            });
            this.resolve('down', target, userInput);
        }

        if (userInput.action === 'up' && target) {
            if (this.pressLocations.has(target.value)) {
                this.resolve('click', target, userInput);
            }

            this.resolve('up', target, userInput);
            this.pressLocations.delete(target.value);
        }

        if (userInput.action === 'move') {
            let pressed = this.getPressedValue();

            if (!pressed && target) {
                this.resolve('move', target, userInput);
            }

            if (pressed && pressed.position.getDistanceTo(this.client.getRealPointerPosition()) >= DEFAULT_DRAG_THRESHOLD) {
                this.resolve('drag', pressed, userInput);
            }
        }

        if (userInput.action === 'text' && this.textEditionComponent) {
            this.resolve('text', { value: this.textEditionComponent }, userInput);
        }

        if (this.predicateOnly && this.predicate(userInput)) {
            this.resolve('unknown', { value: undefined }, userInput);
        }
    }

    processFocusInput(userInput: UserInput, prevFocus: Component | null, newFocus: Component | null) {
        if (prevFocus && this.groups.focused.has(prevFocus)) {
            this.groups.focused.delete(prevFocus);
            this.resolve('unfocus', { value: prevFocus }, userInput);
        }

        if (newFocus && this.groups.enabled.has(newFocus)) {
            this.groups.focused.add(newFocus);
            this.resolve('focus', { value: newFocus }, userInput);

            if (this.selectionTrigger.includes('text') && typeof newFocus.getText === 'function') {
                this.client.enableTextEdition(this.sourceId, this.priority, newFocus.getText());
                this.textEditionComponent = newFocus;
            }
        }
    }

    private getPressedValue(): PressData | null {
        if (this.pressLocations.size === 0) {
            return null;
        } else {
            for (let data of this.pressLocations.values()) {
                return data;
            }

            throw new Error(`unreachable`);
        }
    }

    private getTarget(userInput: UserInput): UserInputTargetData | null {
        let value: any = undefined;
        let isShortcut: boolean = false;

        if (userInput.combination && userInput.combination in this.shortcuts) {
            value = this.shortcuts[userInput.combination];
            isShortcut = true;
        }

        if (isComponent(value) && !this.groups.enabled.has(value)) {
            value = undefined;
        }

        let isSelectionButton = userInput.button && this.selectionButton.includes(userInput.button);

        if (value === undefined && isSelectionButton && this.hoveredComponent && this.groups.enabled.has(this.hoveredComponent)) {
            value = this.hoveredComponent;
            isShortcut = false;
        }

        if (value !== undefined) {
            return { value, isShortcut };
        } else {
            return null;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(UserInputEntry);