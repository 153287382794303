import { removeItem } from './index.ts';

declare global {
    interface Array<T> {
        remove(item: T): boolean;
    }
}

Array.prototype.remove = function (item) {
    return removeItem(this, item);
};