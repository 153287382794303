import { LobbyPlayer } from './lobby-player.ts';
import { HubRoom } from '../hub-room/hub-room.ts';
import { LOBBY_PLAYER_COLORS } from './lobby-constants.ts';
import { GameHubParams } from '../hub.ts';
import { BaseHubRoom } from '../base-hub-room.ts';
import { RoomApi } from '../../framework/room/room-api.ts';
import { Room } from '../../framework/room/room.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { Label } from '../../helpers/widgets/label.ts';

export type LobbyRoomParams = {
    name: string;
};

export class LobbyRoom extends BaseHubRoom implements Room<LobbyPlayer> {
    nameLabel = new Label();
    players: LobbyPlayer[] = [];
    startButton = new Button('Start');
    leaveButton = new Button('Leave');
    host: LobbyPlayer | null = null;

    constructor(baseParams: GameHubParams, params: LobbyRoomParams) {
        super(baseParams);
        this.nameLabel.setText(params.name);
        this.startButton.setStyle(this.params.buttonStyle);
        this.leaveButton.setStyle(this.params.buttonStyle);
    }

    private refresh(api: RoomApi) {
        for (let i = 0; i < this.players.length; ++i) {
            let player = this.players[i];

            player.isHost = i === 0;
            player.color = LOBBY_PLAYER_COLORS[i % LOBBY_PLAYER_COLORS.length];
        }

        this.host = this.players[0] ?? null;

        api.render();
    }

    onClientAdded(api: RoomApi, player: LobbyPlayer): void {
        this.players.push(player);
        this.refresh(api);
    }

    onClientRemoved(api: RoomApi, player: LobbyPlayer): void {
        this.players.remove(player);
        this.refresh(api);
    }

    render(view: View) {
        if (this.params.renderLobbyRoom) {
            this.params.renderLobbyRoom(view, this);
        } else {
            this.defaultRender(view);
        }
    }

    defaultRender(view: View): void {
        view.addChild(this.nameLabel, view.rect.fromTopInwards('*', '20%'));

        view.addGrid({
            items: this.players,
            rect: rect => rect.fromCenter('100%', '70%'),
            columnSize: 4,
            rowSize: [1, Infinity],
            fillFirst: 'column',
            itemAspectRatio: 6,
            horizontalAlign: 'left'
        });

        let buttons = [this.leaveButton];

        if (this.host?.id === view.getActiveClientId()) {
            buttons.push(this.startButton);
        }

        view.layout()
            .bottomLeftToRight()
            .horizontalAlign('center')
            .childAspectRatio(4)
            .childHeight('10%')
            .margin('5%')
            .addChild(buttons);
    }

    async $leaveLobbyInteraction(api: RoomApi, player: LobbyPlayer) {
        await api.waitForItemSelection(this.leaveButton);
        await api.waitForServerResponse();

        api.removeClientFromRoom(LobbyRoom, api.roomId, player.id);
    }

    async $startGameInteraction(api: RoomApi, player: LobbyPlayer) {
        await api.waitForItemSelection(this.startButton);
        await api.waitForServerResponse();

        api.assert(player.isHost);

        let gameId = api.createRoom(this.params.gameConstructor);

        for (let player of this.players) {
            api.addClientToRoom(this.params.gameConstructor, gameId, new this.params.playerConstructor(player.id));
        }

        if ('start' in this.params.gameConstructor.prototype) {
            api.emitRoomEvent(this.params.gameConstructor.prototype.start, undefined, gameId);
        }

        api.deleteRoom(LobbyRoom, api.roomId);
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyRoom);