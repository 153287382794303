import { DisplaySize, DisplaySizeLike } from '../geometry/display-size.ts';
import { Rect, RectLike } from '../geometry/rect.ts';
import { NonFunction } from '../language/types.ts';
import { LAYOUT_ANCHOR_TO_VALUES, LayoutAnchor } from './layout-anchor.ts';
import { LayoutDirection } from './layout-direction.ts';
import { LayoutHorizontalAlign, horizontalAlignToNumber } from './layout-horizontal-align.ts';
import { LayoutItem, ObjectToLayout } from './layout-item.ts';
import { LayoutVerticalAlign, verticalAlignToNumber } from './layout-vertical-align.ts';

export type GetLayoutItem<L> = L extends Layout<infer T, infer P> ? LayoutItem<T, P> : never;

/**
 * Base layout class. It is the parent class of {@link ViewLayout}.
 * 
 * All methods returns the layout itself, so they can easily be chained.
 * The layout always keeps track of two things:
 * - The current *item*
 * - The current *container*, on which child items will be added
 * 
 * Methods affect either one or the other. When the layout is created, the root rectangle
 * is both the current item and the current container.
 */
export class Layout<T, P extends NonFunction> {
    protected rootRect: Rect | null = null;
    protected rootList: LayoutItem<T, P>[] = [];
    protected currentRoot: LayoutItem<T, P> | null = null;
    protected currentContainer: LayoutItem<T, P> | null = null;
    protected currentItem: LayoutItem<T, P> | null = null;

    constructor() {
        this.initRoot();
    }

    /**
     * Set the root rectangle for the layout. By default, it is the rectangle of the view that created the layout.
     * @param rect 
     * @returns 
     */
    setRootRect(rect: RectLike): this {
        this.rootRect = Rect.from(rect);
        return this;
    }

    private initRoot() {
        if (this.currentRoot) {
            return;
        }

        this.currentRoot = new LayoutItem<T, P>(null, null, null);
        this.currentRoot.direction = "left-to-right";
        this.currentRoot.outerMargin = this.rootList.at(-1)?.outerMargin || 0;
        this.currentContainer = this.currentRoot;
        this.currentItem = this.currentRoot;

        this.rootList.push(this.currentRoot);
    }

    compute(defaultRootRect?: Rect): ObjectToLayout<T, P>[] {
        let displayRect = this.rootRect ?? defaultRootRect ?? Rect.ZERO;

        let result: ObjectToLayout<T, P>[] = [];

        for (let root of this.rootList) {
            let width = DisplaySize.resolve(root.width ?? 0, displayRect, 'scaledFromParentWidth');
            let height = DisplaySize.resolve(root.height ?? 0, displayRect, 'scaledFromParentHeight');
            let aspectRatio = root.aspectRatio;

            if (displayRect.isZero()) {
                if (width && height) {
                    displayRect = displayRect.withSize(width, height);
                } else if (width && aspectRatio) {
                    displayRect = displayRect.withSize(width, width / aspectRatio);
                } else if (height && aspectRatio) {
                    displayRect = displayRect.withSize(height * aspectRatio, height);
                }
            } else {
                if (width) {
                    displayRect = displayRect.withWidth(width);
                }

                if (height) {
                    displayRect = displayRect.withHeight(height);
                }
            }

            root.setRect(displayRect, displayRect);
            root.collect(result);
        }

        return result;
    }

    protected push(object: T, properties: P | ((properties: P) => P)): this {
        if (!this.currentContainer) {
            return this;
        }

        let container = this.currentContainer;
        this.currentItem = new LayoutItem<T, P>(container, object);
        this.currentItem.force = container.childForce;
        this.currentItem.aspectRatio = container.childAspectRatio;
        this.currentItem.width = container.childWidth;
        this.currentItem.height = container.childHeight;

        if (typeof properties === 'function') {
            this.currentItem.properties = properties(this.currentItem.properties!);
        } else {
            this.currentItem.properties = properties;
        }

        container.children.push(this.currentItem);

        return this;
    }

    /**
     * Make the current container's parent both the current container and item.
     * Basically goes back one step up in the tree.
     * @returns 
     */
    parent(): this {
        if (this.currentContainer) {
            this.currentContainer = this.currentContainer.parent || this.currentContainer;
            this.currentItem = this.currentContainer;
        }

        return this;
    }

    /**
     * Set the current container's direction, specifying in which direction child items will be added.
     * @param direction 
     * @returns 
     */
    direction(direction: LayoutDirection): this {
        this.currentContainer = this.currentItem;
        this.currentItem!.direction = direction;

        if (this.currentItem!.kind === 'stack') {
            this.currentItem!.kind = 'line';
        }

        return this;
    }

    /**
     * Set the current container's horizontal align, specifying where its children should be
     * positionned horizontally if they don't fill its entire width.
     * 
     * If a number is specified, it must be a value between 0 (left) and 1 (right).
     * @param horizontalAlign 
     * @returns 
     */
    horizontalAlign(horizontalAlign: number | LayoutHorizontalAlign): this {
        this.currentItem!.horizontalAlign = horizontalAlignToNumber(horizontalAlign);
        return this;
    }

    /**
     * Set the current container's vertical align, specifying where its children should be
     * positionned vertically if they don't fill its entire width.
     * 
     * If a number is specified, it must be a value between 0 (top) and 1 (bottom).
     * @param horizontalAlign 
     * @returns 
     */
    verticalAlign(verticalAlign: number | LayoutVerticalAlign): this {
        this.currentItem!.verticalAlign = verticalAlignToNumber(verticalAlign);
        return this;
    }

    /**
     * Calls {@link Layout.horizontalAlign} and {@link Layout.verticalAlign} at the same time.
     * @param anchor 
     * @returns 
     */
    anchor(anchor: LayoutAnchor): this {
        let { x, y } = LAYOUT_ANCHOR_TO_VALUES[anchor];

        if (this.currentItem!.kind === 'stack') {
            this.currentItem!.kind = 'line';
        }

        this.horizontalAlign(x);
        this.verticalAlign(y);

        return this;
    }

    /**
     * Set the current item's force, indicating the width or height (depending on the layout direction) it should fill.
     * 
     * This setting only has meaning relative to other items: an item with a force of 4 will take twice as much space
     * as an item with a force of 2. A single item with even a force of 0.1 will take all the available space.
     * 
     * The force is ignore when specifying a {@link Layout.width} or {@link Layout.height}.
     * @param force 
     * @returns 
     */
    force(force: number): this {
        let item = this.currentItem!;

        item.force = force;
        item.aspectRatio = null;
        item.width = null;
        item.height = null;
        return this;
    }

    /**
     * Set the current item's width. If a relative size is passed, it is relative to the root rectangle.
     * @param width 
     * @returns 
     */
    width(width: DisplaySizeLike): this {
        this.currentItem!.width = width;
        this.currentItem!.childWidth ??= '100%';
        return this;
    }

    /**
     * Set the current item's height. If a relative size is passed, it is relative to the root rectangle.
     * @param width 
     * @returns 
     */
    height(height: DisplaySizeLike): this {
        this.currentItem!.height = height;
        this.currentItem!.childHeight ??= '100%';
        return this;
    }

    /**
     * Set the current items's aspect ratio (its width / height ratio).
     * It is ignored if both `width` and `height` have been specified.
     * @param aspectRatio 
     * @returns 
     */
    aspectRatio(aspectRatio: number): this {
        this.currentItem!.aspectRatio = aspectRatio;
        // TODO: unset width or height
        return this;
    }

    scale(scale: number): this {
        this.currentItem!.scale = scale;
        return this;
    }

    /**
     * Specify the current container's child force, i.e the [force](ViewLayout.html#force) that will be assigned
     * to future items added with {@link ViewLayout.addChild}.
     * It doesn't change the force of items already added.
     * @param force 
     * @returns 
     */
    childForce(force: number): this {
        let item = this.currentItem!;

        item.childForce = force;
        item.childAspectRatio = null;
        item.childWidth = null;
        item.childHeight = null;
        return this;
    }

    /**
     * Specify the current container's child width, i.e the [force](ViewLayout.html#width) that will be assigned
     * to future items added with {@link ViewLayout.addChild}.
     * It doesn't change the force of items already added.
     * @param width 
     * @returns 
     */
    childWidth(width?: DisplaySizeLike): this {
        if (width !== undefined) {
            this.currentContainer!.childWidth = width;
        }

        return this;
    }

    /**
     * Specify the current container's child height, i.e the [force](ViewLayout.html#height) that will be assigned
     * to future items added with {@link ViewLayout.addChild}.
     * It doesn't change the height of items already added.
     * @param height 
     * @returns 
     */
    childHeight(height?: DisplaySizeLike): this {
        if (height !== undefined) {
            this.currentContainer!.childHeight = height;
        }

        return this;
    }

    /**
     * Specify the current container's child aspect ratio, i.e the [aspect ratio](ViewLayout.html#aspectRatio) that will be assigned
     * to future items added with {@link ViewLayout.addChild}.
     * It doesn't change the aspect ratio of items already added.
     * @param aspectRatio 
     * @returns 
     */
    childAspectRatio(aspectRatio?: number): this {
        if (aspectRatio !== undefined) {
            this.currentContainer!.childAspectRatio = aspectRatio;
        }

        // TODO: unset width or height
        return this;
    }

    /**
     * Set the current container's inner margin, i.e the empty space between its items.
     * @param innerMargin 
     * @returns 
     */
    innerMargin(innerMargin: DisplaySizeLike): this {
        this.currentContainer!.innerMargin = innerMargin;
        return this;
    }

    /**
     * Set the current container's outer margin, i.e the empty space around it.
     * @param outerMargin 
     * @returns 
     */
    outerMargin(outerMargin: DisplaySizeLike): this {
        this.currentContainer!.outerMargin = outerMargin;
        return this;
    }

    /**
     * Set both {@link ViewLayout.outerMargin} and {@link ViewLayout.innerMargin}.
     * @param margin 
     * @returns 
     */
    margin(margin: DisplaySizeLike): this {
        return this
            .innerMargin(margin)
            .outerMargin(margin);
    }

    /**
     * Indicate to layout the current container as a grid.
     * @param direction 
     * @returns 
     */
    grid(direction?: LayoutDirection): this {
        this.currentContainer = this.currentItem;
        this.currentContainer!.kind = 'grid';

        if (direction) {
            this.currentContainer!.direction = direction;
        }

        return this;
    }

    /**
     * Set the number of items that the current container can container in a row.
     * This is ignored if {@link ViewLayout.grid} has not been called before.
     * 
     * You can pass `Infinity` to the second argument to have no upper limit of items in a row.
     * @param minRowSize 
     * @param maxRowSize 
     * @returns 
     */
    rowSize(minRowSize: number, maxRowSize: number = minRowSize): this {
        this.currentContainer!.rowSize.min = minRowSize;
        this.currentContainer!.rowSize.max = maxRowSize;
        
        return this;
    }

    /**
     * Set the number of items that the current container can container in a column.
     * This is ignored if {@link ViewLayout.grid} has not been called before.
     * 
     * You can pass `Infinity` to the second argument to have no upper limit of items in a column.
     * @param minColumnSize 
     * @param maxColumnSize 
     * @returns 
     */
    columnSize(minColumnSize: number, maxColumnSize: number = minColumnSize): this {
        this.currentContainer!.columnSize.min = minColumnSize;
        this.currentContainer!.columnSize.max = maxColumnSize ?? minColumnSize;
        
        return this;
    }

    /**
     * If the current container is a grid, set the number of rows or columns (depending on the direction)
     * that should be skipped. This can be useful when implementing a scrolling mechanism for a grid that can
     * only display a fixed number of items.
     * @param scroll 
     * @returns 
     */
    scroll(scroll: number): this {
        this.currentContainer!.scroll = scroll;
        return this;
    }

    ignoreEmptyCells(ignoreEmptyCells: boolean): this {
        this.currentContainer!.ignoreEmptyCells = ignoreEmptyCells;
        return this;
    }

    /**
     * Reset the layout, making the root rectangle the current item and container.
     * Items that have already been added will still be positionned.
     * @returns 
     */
    reset(): this {
        this.currentRoot = null;
        this.currentContainer = null;
        this.currentItem = null;
        this.initRoot();
        return this;
    }

    private bulkConfig(direction: LayoutDirection, horizontalAlign: number, verticalAlign: number): this {
        return this
            .direction(direction)
            .horizontalAlign(horizontalAlign)
            .verticalAlign(verticalAlign);
    }

    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from center to right.
     * @returns 
     */
    centerToRight(): this { return this.bulkConfig('left-to-right', 0.5, 0.5); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from center to top.
     * @returns 
     */
    centerToLeft(): this { return this.bulkConfig('right-to-left', 0.5, 0.5); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from center to bottom.
     * @returns 
     */
    centerToBottom(): this { return this.bulkConfig('top-to-bottom', 0.5, 0.5); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from center to top.
     * @returns 
     */
    centerToTop(): this { return this.bulkConfig('bottom-to-top', 0.5, 0.5); }

    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from left to right.
     * @returns 
     */
    leftToRight(): this { return this.bulkConfig('left-to-right', 0, 0.5); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from right to left.
     * @returns 
     */
    rightToLeft(): this { return this.bulkConfig('right-to-left', 0, 0.5); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from top to bottom.
     * @returns 
     */
    topToBottom(): this { return this.bulkConfig('top-to-bottom', 0.5, 0); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from bottom to top.
     * @returns 
     */
    bottomToTop(): this { return this.bulkConfig('bottom-to-top', 0.5, 0); }

    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from top-left to top-right.
     * @returns 
     */
    topLeftToRight(): this { return this.bulkConfig('left-to-right', 0, 0); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from top-right to top-left.
     * @returns 
     */
    topRightToLeft(): this { return this.bulkConfig('right-to-left', 0, 0); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from bottom-left to bottom-right.
     * @returns 
     */
    bottomLeftToRight(): this { return this.bulkConfig('left-to-right', 0, 1); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from bottom-right to bottom-left.
     * @returns 
     */
    bottomRightToLeft(): this { return this.bulkConfig('right-to-left', 0, 1); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from top-left to bottom-left.
     * @returns 
     */
    topLeftToBottom(): this { return this.bulkConfig('top-to-bottom', 0, 0); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from bottom-left to top-left.
     * @returns 
     */
    bottomLeftToTop(): this { return this.bulkConfig('bottom-to-top', 0, 0); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from top-right to bottom-right.
     * @returns 
     */
    topRightToBottom(): this { return this.bulkConfig('top-to-bottom', 1, 0); }
    /**
     * Calls {@link ViewLayout.direction}, {@link ViewLayout.horizontalAlign} and {@link ViewLayout.verticalAlign}
     * to display the current container's items from bottom-right to top-right.
     * @returns 
     */
    bottomRightToTop(): this { return this.bulkConfig('bottom-to-top', 1, 0); }
}
globalThis.ALL_FUNCTIONS.push(Layout);