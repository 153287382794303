import { getHorizontalAlignMultiplier } from '../../../utils/geometry/horizontal-align.ts';
import { getVerticalAlignMultiplier } from '../../../utils/geometry/vertical-align.ts';
import { clamp } from '../../../utils/language/math.ts';
import { GraphicsEngine } from '../graphics-engine.ts';
import { Loop } from '../../../utils/time/loop-rule.ts';
import { GraphicsAttributeList } from './graphics-attribute-list.ts';
import { ANIMATED_GRAPHICS_ATTRIBUTES, AnimatedGraphicsAttributeDetails, GRAPHICS_METADATA, Graphics, getGraphicsAttributeMetadata } from './graphics.ts';
import { EASING_TO_BITS, FILL_DIRECTION_TO_BITS, GLSL_TYPE_TO_BIT, IMAGE_FIT_TO_BITS, LOOP_TO_BITS, MODIFIER_OPERATION_TO_BITS, ROTATION_DIRECTION_TO_BITS } from './graphics-constants.ts';
import { Easing } from '../../../utils/time/easing.ts';
import { GraphicsAttribute, GraphicsAttributeOperation } from './graphics-attribute-types.ts';
import { GraphicsAttributeDetails } from './graphics-attribute-details.ts';

export const MAX_GLSL_ATTRIBUTE_COUNT = 64;

export class GraphicsLoader {
    private graphicsEngine: GraphicsEngine;
    private attributeCount: number = 0;
    private headerBuffer: Float32Array = new Float32Array(3);
    private headerBufferSize: number = 0;
    private attributeMetadata: Uint16Array = new Uint16Array(MAX_GLSL_ATTRIBUTE_COUNT);
    private attributeDataBuffer: Float32Array = new Float32Array(this.attributeMetadata.length * 2);
    private attributeDataBufferSize: number = 0;

    constructor(graphicsEngine: GraphicsEngine) {
        this.graphicsEngine = graphicsEngine;
    }

    private reset(): this {
        this.attributeCount = 0;
        this.headerBufferSize = 0;
        this.attributeMetadata.fill(0);
        this.attributeDataBufferSize = 0;

        return this;
    }

    loadGraphics(attributes: GraphicsAttributeList, imageId: string | null): number {
        let graphicsEngine = this.graphicsEngine;
        let layerId = attributes.getLayerId();
        let dataTexture = graphicsEngine.getAttributesDataTexture(layerId);

        this.reset();

        for (let kind of ANIMATED_GRAPHICS_ATTRIBUTES) {
            let attribute = attributes.getBodyDetails(kind);

            if (attribute) {
                this.packAttribute(kind, attribute);
            }
        }

        for (let kind of ANIMATED_GRAPHICS_ATTRIBUTES) {
            let modifier = attributes.getModifierDetails(kind);

            if (modifier) {
                this.packAttribute(kind, modifier);
            }
        }

        this.packHeader(attributes, imageId);

        if (this.attributeCount === 0) {
            return 0;
        }

        let blockSize = Math.ceil(this.headerBufferSize + this.attributeDataBufferSize + this.attributeCount / 2);
        let attributeStartPointer = dataTexture.allocate(blockSize);
        let ptr = attributeStartPointer;

        for (let i = 0; i < this.headerBufferSize; ++i) {
            dataTexture.setValue(ptr++, this.headerBuffer[i]);
        }

        for (let i = 0; i < this.attributeCount; i += 2) {
            let a = this.attributeMetadata[i];
            let b = this.attributeMetadata[i + 1];
            let floatMetadata = graphicsEngine.encodeUint16PairToFloat32(a, b);

            dataTexture.setValue(ptr++, floatMetadata);
        }

        for (let i = 0; i < this.attributeDataBufferSize; ++i) {
            let value = this.attributeDataBuffer[i];

            dataTexture.setValue(ptr++, value);
        }

        return attributeStartPointer;
    }

    private packHeader(attributes: GraphicsAttributeList, imageId: string | null) {
        let hasImage = !!imageId;
        let layerId = attributes.getLayerId();
        let fillPercentRadialDirection = attributes.requireValue('fillPercentRadialDirection');
        let fillPercentLinearDirection = attributes.requireValue('fillPercentLinearDirection');
        let imageFit = attributes.requireValue('imageFit');
        let horizontalAlign = attributes.requireValue('horizontalAlign');
        let verticalAlign = attributes.requireValue('verticalAlign');
        let [spriteSheetRowSize, spriteSheetColumnSize] = this.graphicsEngine.getImageSpriteSheetSize(imageId)

        if (attributes.requireValue('text') !== null) {
            imageFit = 'raw';
        }

        let attributeCountBits = this.attributeCount;

        let hasImageBits = +hasImage;
        let fillPercentRadialDirectionBits = ROTATION_DIRECTION_TO_BITS[fillPercentRadialDirection];
        let fillPercentLinearDirectionBits = FILL_DIRECTION_TO_BITS[fillPercentLinearDirection];
        let imageFitBits = IMAGE_FIT_TO_BITS[imageFit];
        let horizontalAlignBits = getHorizontalAlignMultiplier(horizontalAlign) * 2 | 0;
        let verticalAlignBits = getVerticalAlignMultiplier(verticalAlign) * 2 | 0;
        let spriteSheetRowSizeBits = clamp(spriteSheetRowSize, 0, 127) | 0;
        let spriteSheetColumnsSizeBits = clamp(spriteSheetColumnSize, 0, 127) | 0;
        let bits =
            (attributeCountBits << 24) +
            (spriteSheetRowSizeBits << 17) +
            (spriteSheetColumnsSizeBits << 10) +
            (horizontalAlignBits << 8) +
            (verticalAlignBits << 6) +
            (imageFitBits << 4) +
            (fillPercentLinearDirectionBits << 2) +
            (fillPercentRadialDirectionBits << 1) +
            hasImageBits;

        this.headerBuffer[this.headerBufferSize++] = this.graphicsEngine.encodeUint32ToFloat32(bits);

        if (imageId) {
            this.headerBuffer[this.headerBufferSize++] = this.graphicsEngine.encodeImageTextureCoordsToFloat32(layerId, imageId);
            this.headerBuffer[this.headerBufferSize++] = this.graphicsEngine.encodeImageTextureSizeToFloat32(layerId, imageId);
        }
    }

    private packAttribute<K extends keyof Graphics>(key: K, attribute: AnimatedGraphicsAttributeDetails<K>) {
        let { encodeFunction, type, attributeIndex } = getGraphicsAttributeMetadata(key).glsl!;
        let isAnimated = this.packAttributeData<any>(attribute, encodeFunction);
        let isAnimatedBit = isAnimated ? 1 : 0;
        let operationBits = MODIFIER_OPERATION_TO_BITS[attribute.operation];
        let typeBits = GLSL_TYPE_TO_BIT[type];
        let metadataByte = (attributeIndex << 8) + (isAnimatedBit << 6) + (operationBits << 3) + typeBits;

        this.attributeMetadata[this.attributeCount] = metadataByte;
        this.attributeCount += 1;
    }

    private packAttributeData<T>(
        attribute: GraphicsAttributeDetails<T>,
        valueToFloat: (x: T, graphicsEngine: GraphicsEngine) => number,
    ): boolean {
        let graphicsEngine = this.graphicsEngine;


        if (attribute.duration === 0 || attribute.start === attribute.end) {
            this.attributeDataBuffer[this.attributeDataBufferSize++] = valueToFloat(attribute.end, graphicsEngine);

            return false;
        }

        let startValue = attribute.start === undefined ? NaN : valueToFloat(attribute.start, graphicsEngine);
        let endValue = valueToFloat(attribute.end, graphicsEngine);

        let durationAndDelayBetweenLoopsBits = (attribute.duration << 16) + attribute.delayBetweenLoops;
        let durationAndDelayBetweenLoopsValue = graphicsEngine.encodeUint32ToFloat32(durationAndDelayBetweenLoopsBits);

        let loopBits = LOOP_TO_BITS[attribute.loop];
        let easingBits = EASING_TO_BITS[attribute.easing as Exclude<Easing, Function>];
        let reverseBit = +attribute.reverse;
        let startTimeAndFlagsBits = ((attribute.startTime + attribute.delay) << 8) + (easingBits << 4) + (loopBits << 1) + reverseBit;
        let startTimeAndFlags = graphicsEngine.encodeUint32ToFloat32(startTimeAndFlagsBits);

        this.attributeDataBuffer[this.attributeDataBufferSize++] = startValue;
        this.attributeDataBuffer[this.attributeDataBufferSize++] = endValue;
        this.attributeDataBuffer[this.attributeDataBufferSize++] = durationAndDelayBetweenLoopsValue;
        this.attributeDataBuffer[this.attributeDataBufferSize++] = startTimeAndFlags;

        return true;
    }
}
globalThis.ALL_FUNCTIONS.push(GraphicsLoader);