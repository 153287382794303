import { AnimationQueue } from '../animation/animation-queue.ts';
import { LoadImageParams, ConfigureRendererParams, CreateSpriteSheetParams } from '../graphics-engine/graphics-engine-types.ts';
import { Component } from '../component/component.ts';
import { ClientApiActionParams } from './client-api-types.ts';
import { ClientApiReading } from './client-api-reading.ts';
import { AnimationQueueDummy } from '../animation/animation-queue-dummy.ts';
import { Collection, iterateCollection } from '../../utils/language/collection.ts';
import { LayerId, LayerProperties } from '../graphics-engine/layer-types.ts';
import { INSTANCE_ANIMATION_QUEUE_ID, NON_BLOCKING_ANIMATION_QUEUE_ID } from './client-types.ts';

export class ClientApiAction extends ClientApiReading {
    private dummyAnimationQueue: AnimationQueue = new AnimationQueueDummy() as AnimationQueue;

    reset(params?: ClientApiActionParams): void {
        super.reset(params);
        // console.log(this.dummyAnimationQueue.constructor.prototype)
    }

    destroy(): void {
        super.destroy();
    }

    /* CONFIGURATION */

    async loadImage(imageUrl: string, params: LoadImageParams) {
        return this.client?.getGraphicsEngine().loadImage(imageUrl, params);
    }

    async createSpriteSheet(params: CreateSpriteSheetParams) {
        return this.client?.getGraphicsEngine().createSpriteSheet(params);
    }

    configureRenderer(params: ConfigureRendererParams = {}) {
        this.client?.getGraphicsEngine().configureRenderer(params);
    }

    /* ANIMATIONS */

    getAnimationQueue(queueId: number = 0): AnimationQueue {
        return this.client?.getAnimationQueue(queueId) ?? this.dummyAnimationQueue;
    }

    instant(): AnimationQueue {
        return this.client?.getAnimationQueue(INSTANCE_ANIMATION_QUEUE_ID) ?? this.dummyAnimationQueue;
    }

    now(): AnimationQueue {
        return this.client?.getAnimationQueue(NON_BLOCKING_ANIMATION_QUEUE_ID) ?? this.dummyAnimationQueue;
    }

    queue(queueId: number = 0): AnimationQueue {
        return this.client?.getAnimationQueue(queueId).queue() ?? this.dummyAnimationQueue;
    }

    render(component?: Collection<Component>): void {
        this.client?.renderComponent(component ?? this.client.getActiveRoom());
    }

    unmount(component: Collection<Component>): void {
        this.client?.unmountComponent(component);
    }

    initLayers<T extends string | number>(layers: { [Key in T]: Partial<LayerProperties> }) {
        for (let [layerId, properties] of Object.entries(layers)) {
            this.client?.initLayer(layerId, properties as Partial<LayerProperties>);
        }
    }

    updateLayer(layerId: Collection<LayerId>, properties: Partial<LayerProperties>): this {
        for (let item of iterateCollection(layerId)) {
            this.client?.updateLayer(item, properties);
        }
        return this;
    }

    /* FOCUS */

    /**
    * Focus the specified component. If `null` is passed, clear the focus.
    * @param component 
    */
    setFocus(component: Component | null) {
        this.client?.setFocusedComponent(component);
    }

    /**
     * Focus the next component in the focus chain.
     * The focus chain is constructed from the `selectableComponents` and `requiredFocusedComponent` properties
     * of the different interactions started with {@link RoomApi.waitForUserInput}.
     */
    focusNext() {
        this.client?.focusNext();
    }

    /**
     * Focus the previous component in the focus chain.
     * The focus chain is constructed from the `selectableComponents` and `requiredFocusedComponent` properties
     * of the different interactions started with {@link RoomApi.waitForUserInput}.
     */
    focusPrev() {
        this.client?.focusPrev();
    }

    clearFocus() {
        this.client?.setFocusedComponent(null);
    }
}
globalThis.ALL_FUNCTIONS.push(ClientApiAction);