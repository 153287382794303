import { isFunctionBodyEmpty } from '../../utils/language/function.ts';
import { ParametersExceptFirst } from '../../utils/language/types.ts';
import { AggregatedServerData } from './room-manager-types.ts';
import { RoomWrapper } from './room-wrapper.ts';
import { Room, RoomClient, RoomHookMethodName } from './room.ts';

export const GLOBAL_HOOK_METHOD_NAMES = <const>[
    'onRoomCreated',
    'onRoomDeleted',
    'onClientAddedToRoom',
    'onClientRemovedFromRoom',
    'onUpdate',
] satisfies RoomHookMethodName[];

export type GlobalHookmethodName = typeof GLOBAL_HOOK_METHOD_NAMES[number];

export class RoomGlobalHookManager {
    private enabled: boolean = true;
    private rooms: { [Key in GlobalHookmethodName]: Set<RoomWrapper> } = {
        onRoomCreated: new Set(),
        onRoomDeleted: new Set(),
        onClientAddedToRoom: new Set(),
        onClientRemovedFromRoom: new Set(),
        onUpdate: new Set(),
    };

    setEnabled(value: boolean) {
        this.enabled = value;
    }

    notifyRoomCreated(roomWrapper: RoomWrapper) {
        if (!this.enabled) {
            return;
        }

        for (let methodName of GLOBAL_HOOK_METHOD_NAMES) {
            if (typeof roomWrapper.room[methodName] === 'function' && !isFunctionBodyEmpty(roomWrapper.room[methodName])) {
                this.rooms[methodName].add(roomWrapper);
            }
        }
    }

    notifyRoomDeleted(roomWrapper: RoomWrapper) {
        if (!this.enabled) {
            return;
        }

        for (let methodName of GLOBAL_HOOK_METHOD_NAMES) {
            this.rooms[methodName].delete(roomWrapper);
        }
    }

    triggerHook<K extends GlobalHookmethodName>(
        aggregatedServerData: AggregatedServerData | null,
        methodName: K,
        excludedRoomId: string,
        ...args: ParametersExceptFirst<Room[K]>
    ): RoomWrapper[] {
        let result: RoomWrapper[] = [];

        for (let roomWrapper of this.rooms[methodName]) {
            if (roomWrapper.roomId !== excludedRoomId) {
                roomWrapper.runHookMethod(aggregatedServerData, methodName, ...args);

                result.push(roomWrapper);
            }
        }

        return result;
    }

    triggerClientHook<K extends GlobalHookmethodName>(
        aggregatedServerData: AggregatedServerData | null,
        methodName: K,
        excludedRoomId: string,
        clientId: string,
        args: (client: RoomClient) => ParametersExceptFirst<Room[K]>
    ): RoomWrapper[] {
        let result: RoomWrapper[] = [];

        for (let roomWrapper of this.rooms[methodName]) {
            let client = roomWrapper.clients.get(clientId);

            if (client && roomWrapper.roomId !== excludedRoomId) {
                roomWrapper.runHookMethod(aggregatedServerData, methodName, ...args(client));

                result.push(roomWrapper);
            }
        }

        return result;
    }

    clear() {
        for (let methodName of GLOBAL_HOOK_METHOD_NAMES) {
            this.rooms[methodName].clear();
        }
    }

    getRoomsWithMethod(methodName: GlobalHookmethodName): Set<RoomWrapper> {
        return this.rooms[methodName];
    }
}
globalThis.ALL_FUNCTIONS.push(RoomGlobalHookManager);