import { Counter } from '../../utils/data-structures/counter.ts';
import { Point } from '../../utils/geometry/point.ts';
import { PromiseWithResolvers } from '../../utils/language/promise.ts';
import { Result } from '../../utils/language/result.ts';
import { Component } from '../component/component.ts';
import { EventParams } from '../room/room-manager-types.ts';
import { ServerData } from '../server/server-data.ts';

export class ComponentStateInfo {
    component: Component | null = null;
    pointer: Point | null = null;
    timestamp: number = 0;

    constructor(params?: Partial<ComponentStateInfo>) {
        Object.assign(this, params);
    }
}

export type ProcessInteractionResultParams = {
    requestId: number;
    result: Result<ServerData[]>;
};

export type AnimationQueueId = string | number | Symbol;

export const NON_BLOCKING_ANIMATION_QUEUE_ID = Symbol();
export const INSTANCE_ANIMATION_QUEUE_ID = Symbol();

export type ClientOngoingRequest = {
    resumeCallback: (result: Result<ServerData[]>) => void;
    onComplete: PromiseWithResolvers;
};
globalThis.ALL_FUNCTIONS.push(ComponentStateInfo);