import { Component, View } from 'outpost';
import { FIRE_WAVE_RECT } from './constants.ts';

export class FireWave implements Component {
    render(view: View): void {
        view.paint({
            rect: FIRE_WAVE_RECT,
            color: 'tomato',
        });
    }
}
globalThis.ALL_FUNCTIONS.push(FireWave);