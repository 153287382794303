import { HubRoom } from '../hub-room/hub-room.ts';
import { HubPlayer } from '../hub-room/hub-player.ts';
import { BaseHubRoom } from '../base-hub-room.ts';
import { FocusController } from '../../helpers/components/focus-controller.ts';
import { RoomApi } from '../../framework/room/room-api.ts';
import { Room, RoomClient } from '../../framework/room/room.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { Label } from '../../helpers/widgets/label.ts';
import { TextBox } from '../../helpers/widgets/textbox.ts';

export class LoginRoom extends BaseHubRoom implements Room {
    titleLabel = new Label();
    usernameTextbox = new TextBox('Username');
    loginButton = new Button('Login');
    mainRoomId: string = '';
    autoLogin: boolean = false;

    onCreated(api: RoomApi): void {
        this.mainRoomId = api.createRoom(HubRoom, {
            constructorArgs: [this.params],
            clients: []
        });
        this.autoLogin = this.params.autoLogin ?? true;
    }

    onMount(api: RoomApi): void {
        setTimeout(() => api.focusNext(), 100);
        api.configureRenderer(this.params.renderer);
        this.titleLabel.setText(this.gameName);
        this.loginButton.setStyle(this.params.buttonStyle);
        api.render(new FocusController());
    }

    render(view: View) {
        if (this.params.renderLoginRoom) {
            this.params.renderLoginRoom(view, this);
        } else {
            this.defaultRender(view);
        }
    }

    defaultRender(view: View): void {
        view.layout()
            .centerToBottom()
            .width('30%')
            .innerMargin('5%')
            .childAspectRatio(5)
            .addChild(this.usernameTextbox)
            .addChild(this.loginButton)
            .scale(0.9);

        view.addChild(this.titleLabel, view.rect.fromTopInwards('*', '20%'));
    }

    async $loginInteraction(api: RoomApi, player: RoomClient) {
        let storedUsername = api.getClientData(() => window.localStorage.getItem('username'));
        
        if (!this.autoLogin) {
            storedUsername = null;
        }

        if (!storedUsername) {
            await api.waitForUserInput({
                selectable: this.loginButton,
                shortcuts: { 'Enter': this.loginButton },
                isEnabled: () => this.usernameTextbox.getText().content.length > 0,
                checkIsEnabledOn: 'client'
            });
        }

        let username = storedUsername || api.getClientData(() => this.usernameTextbox.getText().content);

        if (!username || username.length === 0) {
            return;
        }

        await api.waitForLocalServerResponse();

        if (api.isClientConnected(username)) {
            api.withActiveClient(() => {
                console.log(`client "${username}" is already connected`);
                window.localStorage.removeItem('username')
            });
            return;
        }

        if (this.autoLogin) {
            api.withActiveClient(() => window.localStorage.setItem('username', username));
        }

        api.addClientToRoom(HubRoom, this.mainRoomId, new HubPlayer(username));
        api.authenticateClient(player.id, username);
    }
}
globalThis.ALL_FUNCTIONS.push(LoginRoom);