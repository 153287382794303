import { Rect } from '../../utils/geometry/rect.ts';
import { GraphicsAttributeList } from '../graphics-engine/graphics/graphics-attribute-list.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';

export enum ViewFragmentPriority {
    Parent = -2,
    Self,
    Default = 0,
    Dimmed,
    Highlighted,
    Disabled,
    Focused,
    Hovered,
    Pressed
}

export enum ViewState {
    Pending,
    Alive,
    Dead
}

export type ViewTooltipOptions = {
    showDelay?: number;
    hideDelay?: number;
};

export type ViewAtom = {
    key: string;
    index: number;
    attributes: GraphicsAttributeList;
};

export type ViewBasicAttributes = {
    rect: Rect;
    layerId: LayerId;
    detectable: boolean;
};

export function getDefaultBasicAttributes(): ViewBasicAttributes {
    return {
        layerId: null,
        detectable: true,
        rect: Rect.zero()
    };
}