import { Easing } from '../../../utils/time/easing.ts';
import { Loop } from '../../../utils/time/loop-rule.ts';
import { GraphicsAttribute, GraphicsAttributeOperation, isAddGraphicsAttribute, isAnimatedGraphicsAttribute, isMultGraphicsAttribute } from './graphics-attribute-types.ts';

export type GraphicsAttributeDetails<T> = {
    start: T | undefined;
    end: T;
    operation: GraphicsAttributeOperation;
    startTime: number;
    duration: number;
    delay: number;
    delayBetweenLoops: number;
    loop: Loop;
    easing: Easing;
    reverse: boolean;
};

function identity<T>(x: T): T {
    return x;
}

export function destructureGraphicsAttribute<T>(
    value: GraphicsAttribute<T>,
    startTime: number,
    defaultDuration: number | undefined,
    defaultDelay: number | undefined,
    defaultEasing: Easing | undefined,
    defaultToEndValue: boolean,
):GraphicsAttributeDetails<T> {
    return destructureGraphicsAttributesMapped(value, identity, startTime, defaultDuration, defaultDelay, defaultEasing, defaultToEndValue);
}

export function destructureGraphicsAttributesMapped<T, U>(
    value: GraphicsAttribute<T>,
    mapFunction: (value: T) => U,
    defaultStartTime: number,
    defaultDuration: number | undefined,
    defaultDelay: number | undefined,
    defaultEasing: Easing | undefined,
    defaultToEndValue: boolean,
): GraphicsAttributeDetails<U> {
    let startTime = defaultStartTime;
    let operation: GraphicsAttributeOperation = 'set';
    let duration: number = defaultDuration ?? 0;
    let delay: number = defaultDelay ?? 0;
    let delayBetweenLoops: number = 0;
    let loop: Loop = 'clamp';
    let easing: Easing = defaultEasing ?? 'linear';
    let reverse: boolean = false;
    let start: U | undefined = undefined;
    let end: U;

    if (!value || typeof value !== 'object' || !(isAnimatedGraphicsAttribute(value) || isMultGraphicsAttribute(value) || isAddGraphicsAttribute(value))) {
        end = mapFunction(value);
        start = end;
    } else {
        startTime = value.startTime ?? startTime;
        duration = value.duration ?? duration;
        delay = value.delay ?? delay;
        delayBetweenLoops = value.delayBetweenLoops ?? delayBetweenLoops;
        loop = value.loop ?? loop;
        easing = value.easing ?? easing;
        reverse = value.reverse ?? reverse;

        if (isAddGraphicsAttribute(value)) {
            end = mapFunction(value.add);
            operation = 'add';
        } else if (isMultGraphicsAttribute(value)) {
            end = mapFunction(value.mult);
            operation = 'mult';
        } else {
            end = mapFunction(value.end);
            start = value.start !== undefined ? mapFunction(value.start) : (defaultToEndValue ? end : undefined);
            operation = value.operation ?? 'set';
        }
    }

    return { start, end, operation, startTime, duration, delay, delayBetweenLoops, loop, easing, reverse };
}
