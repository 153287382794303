import { Plugin, PluginBuild } from 'esbuild';
import { readFileSync } from 'fs';

export const GLOBAL_FUNCTION_ARRAY_NAME = 'ALL_FUNCTIONS';

const CLASS_DECLARATION_REGEXP = /(?<=(\n|^))\s*(export\s+)?class\s+\w+(\s*<[^>]*>)?(\s+extends\s+\w+(\s*<[^>]*>)?)?(\s+implements\s+(\w+(\s*<[^>]*>)?\s*(,\s*)?)+)*\s*{/g;
const FUNCTION_DECLARATION_REGEXP = /(?<=(\n|^))(export\s+)?(async\s+)?function\s+\w+(\s*<[^>]*>)?\s*\(?/g;

export function functionDeclarationHookPlugin(): Plugin {
    return {
        name: 'class-declaration-hook',
        setup(build: PluginBuild) {
            build.onLoad({ filter: /\.(ts|js)$/ }, async (args) => {
                let text = readFileSync(args.path, 'utf8');
                let declaredClassNames = getDeclaredFunctionNames(text, CLASS_DECLARATION_REGEXP, 'class', '{');
                let declaredFunctionNames: string[] = [];

                // if (!args.path.includes('node_modules')) {
                //     declaredFunctionNames = getDeclaredFunctionNames(text, FUNCTION_DECLARATION_REGEXP, 'function', '(');
                // }

                let declaredNames = [...declaredClassNames, ...declaredFunctionNames];

                if (declaredNames.length > 0) {
                    text += declaredNames.map(name => `\nglobalThis.${GLOBAL_FUNCTION_ARRAY_NAME}.push(${name});`).join('');
                }

                return {
                    contents: text,
                    loader: 'ts',
                };
            });
        }
    };
}

function getDeclaredFunctionNames(text: string, regexp: RegExp, keyword: string, delimiter: string): string[] {
    let matches = text.match(regexp);

    if (!matches) {
        return [];
    }

    return matches.map(str => {
        let i = str.indexOf(keyword) + keyword.length;

        while (str[i] === ' ') {
            i += 1;
        }

        let start = i;

        while (str[i] !== ' ' && str[i] !== delimiter && str[i] !== '<') {
            i += 1;
        }

        let end = i;
        let className = str.substring(start, end);

        return className;
    });
}