import { LobbyRoom } from '../lobby-room/lobby-room.ts';
import { LobbyPlayer } from '../lobby-room/lobby-player.ts';
import { LobbyPreview } from '../lobby-room/lobby-preview.ts';
import { HubPlayer } from './hub-player.ts';
import { BaseHubRoom } from '../base-hub-room.ts';
import { RoomApi } from '../../framework/room/room-api.ts';
import { RoomClientListWrapper } from '../../framework/room/room-client-list-wrapper.ts';
import { Room, RoomClient } from '../../framework/room/room.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { Label } from '../../helpers/widgets/label.ts';

export class HubRoom extends BaseHubRoom implements Room<HubPlayer> {
    players = new RoomClientListWrapper<HubPlayer>();
    titleLabel = new Label();
    createGameButton = new Button('Create game');
    joinGameButton = new Button('Join game');
    backButton = new Button('Back');
    logoutButton = new Button('Logout');
    usernameLabel = new Label();
    lobbies: Map<string, LobbyPreview> = new Map();
    showLobbies: boolean = false;
    autoJoin: boolean = false;
    autoJoinRoomId: string | null = null;
    autoJoinRoomStarted: boolean = false;

    onCreated(api: RoomApi): void {
        this.autoJoin = this.params.autoJoin ?? false;

        if (this.autoJoin) {
            this.autoJoinRoomId = api.createRoom(this.params.gameConstructor);
        }
    }

    onMount(api: RoomApi): void {
        api.configureRenderer(this.params.renderer);
        this.usernameLabel.setText(api.getActiveClientId()!);
        this.titleLabel.setText(this.gameName);
    }

    onClientAddedToRoom(api: RoomApi, roomId: string, player: HubPlayer): void {
        let roomInfo = api.getRoomInfo(roomId)!;

        if (roomInfo.roomConstructor === this.params.gameConstructor) {
            player.gameId = roomId;
        }
    }

    onClientRemovedFromRoom(api: RoomApi, roomId: string, player: HubPlayer): void {
        if (player.gameId === roomId) {
            player.gameId = null;
        } else if (player.lobby?.id === roomId) {
            player.lobby.playerCount -= 1;

            if (player.lobby.playerCount === 0) {
                this.deleteLobby(api, player.lobby.id);
            }

            player.lobby = null;
        }
    }

    onClientAdded(api: RoomApi, player: HubPlayer) {
        if (this.autoJoin) {
            api.addClientToRoom(this.params.gameConstructor, this.autoJoinRoomId!, new this.params.playerConstructor(player.id))

            if (!this.autoJoinRoomStarted && 'onStart' in this.params.gameConstructor.prototype) {
                api.emitRoomEvent(this.params.gameConstructor.prototype.onStart, undefined, this.autoJoinRoomId!);
            }

            this.autoJoinRoomStarted = true;
        }
    }

    render(view: View) {
        if (this.params.renderHubRoom) {
            this.params.renderHubRoom(view, this);
        } else {
            this.defaultRender(view);
        }
    }

    defaultRender(view: View): void {
        view.addChild(this.titleLabel, view.rect.fromTopInwards('*', '20%'));

        if (this.showLobbies) {
            this.renderLobbies(view);
        } else {
            this.renderHome(view);
        }

        view.addChild(this.usernameLabel, view.rect.fromTopRightInwards(100, 70, 10));
    }

    private renderHome(view: View) {
        view.layout()
            .centerToBottom()
            .width('30%')
            .innerMargin('5%')
            .childAspectRatio(5)
            .addChild(this.createGameButton)
            .addChild(this.joinGameButton)
            .scale(0.9);

        view.addChild(this.logoutButton, view.rect.fromTopLeftInwards(100, 50, 10));
    }

    private renderLobbies(view: View) {
        view.addGrid({
            rect: rect => rect.fromBottomInwards('*', '80%'),
            items: this.lobbies.values(),
            rowSize: 2,
            columnSize: [4, Infinity],
            itemAspectRatio: 5,
            margin: 5,
            verticalAlign: 'top',
        });

        view.addChild(this.backButton, view.rect.fromTopLeftInwards(100, 50, 10));
    }

    async $createLobbyInteraction(api: RoomApi, player: HubPlayer) {
        await api.waitForUserInput({
            selectable: this.createGameButton,
            isEnabled: () => player.lobby === null
        });

        await api.waitForServerResponse();

        let lobby = this.createLobby(api, `Game of ${player.id}`);

        this.joinLobby(api, player, lobby);

        api.render();
    }

    private createLobby(api: RoomApi, lobbyName: string): LobbyPreview {
        let lobbyId = api.createRoom(LobbyRoom, {
            constructorArgs: [this.params, {
                name: lobbyName
            }]
        });

        let lobbyPreview = new LobbyPreview(this.params, {
            id: lobbyId,
            name: lobbyName
        });

        this.lobbies.set(lobbyId, lobbyPreview);

        return lobbyPreview;
    }

    async $displayLobbiesInteraction(api: RoomApi) {
        await api.waitForUserInput({
            selectable: this.joinGameButton,
            isEnabled: () => this.lobbies.size > 0
        });

        this.showLobbies = true;
        api.render();
    }

    async $hideLobbiesInteraction(api: RoomApi) {
        await api.waitForItemSelection(this.backButton);

        this.showLobbies = false;
        api.render();
    }

    async $joinLobbyInteraction(api: RoomApi, player: HubPlayer) {
        let lobbies = [...this.lobbies.values()];
        let input = await api.waitForUserInput({
            selectable: () => lobbies.map(lobby => lobby.joinButton)
        });
        let lobby = lobbies.find(lobby => lobby.joinButton === input.selection)!;

        await api.waitForServerResponse();

        api.withActiveClient(() => this.showLobbies = false);

        this.joinLobby(api, player, lobby);
    }

    private joinLobby(api: RoomApi, player: HubPlayer, lobby: LobbyPreview) {
        player.lobby = lobby;
        lobby.playerCount += 1;

        api.addClientToRoom(LobbyRoom, lobby.id, new LobbyPlayer(player.id));

        if (api.getActiveClientId() !== player.id) {
            api.render();
        }
    }

    async $logoutInteraction(api: RoomApi, client: RoomClient) {
        await api.waitForItemSelection(this.logoutButton);

        api.withActiveClient(() => window.localStorage.removeItem('username'));

        await api.waitForServerResponse();

        api.deauthenticateClient(client.id);
    }

    private deleteLobby(api: RoomApi, lobbyId: string) {
        this.lobbies.delete(lobbyId);
        api.deleteRoom(LobbyRoom, lobbyId);
    }
}
globalThis.ALL_FUNCTIONS.push(HubRoom);