import { Line } from '../geometry/line.ts';
import { Point } from '../geometry/point.ts';
import { Rect, RectLike } from '../geometry/rect.ts';
import { Vector } from '../geometry/vector.ts';

export type BodyShape = 'circle' | 'rectangle';

export class Body {
    shape: BodyShape = 'rectangle';
    angle: number = 0;
    width: number = 1;
    height: number = 1;
    position: Point = Point.zero();
    velocity: Vector = Vector.zero();

    private static fromShape(shape: BodyShape, rectLike: RectLike): Body {
        let rect = Rect.from(rectLike);
        let body = new Body();

        body.shape = shape;
        body.position = rect.getCenter();
        body.width = rect.width;
        body.height = rect.height;

        return body;
    }

    static rectangle(rect: RectLike) {
        return Body.fromShape('rectangle', rect);
    }

    static circle(rect: RectLike) {
        return Body.fromShape('circle', rect);
    }

    getRect(): Rect {
        return new Rect(this.position.x, this.position.y, this.width, this.height);
    }

    getPoints(): Point[] {
        let w = this.width / 2;
        let h = this.height / 2;
        let x1 = this.position.x - w;
        let y1 = this.position.y - h;
        let x2 = this.position.x + w;
        let y2 = this.position.y + h;

        return [
            new Point(x1, y1),
            new Point(x2, y1),
            new Point(x2, y2),
            new Point(x1, y2),
        ];
    }

    getLines(): Line[] {
        let lines: Line[] = [];
        let points = this.getPoints();
        let current = points[0];

        for (let i = 0; i < points.length; ++i) {
            let next = points[(i + 1) % points.length];
            let line = new Line(current.x, current.y, next.x, next.y);

            lines.push(line);
            current = next;
        }

        return lines;
    }
}
globalThis.ALL_FUNCTIONS.push(Body);