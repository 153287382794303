#version 300 es

precision highp float;
precision lowp sampler2DArray;

#define PI 3.1415926535897932384626433832795
const float EPSILON = 0.000001;

// UNIFORMS START
uniform float u_currentTime;
uniform vec2 u_realCanvasSize;
uniform float u_virtualToRealRatio;
uniform vec2 u_cameraCenter;
uniform float u_cameraZoom;
uniform vec2 u_viewportCenter;
uniform sampler2DArray u_imageTexture;
uniform float u_imageColorReplaceDistanceThreshold;
uniform sampler2D u_attributesDataTexture;
// UNIFORMS END

// VARYINGS START
in vec4 v_color;
in float v_strokeSize;
in float v_borderRadius;
in float v_borderSharp;
in float v_fillPercentRadial;
in float v_fillPercentRadialDirection;
in float v_fillPercentRadialStartAngle;
in float v_fillPercentLinearProgress;
in float v_fillPercentLinear;
in vec2 v_distanceToTopLeft;
in vec2 v_rectSize;
in vec3 v_imageTextureCoords;
in vec4 v_imageReplaceSourceColor;
in vec4 v_imageReplaceTargetColor;
in float v_alpha;
in float v_brightness;
in float v_grayscale;
in vec4 v_tint;
in vec4 v_componentId;
// VARYINGS END

layout(location = 0) out vec4 out_color;
layout(location = 1) out vec4 out_componentId;

// https://stackoverflow.com/questions/1724946/blend-mode-on-a-transparent-and-semi-transparent-background
vec4 blend(in vec4 dstColor, in vec4 srcColor, in float t) {
    vec3 srcRgb = srcColor.rgb;
    vec3 dstRgb = dstColor.rgb;
    float srcAlpha = srcColor.a * t;
    float dstAlpha = dstColor.a;

    float alpha = srcAlpha + dstAlpha * (1. - srcAlpha);
    vec3 rgb = srcRgb * srcAlpha + dstRgb * dstAlpha * (1. - srcAlpha);    

    return vec4(rgb / alpha, alpha);
}

vec4 replaceColor(in vec4 color, in vec4 srcColor, in vec4 dstColor) {
    float d = distance(color.rgb, srcColor.rgb);

    if (dstColor.a == 0. || d > u_imageColorReplaceDistanceThreshold) {
        return color;
    }

    return vec4(dstColor.rgb, dstColor.a * color.a);
}

// https://stackoverflow.com/questions/15095909/from-rgb-to-hsv-in-opengl-glsl
vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;

    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);

    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

vec4 setBrightnessComplex(vec4 color, float brightness) {
    if (brightness == 1.) {
        return color;
    }

    vec3 hsv = rgb2hsv(color.rgb);
    vec3 rgb = hsv2rgb(hsv * vec3(1., 1., brightness));

    return vec4(rgb, color.a);
}

vec4 setBrightnessSimple(vec4 color, float brightness) {
    return vec4(color.rgb * brightness, color.a);
}

vec4 setGrayscale(vec4 color, float grayscale) {
    float value = color.r * 0.299 + color.g * 0.587 + color.b * 0.114;
    vec3 fullGrayscaleRgb = vec3(value);
    vec3 interpolatedGrayscaleRgb = mix(color.rgb, fullGrayscaleRgb, grayscale);

    return vec4(interpolatedGrayscaleRgb, color.a);
}

vec4 setTint(vec4 color, vec4 tintColor) {
    return color * tintColor;
}

float getDistanceToCorner(in float d1, in float d2, in float borderRadius) {
    if (d1 > borderRadius || d2 > borderRadius) {
        return 1000000.;
    }

    return borderRadius - length(vec2(d1 - borderRadius, d2 - borderRadius));
}

void main() {
    float distanceToLeft = v_distanceToTopLeft.x;
    float distanceToTop = v_distanceToTopLeft.y;
    float distanceToRight = v_rectSize.x - v_distanceToTopLeft.x;
    float distanceToBottom = v_rectSize.y - v_distanceToTopLeft.y;
    float distanceToBorder = min(
        min(distanceToLeft, distanceToRight),
        min(distanceToTop, distanceToBottom)
    ) - 0.5;
    float alphaMultiplier = v_alpha;

    if (v_borderRadius > EPSILON) {
        float distanceToTopLeft = getDistanceToCorner(distanceToTop, distanceToLeft, v_borderRadius);
        float distanceToTopRight = getDistanceToCorner(distanceToTop, distanceToRight, v_borderRadius);
        float distanceToBottomLeft = getDistanceToCorner(distanceToBottom, distanceToLeft, v_borderRadius);
        float distanceToBottomRight = getDistanceToCorner(distanceToBottom, distanceToRight, v_borderRadius);
        float distanceToCorner = min(
            min(distanceToTopLeft, distanceToTopRight),
            min(distanceToBottomLeft, distanceToBottomRight)
        ) - 0.5;

        distanceToBorder = min(distanceToBorder, distanceToCorner);
    }

    float innerAlpha = distanceToBorder + 1.;
    float outerAlpha = v_strokeSize - distanceToBorder;
    alphaMultiplier *= clamp(min(innerAlpha, outerAlpha), 0., 1.);

    if (v_fillPercentRadial < 1.) {
        float startAngle = v_fillPercentRadialStartAngle;
        float maxAngle = PI * 2. * v_fillPercentRadial;
        float baseAngle = atan(distanceToBottom - distanceToTop, distanceToRight - distanceToLeft) - PI / 2. - startAngle;
        float pixelAngle = mod(baseAngle * v_fillPercentRadialDirection, PI * 2.);

        if (pixelAngle > maxAngle) {
            alphaMultiplier = 0.;
        }
    }

    alphaMultiplier *= step(v_fillPercentLinearProgress, v_fillPercentLinear);

    vec4 fragmentColor = v_color;

    if (v_imageTextureCoords.x >= -0.5) {
        vec4 texelColor = texture(u_imageTexture, v_imageTextureCoords);

        texelColor = replaceColor(texelColor, v_imageReplaceSourceColor, v_imageReplaceTargetColor);

        fragmentColor = blend(fragmentColor, texelColor, 1.);
    }

    fragmentColor = setTint(fragmentColor, v_tint);
    fragmentColor = setBrightnessSimple(fragmentColor, v_brightness);
    fragmentColor = setGrayscale(fragmentColor, v_grayscale);
    fragmentColor.a *= alphaMultiplier;

    out_color = fragmentColor;
    out_componentId = v_componentId;
}