import { Counter } from '../../utils/data-structures/counter.ts';
import { DomKeybordEvent, DomMouseEvent, DomTextInputEvent, DomWheelEvent, DomWindowEvent } from '../../utils/dom/window-events.ts';
import { Point } from '../../utils/geometry/point.ts';
import { TextContent } from '../../utils/text/text-content.ts';
import { Client } from '../client/client.ts';
import { Component } from '../component/component.ts';
import { UserInputEntry } from './user-input-entry.ts';
import { ALT_PREFIX, CTRL_PREFIX, GenericButtonCombination, GenericButtonName, META_PREFIX, MODIFIER_KEYS, MOUSE_LEFT_NAME, MOUSE_MIDDLE_NAME, MOUSE_RIGHT_NAME, SCROLL_NAME, SHIFT_PREFIX, WaitForUserInputParams } from './user-input-types.ts';
import { UserInput, UserInputAction } from './user-input.ts';

export class UserInputManager {
    private client: Client;
    private entries: UserInputEntry[] = [];
    private pointerPosition: Point = Point.from([800, 450]);
    private sources: Map<[Component, string], number> = new Map();
    private sourceIdCounter: Counter = new Counter();

    constructor(client: Client) {
        this.client = client;
    }

    update() {
        let focusChain: Component[] = [];

        for (let entry of this.entries) {
            entry.update();

            if (entry.isCompleted()) {
                entry.destroy();
            } else {
                focusChain.push(...entry.getFocusChain());
            }
        }

        this.entries = this.entries.filter(entry => !entry.isCompleted());
        this.client.setFocusChain(focusChain);
    }

    cancelUserInput(source: [Component, string]) {
        let sourceId = this.getSourceId(source);

        for (let entry of this.entries) {
            if (entry.getSourceId() === sourceId) {
                entry.cancel();
            }
        }
    }

    cancelAllUserInput() {
        for (let entry of this.entries) {
            entry.cancel();
        }
    }

    async waitForUserInput(source: [Component, string], priority: number, params: WaitForUserInputParams<any, any>): Promise<UserInput> {
        let sourceId = this.getSourceId(source);
        let entry = new UserInputEntry(this.client, sourceId, priority, params);

        this.entries.unshift(entry);
        this.entries.sort((a, b) => b.getPriority() - a.getPriority());

        entry.update();
        entry.processFocusInput(new UserInput('focus'), null, this.client.getFocusedComponent());
        entry.processUserInput(new UserInput('none'));

        return entry.getResolvePromise();
    }

    private getSourceId(source: [Component, string]): number {
        let sourceId = this.sources.get(source);

        if (!sourceId) {
            sourceId = this.sourceIdCounter.next();
            this.sources.set(source, sourceId);
        }

        return sourceId;
    }

    processDomEvent(evt: DomWindowEvent) {
        if (evt.kind === 'keyboard' && evt.repeat) {
            return;
        }

        let userInput = new UserInput('move');
        let evtSourceId: number | null = null;

        if (evt.kind === 'mouse' || evt.kind === 'keyboard' || evt.kind === 'wheel') {
            this.processUserInputButton(userInput, evt);
        }

        if (evt.kind === 'mouse' && evt.action === 'move') {
            this.processUserInputMovement(userInput, evt);
        }

        if (evt.kind === 'wheel') {
            this.processUserInputScroll(userInput, evt);
        }

        if (evt.kind === 'text-input') {
            this.processUserInputText(userInput, evt);
            evtSourceId = evt.targetId;
        }

        this.propagate(entry => entry.processUserInput(userInput.clone()), evtSourceId);

        if (evt.kind === 'mouse' && evt.action === 'down') {
            this.client.setFocusedComponent(this.client.getHoveredComponent());
        }
    }

    processTimeElapsed() {
        this.propagate(entry => entry.processUserInput(new UserInput('none')));
    }

    processNewFocus(prevFocus: Component | null, newFocus: Component | null) {
        this.propagate(entry => entry.processFocusInput(new UserInput('focus'), prevFocus, newFocus));
    }

    private propagate(callback: (entry: UserInputEntry) => void, targetSourceId: number | null = null) {
        let intercepted = false;

        for (let entry of this.entries) {
            if (targetSourceId && entry.getSourceId() !== targetSourceId) {
                continue;
            }

            callback(entry);

            if (entry.doesCapture() && entry.isCompleted()) {
                intercepted = true;
                break;
            }
        }

        return intercepted;
    }

    private processUserInputText(userInput: UserInput, evt: DomTextInputEvent) {
        userInput.action = 'text';
        userInput.text = TextContent.from({
            content: evt.text,
            cursorIndex: evt.selectionEnd
        });
    }

    private processUserInputScroll(userInput: UserInput, evt: DomWheelEvent) {
        userInput.scrollDeltaY = evt.deltaY;
    }

    private processUserInputMovement(userInput: UserInput, evt: DomMouseEvent) {
        let virtualToRealRatio = this.client.getGraphicsEngine().getVirtualToRealRatio();
        let pointerPosition = Point.from([evt.x / virtualToRealRatio, evt.y / virtualToRealRatio]);
        let movement = this.pointerPosition.getVectorTo(pointerPosition);

        this.pointerPosition = pointerPosition;
        
        userInput.position = pointerPosition.clone();
        userInput.movement = movement;
    }

    private processUserInputButton(userInput: UserInput, evt: DomKeybordEvent | DomMouseEvent | DomWheelEvent) {
        let genericButtonName = '';
        let genericButtonPrefix = '';
        let isModifierKey = false;

        if (evt.kind === 'keyboard') {
            isModifierKey = MODIFIER_KEYS.includes(evt.key);
            genericButtonName = evt.code;
            userInput.source = 'keyboard';
            userInput.action = evt.action;
            userInput.repeat = evt.repeat;
        } else if (evt.kind === 'mouse') {
            if (evt.button === 'left') {
                genericButtonName = MOUSE_LEFT_NAME;
            } else if (evt.button === 'middle') {
                genericButtonName = MOUSE_MIDDLE_NAME;
            } else if (evt.button === 'right') {
                genericButtonName = MOUSE_RIGHT_NAME;
            }

            userInput.source = 'mouse';
            userInput.action = evt.action;
        } else if (evt.kind === 'wheel') {
            genericButtonName = SCROLL_NAME;
            userInput.source = 'wheel';
            userInput.action = 'scroll';
        }

        if (!isModifierKey) {
            if (evt.metaKey) {
                genericButtonPrefix += META_PREFIX;
                userInput.metaKey = true;
            }

            if (evt.ctrlKey) {
                genericButtonPrefix += CTRL_PREFIX;
                userInput.ctrlKey = true;
            }

            if (evt.shiftKey) {
                genericButtonPrefix += SHIFT_PREFIX;
                userInput.shiftKey = true;
            }

            if (evt.altKey) {
                genericButtonPrefix += ALT_PREFIX;
                userInput.altKey = true;
            }
        }

        userInput.nativeEvent = evt.nativeEvent;
        userInput.button = genericButtonName as GenericButtonName;
        userInput.combination = `${genericButtonPrefix}${genericButtonName}` as GenericButtonCombination;
    }
}
globalThis.ALL_FUNCTIONS.push(UserInputManager);