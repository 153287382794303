import { WidgetStyle, mergeWidgetStyles } from './widget-types.ts';
import { Widget } from './widget.ts';

export const DEFAULT_BUTTON_STYLE: WidgetStyle = {
    base: {
        all: { borderRadius: '10%' },
        background: { color: 'white' },
        body: { textSize: '50%' },
        border: { strokeSize: '3%', color: 'black' }
    },
    hovered: {
        all: { cursor: 'pointer' },
        overlay: { color: 'black', alpha: 0.15 },
    },
    focused: {
        body: { textCursorShow: true },
        border: { strokeSize: { mult: 2 } },
    },
    disabled: {
        overlay: { color: 'black', alpha: 0.4 },
    }
};

export class Button extends Widget {
    constructor(text: string, style?: WidgetStyle) {
        super(text, mergeWidgetStyles(DEFAULT_BUTTON_STYLE, style));
    }
}
globalThis.ALL_FUNCTIONS.push(Button);