import { Component, Rect, View } from 'outpost';
import { BOSS_HEIGHT, BOSS_STARTING_POSITION, BOSS_WIDTH } from './constants.ts';

export class Boss implements Component {
    rect: Rect = new Rect(BOSS_STARTING_POSITION.x, BOSS_STARTING_POSITION.y, BOSS_WIDTH, BOSS_HEIGHT);
    angle: number = 1;

    render(view: View): void {
        view.paint({
            rect: this.rect,
            angle: this.angle,
            color: 'black'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Boss);