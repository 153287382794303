import { KeyCode, Room, RoomApi, View } from 'outpost';
import { Player } from './player.ts';
import { FireWave } from './fire-wave.ts';
import { Boss } from './boss.ts';
import { GenericButtonName } from '../../outpost/src/framework/user-input/user-input-types.ts';
import { KEY_TO_CALLBACK } from './constants.ts';

export class MainRoom implements Room<Player> {
    players: Player[] = [];
    boss: Boss = new Boss();
    fireWave: FireWave = new FireWave();
    pause: boolean = false;

    onClientAdded(api: RoomApi, client: Player): void {
        this.players.push(client);
        client.game = this;
    }

    onClientRemoved(api: RoomApi, client: Player): void {
        this.players.remove(client);
    }

    onUpdate(api: RoomApi): void {
        if (this.pause) {
            return;
        }

        let elapsedSecs = api.getServerTickDuration() / 1000;
        let bossSides = this.boss.rect.getSides().map(line => line.rotate(this.boss.angle, this.boss.rect.getCenter()));

        for (let player of this.players) {
            player.update(elapsedSecs);
            player.processBossCollisions(bossSides);
        }

        api.update(this);
    }

    render(view: View): void {
        view.addChild(this.fireWave);
        view.addChild(this.boss);
        view.addChild(this.players);
    }

    async $startTurn(api: RoomApi, player: Player) {
        let input = await api.waitForUserInput({
            shortcuts: KEY_TO_CALLBACK,
            shortcutTrigger: 'down'
        });

        await api.waitForServerResponse();

        input.selection(player.engineStrength, true);
    }

    async $stopTurn(api: RoomApi, player: Player) {
        let input = await api.waitForUserInput({
            shortcuts: KEY_TO_CALLBACK,
            shortcutTrigger: 'up'
        });

        await api.waitForServerResponse();

        input.selection(player.engineStrength, false);
    }

    async $pause(api: RoomApi) {
        await api.waitForButtonPress('KeyP');
        await api.waitForServerResponse();

        this.pause = !this.pause;
    }
}
globalThis.ALL_FUNCTIONS.push(MainRoom);