import { Room, RoomApi, RoomClient, View } from 'outpost';
import { MainRoom } from './main-room.ts';
import { Player } from './player.ts';

export class LoginRoom implements Room {
    mainRoomId: string = '';
    idToName: Map<string, string> = new Map();

    onCreated(api: RoomApi): void {
        this.mainRoomId = api.createRoom(MainRoom);
    }

    async $loginInteraction(api: RoomApi, client: RoomClient) {
        if (this.idToName.has(client.id)) {
            return;
        }

        let username = api.nativePrompt('What is your name?', 'glob-username');

        if (!username) {
            return;
        }

        await api.waitForServerResponse();

        this.idToName.set(client.id, username);
        api.addClientToRoom(MainRoom, this.mainRoomId, new Player(client.id, username));
    }

    render(view: View): void {
        view.paint('background', {
            color: 'black'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(LoginRoom);