import { KeyCode, Rect } from 'outpost';
import { EngineStrength } from './physics/engine-strength.ts';

export { default as PLAYER_IMG } from '../assets/player.png';

export const VIEWPORT = Rect.from([1600, 900]);

export const FIRE_WAVE_RECT = VIEWPORT.fromBottomInwards('*', '20%');
export const FIRE_WAVE_SPEED = 100;

export const BOSS_STARTING_POSITION = VIEWPORT.fromTopInwards('*', '80%').getCenter();
export const BOSS_WIDTH = 150;
export const BOSS_HEIGHT = BOSS_WIDTH * 1.5;

export const PLAYER_STARTING_POSITION = VIEWPORT.getCenter().translate(100, 180);
export const PLAYER_WIDTH = 40;
export const PLAYER_HEIGHT = PLAYER_WIDTH;
export const PLAYER_STARTING_SPEED = 200;
export const PLAYER_ANGULAR_SPEED = 5;

export const PLAYER_ACCELERATION = 200;
export const PLAYER_ROAD_MAX_SPEED = 500;
export const PLAYER_DIRT_MAX_SPEED = 200;
export const PLAYER_BRAKE_DECELERATION = PLAYER_ACCELERATION;
export const PLAYER_DIRT_DECELERATION = 100;
export const PLAYER_NATURAL_DECELERATION_FACTOR = 0.5;
export const PLAYER_NATURAL_DECELERATION = 50;
export const BOUNCE_SPEED_RATIO = 0.7;
export const MIN_BOUNCE_SPEED = 150;

export const KEY_TO_CALLBACK: Partial<{ [Key in KeyCode]: (engine: EngineStrength, pressed: boolean) => void }> = {
    'ArrowDown': (engine, pressed) => engine.braking = pressed,
    'ArrowUp': (engine, pressed) => engine.accelerating = pressed,
    'ArrowRight': (engine, pressed) => engine.turningRight = pressed,
    'ArrowLeft': (engine, pressed) => engine.turningLeft = pressed,
    'KeyS': (engine, pressed) => engine.braking = pressed,
    'KeyW': (engine, pressed) => engine.accelerating = pressed,
    'KeyD': (engine, pressed) => engine.turningRight = pressed,
    'KeyA': (engine, pressed) => engine.turningLeft = pressed,
};