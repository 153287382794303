import { CallbackQueue } from '../../utils/data-structures/callback-queue.ts';
import { Result } from '../../utils/language/result.ts';
import { Logger } from '../../utils/logging/logger.ts';
import { Client } from '../client/client.ts';
import { Server, isPersistentClientId } from '../server/server.ts';
import { RoomApi } from './room-api.ts';
import { AddClientToRoomParams, CreateRoomParams, AuthenticateClientParams, RoomEventParams, RemoveClientFromRoomParams, DeleteRoomParams, EventParams, ClientConnectParams, ClientDisconnectParams, ClientInteractionParams, EventOutput, RoomInfo, RoomUpdateParams, PostAddClientToRoomParams, PostRemoveClientFromRoomParams, EventKindMapping, PostCreateRoomParams, PreDeleteRoomParams, OngoingInteraction, InteractionCallbackResult, StartRoomParams } from './room-manager-types.ts';
import { RoomWrapper } from './room-wrapper.ts';
import { Room, RoomClient } from './room.ts';
import { RoomGlobalHookManager } from './room-global-hook-manager.ts';
import { Counter } from '../../utils/data-structures/counter.ts';
import { ServerData } from '../server/server-data.ts';
import { RoomClientWrapper } from './room-client-wrapper.ts';
import { Serializer } from '../../utils/serialization/serializer.ts';
import { Deserializer } from '../../utils/serialization/deserializer.ts';
import { MaybeAsync } from '../../utils/language/async.ts';
import { InteractionCallback } from './room-interaction.ts';
import { getObjectCallback, getObjectPath } from '../../utils/language/object.ts';
import { RoomApiState } from './room-api-types.ts';
import { GracefulAbort } from '../../utils/language/error.ts';
import { makePromise } from '../../utils/language/promise.ts';
import { ClientData } from '../client/client-data.ts';
import { RoomEventCallback } from './room-event.ts';
import { ComponentHookMethodName } from '../component/component-types.ts';
import { Component } from '../component/component.ts';

export const ROOT_ROOM_ID = 'root';

export type RoomManagerParams = {
    client: Client | null;
    server: Server | null;
    serializer: Serializer;
    deserializer: Deserializer;
};

export class RoomManager {
    private client: Client | null;
    private server: Server | null;
    private serializer: Serializer;
    private deserializer: Deserializer;
    private rooms: Map<string, RoomWrapper> = new Map();
    private clients: Map<string, RoomClientWrapper> = new Map();
    private eventQueue: CallbackQueue = new CallbackQueue();
    private activeClientId: string | null = null;
    private activeRoom: RoomWrapper | null = null;
    private onActiveRoomChangeCallbacks: ((activeRoom: Room | null, prevActiveRoom: Room | null) => void)[] = [];
    private globalHookManager: RoomGlobalHookManager = new RoomGlobalHookManager();
    private eventIdCounter: Counter = new Counter();
    private eventsResolveCallback: Map<number, () => void> = new Map();
    private commonApi: RoomApi;
    private ongoingInteractions: Map<Component, Map<string, OngoingInteraction>> = new Map();
    private eventCallbackPaths: Map<RoomEventCallback, string[] | null> = new Map();
    private constructorToInteractionKeys: Map<Function, string[]> = new Map();
    private onNewFrameComponents: Set<Component> = new Set();
    private processEventMapping: { [Key in keyof EventKindMapping]: (params: EventKindMapping[Key]) => MaybeAsync<EventOutput<EventKindMapping[Key]>> } = {
        clientConnect: params => this.processClientConnect(params),
        clientDisconnect: params => this.processClientDisconnect(params),
        authenticateClient: params => this.processAuthenticateClient(params),
        createRoom: params => this.processCreateRoom(params),
        startRoom: params => this.processStartRoom(params),
        deleteRoom: params => this.processDeleteRoom(params),
        addClientToRoom: params => this.processAddClientToRoom(params),
        removeClientFromRoom: params => this.processRemoveClientFromRoom(params),
        updateRoom: params => this.processUpdateRoom(params),
        emitRoomEvent: params => this.processEmitRoomEvent(params),
        clientInteraction: params => this.processClientInteraction(params),
        postCreateRoom: params => this.processPostCreateRoom(params),
        preDeleteRoom: params => this.processPreDeleteRoom(params),
        postAddClientToRoom: params => this.processPostAddClientToRoom(params),
        postRemoveClientFromRoom: params => this.processPostRemoveClientFromRoom(params),
        callback: params => this.processCallback(params),
    };

    constructor(params: RoomManagerParams) {
        this.client = params.client;
        this.server = params.server;
        this.serializer = params.serializer;
        this.deserializer = params.deserializer;
        this.commonApi = new RoomApi(this);
    }

    getClient(): Client | null {
        return this.client;
    }

    getServer(): Server | null {
        return this.server;
    }

    start(createRootRoom: () => Room) {
        if (this.server) {
            this.queueCreateRoom({ roomId: ROOT_ROOM_ID, room: createRootRoom() });
        }
    }

    update() {
        if (this.client) {
            this.updateClientInteractions()

            for (let component of this.onNewFrameComponents) {
                this.runComponentHookMethod(component, 'onNewFrame');
            }
        } else {
            for (let roomWrapper of this.globalHookManager.getRoomsWithMethod('onUpdate')) {
                this.queueUpdateRoom({ roomId: roomWrapper.roomId });
            }
        }
    }

    queueClientConnect(params: ClientConnectParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'clientConnect', data: params }, immediate);
    }

    queueClientDisconnect(params: ClientDisconnectParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'clientDisconnect', data: params }, immediate);
    }

    queueAuthenticateClient(params: AuthenticateClientParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'authenticateClient', data: params }, immediate);
    }

    queueCreateRoom(params: CreateRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'createRoom', data: params }, immediate);
    }

    queueDeleteRoom(params: DeleteRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'deleteRoom', data: params }, immediate);
    }

    queueAddClientToRoom(params: AddClientToRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'addClientToRoom', data: params }, immediate);
    }

    queueRemoveClientFromRoom(params: RemoveClientFromRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'removeClientFromRoom', data: params }, immediate);
    }

    queueStartRoom(params: StartRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'startRoom', data: params }, immediate);
    }

    queueUpdateRoom(params: RoomUpdateParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'updateRoom', data: params }, immediate);
    }

    queueEmitRoomEvent(params: RoomEventParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'emitRoomEvent', data: params }, immediate);
    }

    queueClientInteraction(params: ClientInteractionParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'clientInteraction', data: params }, immediate);
    }

    queueAnyEvent<K extends keyof EventKindMapping>(params: EventParams<K>, immediate: boolean = false): number {
        params.eventId ??= this.eventIdCounter.next();

        this.eventQueue.queue(() => this.processEvent(params), immediate);

        return params.eventId;
    }

    queueImmediateFromServer<K extends keyof EventKindMapping>(kind: K, data: EventKindMapping[K]) {
        if (this.server) {
            this.eventQueue.queue(() => this.processEvent({ kind, data }), true);
        }
    }

    queueInstantFromServer<K extends keyof EventKindMapping>(kind: K, data: EventKindMapping[K]) {
        if (this.server) {
            this.processEvent({ kind, data });
        }
    }

    getEventResolvePromise(eventId: number): Promise<void> {
        return new Promise(resolve => {
            this.eventsResolveCallback.set(eventId, resolve);
        });
    }

    private async processEvent<K extends keyof EventKindMapping>(params: EventParams<K>) {
        let { kind, data, eventId = 0 } = params;
        let output = this.processEventMapping[kind](data);

        if (output instanceof Promise) {
            output = await output;
        }

        let resolve = this.eventsResolveCallback.get(eventId);

        if (resolve) {
            resolve();
            this.eventsResolveCallback.delete(eventId);
        }

        if (!this.server || !output || (output.affectedRooms.length === 0 && !output.sendCustomParams)) {
            // console.timeEnd(kind);
            return;
        }

        let {
            affectedRooms,
            sendCustomParams
        } = output;

        let clientIds: Set<string> = new Set();

        for (let roomWrapper of affectedRooms) {
            for (let clientId of roomWrapper.clients.keys()) {
                clientIds.add(clientId);
            }
        }

        if (sendCustomParams) {
            let [clientId, customParams] = sendCustomParams;

            clientIds.delete(clientId);

            if (customParams) {
                this.server.emitEvent({
                    clientIds: clientId,
                    kind,
                    data: customParams,
                    eventId,
                });
            }
        }

        this.server.emitEvent({ clientIds, kind, data, eventId });
    }

    private async processCallback(callback: () => Promise<void>): Promise<null> {
        await callback();

        return null
    }

    private processClientConnect(params: ClientConnectParams): EventOutput<ClientConnectParams> {
        params.aggregatedServerData ??= {};

        let { clientId, createRooms, aggregatedServerData } = params;

        if (this.client && createRooms) {
            this.activeClientId = clientId;
            this.rooms.clear();
            this.clients.clear();
            this.globalHookManager.clear();

            for (let roomSnapshot of createRooms!) {
                this.processCreateRoom(this.deserializer.deserialize(roomSnapshot));
            }
        }

        let clientWrapper = this.clients.get(clientId);
        let affectedRooms = clientWrapper?.roomWrappers.slice() ?? [];

        if (this.server && !affectedRooms.length) {
            let clientData = this.serializer.serialize({ id: clientId }).sliceUint8Array();

            this.queueAddClientToRoom({ clientId, roomId: ROOT_ROOM_ID, clientData }, true);
        }

        if (!createRooms && this.server) {
            createRooms = affectedRooms.map(roomWrapper => roomWrapper.getSnapshot());
        }

        for (let roomWrapper of affectedRooms) {
            let client = roomWrapper.clients.get(clientId)!;

            roomWrapper.runHookMethod(aggregatedServerData, 'onClientReconnected', client);
        }

        this.computeClientActiveRoom();

        return {
            affectedRooms,
            sendCustomParams: [clientId, { ...params, createRooms }]
        };
    }

    private processClientDisconnect(params: ClientDisconnectParams): EventOutput<ClientDisconnectParams> {
        params.aggregatedServerData ??= {};

        let { clientId, aggregatedServerData } = params;
        let clientWrapper = this.clients.get(clientId);

        if (!clientWrapper) {
            return null;
        }

        let affectedRooms = clientWrapper.roomWrappers.slice();
        let isPersistentClient = isPersistentClientId(clientId);

        for (let roomWrapper of affectedRooms) {
            let roomId = roomWrapper.roomId;
            let client = roomWrapper.clients.get(clientId)!;

            roomWrapper.runHookMethod(aggregatedServerData, 'onClientDisconnected', client);

            if (!isPersistentClient) {
                this.processRemoveClientFromRoom({ roomId, clientId });
            }
        }

        return {
            affectedRooms,
            sendCustomParams: [clientId, null]
        };
    }

    private processAuthenticateClient(params: AuthenticateClientParams): EventOutput<AuthenticateClientParams> {
        params.aggregatedServerData ??= {};

        let { currentClientId, newClientId, createRooms, aggregatedServerData } = params;
        let currentClientWrapper = this.clients.get(currentClientId ?? '');

        if (this.server?.isClientConnected(newClientId) || (currentClientId && !currentClientWrapper)) {
            return null;
        }

        let affectedRooms: RoomWrapper[] = [];

        if (currentClientId !== this.activeClientId && currentClientId && currentClientWrapper) {
            let disconnectOutput = this.processClientDisconnect({ clientId: currentClientId, aggregatedServerData });
            this.server?.updateClientId(currentClientId, newClientId);

            affectedRooms.push(...disconnectOutput!.affectedRooms);
        }

        let connectOutput = this.processClientConnect({ clientId: newClientId, createRooms, aggregatedServerData });

        createRooms ??= connectOutput!.sendCustomParams![1]!.createRooms!;

        affectedRooms.push(...connectOutput!.affectedRooms);

        return {
            affectedRooms,
            sendCustomParams: [newClientId, { ...params, createRooms }]
        };
    }

    private processCreateRoom(params: CreateRoomParams): EventOutput<CreateRoomParams> {
        let { roomId } = params;

        if (this.rooms.has(roomId)) {
            return null;
        }

        let roomWrapper = new RoomWrapper(this, params);

        this.rooms.set(roomId, roomWrapper);

        for (let clientId of roomWrapper.clients.keys()) {
            this.addToClientRoomList(clientId, roomWrapper);
        }

        roomWrapper.initRoom();

        if (this.server) {
            roomWrapper.runHookMethod(null, 'onCreated');
        }
        
        this.globalHookManager.notifyRoomCreated(roomWrapper);

        this.computeClientActiveRoom();
        this.queueImmediateFromServer('postCreateRoom', { roomId });

        return {
            affectedRooms: [roomWrapper],
        };
    }

    private processDeleteRoom(params: DeleteRoomParams): EventOutput<DeleteRoomParams> {
        let { roomConstructor, roomId } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || !roomWrapper.matchesConstructor(roomConstructor)) {
            return null;
        }

        this.queueInstantFromServer('preDeleteRoom', { roomId });

        for (let clientId of roomWrapper.clients.keys()) {
            this.queueInstantFromServer('postRemoveClientFromRoom', { roomId, clientId });
        }

        for (let clientId of roomWrapper.clients.keys()) {
            this.removeFromClientRoomList(clientId, roomWrapper);
        }

        this.globalHookManager.notifyRoomDeleted(roomWrapper);

        this.rooms.delete(roomId);
        this.computeClientActiveRoom();

        return {
            affectedRooms: [roomWrapper],
        };
    }

    private processAddClientToRoom(params: AddClientToRoomParams): EventOutput<AddClientToRoomParams> {
        params.aggregatedServerData ??= {};

        if (params.clientId === this.activeClientId && params.createRoom) {
            this.processCreateRoom(this.deserializer.deserialize(params.createRoom));
        }

        let { roomConstructor, roomId, clientId, clientData, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || roomWrapper.clients.has(clientId) || !roomWrapper.matchesConstructor(roomConstructor)) {
            return null;
        }

        let createRoom: Uint8Array | undefined = undefined;

        if (this.server) {
            createRoom = roomWrapper.getSnapshot();
        }

        let client = this.deserializer.deserialize<RoomClient>(clientData);

        // client.id = clientId;

        this.addToClientRoomList(clientId, roomWrapper);
        roomWrapper.clients.set(clientId, client);

        roomWrapper.runHookMethod(aggregatedServerData, 'onClientAdded', client, clientId);

        if (clientId === this.activeClientId) {
            this.computeClientActiveRoom();
        }

        this.queueImmediateFromServer('postAddClientToRoom', { roomId, clientId });

        return {
            affectedRooms: [roomWrapper],
            sendCustomParams: [clientId, { ...params, createRoom }]
        };
    }

    private processRemoveClientFromRoom(params: RemoveClientFromRoomParams): EventOutput<RemoveClientFromRoomParams> {
        params.aggregatedServerData ??= {};
        let { roomConstructor, roomId, clientId, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || !roomWrapper.clients.has(clientId) || !roomWrapper.matchesConstructor(roomConstructor)) {
            return null;
        }

        if (clientId === this.activeClientId) {
            this.removeFromClientRoomList(clientId, roomWrapper);
            this.rooms.delete(roomId);
            this.computeClientActiveRoom();
            return null;
        }

        let clientData = roomWrapper.clients.get(clientId);

        roomWrapper.runHookMethod(aggregatedServerData, 'onClientRemoved', clientData, clientId);

        roomWrapper.clients.delete(clientId);
        this.removeFromClientRoomList(clientId, roomWrapper);

        this.queueImmediateFromServer('postRemoveClientFromRoom', { roomId, clientId });

        return {
            affectedRooms: [roomWrapper],
            sendCustomParams: [clientId, params]
        };
    }

    private processStartRoom(params: StartRoomParams): EventOutput<StartRoomParams> {
        params.aggregatedServerData ??= {};

        let { roomId, roomConstructor, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || !roomWrapper.matchesConstructor(roomConstructor) || !('onStart' in roomWrapper.room)) {
            return null;
        }

        roomWrapper.runHookMethod(aggregatedServerData, 'onStart');

        return {
            affectedRooms: [roomWrapper]
        };
    }

    private processUpdateRoom(params: RoomUpdateParams): EventOutput<RoomUpdateParams> {
        params.aggregatedServerData ??= {};
        
        let { roomId, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper) {
            return null;
        }

        roomWrapper.runHookMethod(aggregatedServerData, 'onUpdate');

        if (this.client) {
            // this.client.cancelNonStartedInteractions();
        }

        return {
            affectedRooms: [roomWrapper]
        };
    }
    
    private processEmitRoomEvent(params: RoomEventParams): EventOutput<RoomEventParams> {
        let { roomId, eventPath, eventCallback, eventData } = params;
        let roomWrapper = this.rooms.get(roomId);
        let api = this.commonApi;

        if (!roomWrapper) {
            return warn(`unkown room id ${roomId}`);
        }

        if (eventCallback) {
            let eventCallbackPath = this.getEventCallbackPath(roomWrapper, eventCallback);

            if (!eventCallbackPath) {
                return warn(`attempt to emit an invalid event callback`);
            } else {
                eventPath = eventCallbackPath;
                params.eventPath = eventPath;
                params.eventCallback = undefined;
            }
        }

        let callback = getObjectCallback<RoomEventCallback>(roomWrapper.room, eventPath);

        if (!callback) {
            return warn(`path ${JSON.stringify(eventPath)} is not a valid event callback`);
        }

        let [component, key] = callback;

        api.reset({
            capabilities: 'server',
            roomWrapper,
            serverData: params.serverData
        });

        component[key](api, eventData);

        params.serverData = api.getServerData();

        return {
            affectedRooms: [roomWrapper],
        };
    }

    private async processClientInteraction(params: ClientInteractionParams): Promise<EventOutput<ClientInteractionParams>> {
        let { roomId, interactionPath, clientId, clientData } = params;
        let roomWrapper = this.rooms.get(roomId);
        let result: Result<ServerData[]>;
        let localResponse = true;

        if (!roomWrapper) {
            result = Result.error(`unkown room id ${roomId}`);
        } else if (!roomWrapper.clients.has(clientId)) {
            result = Result.error(`unkown client id ${clientId}`);
        } else {
            let interactionResult = await this.processCompletedInteraction(roomWrapper, interactionPath, clientId, clientData, params.result);

            result = interactionResult.map(result => result.serverData);
            localResponse = !interactionResult.isOk() || interactionResult.data!.local;
        }

        params.result = result;

        return {
            affectedRooms: (result.isOk() && !localResponse) ? [roomWrapper!] : [],
            sendCustomParams: [clientId, params],
        };
    }

    private processPostCreateRoom(params: PostCreateRoomParams): EventOutput<PostCreateRoomParams> {
        return this.processPostCreateOrDeleteRoom('onRoomCreated', params);
    }

    private processPreDeleteRoom(params: PreDeleteRoomParams): EventOutput<PreDeleteRoomParams> {
        return this.processPostCreateOrDeleteRoom('onRoomDeleted', params);
    }

    private processPostAddClientToRoom(params: PostAddClientToRoomParams): EventOutput<PostAddClientToRoomParams> {
        return this.processPostAddOrRemoveClient('onClientAddedToRoom', params);
    }

    private processPostRemoveClientFromRoom(params: PostRemoveClientFromRoomParams): EventOutput<PostRemoveClientFromRoomParams> {
        return this.processPostAddOrRemoveClient('onClientRemovedFromRoom', params);
    }

    private processPostCreateOrDeleteRoom(methodName: 'onRoomCreated' | 'onRoomDeleted', params: PostCreateRoomParams | PreDeleteRoomParams): EventOutput<PostCreateRoomParams | PreDeleteRoomParams> {
        params.aggregatedServerData ??= {};

        let { roomId, aggregatedServerData } = params;
        let affectedRooms = this.globalHookManager.triggerHook(aggregatedServerData, methodName, roomId, roomId);

        return { affectedRooms };
    }

    private processPostAddOrRemoveClient(methodName: 'onClientAddedToRoom' | 'onClientRemovedFromRoom', params: PostAddClientToRoomParams | PostRemoveClientFromRoomParams): EventOutput<PostAddClientToRoomParams | PostRemoveClientFromRoomParams> {
        params.aggregatedServerData ??= {};

        let { clientId, roomId, aggregatedServerData } = params;
        let affectedRooms = this.globalHookManager.triggerClientHook(aggregatedServerData, methodName, roomId, clientId, (client) => [roomId, client]);

        return { affectedRooms };
    }

    private addToClientRoomList(clientId: string, roomWrapper: RoomWrapper) {
        let clientWrapper = this.clients.get(clientId);

        if (!clientWrapper) {
            clientWrapper = new RoomClientWrapper();
            this.clients.set(clientId, clientWrapper);
        }

        clientWrapper.roomWrappers.push(roomWrapper);
    }

    private removeFromClientRoomList(clientId: string, roomWrapper: RoomWrapper) {
        let clientWrapper = this.clients.get(clientId);

        if (clientWrapper) {
            clientWrapper.roomWrappers.remove(roomWrapper);

            if (clientWrapper.roomWrappers.length === 0) {
                this.clients.delete(clientId);
            }
        }
    }

    private computeClientActiveRoom() {
        if (!this.activeClientId) {
            return;
        }

        let activeRoomWrapper = this.clients.get(this.activeClientId)?.roomWrappers.at(-1) ?? null;

        if (activeRoomWrapper !== this.activeRoom) {
            let prevActiveRoomWrapper = this.activeRoom;

            this.activeRoom = activeRoomWrapper;
            this.triggerActiveRoomChange(this.activeRoom, prevActiveRoomWrapper);
        }
    }

    private triggerActiveRoomChange(newActiveRoom: RoomWrapper | null, prevActiveRoom: RoomWrapper | null) {
        for (let callback of this.onActiveRoomChangeCallbacks) {
            callback(newActiveRoom?.room ?? null, prevActiveRoom?.room ?? null);
        }
    }

    onActiveRoomChange(callback: (activeRoom: Room | null, prevActiveRoom: Room | null) => void) {
        this.onActiveRoomChangeCallbacks.push(callback);
    }

    getRoom(roomId: string): RoomWrapper | undefined {
        return this.rooms.get(roomId);
    }

    getActiveRoomWrapper(): RoomWrapper | null {
        return this.activeRoom ?? null;
    }

    getActiveRoom(): Room | null {
        return this.activeRoom?.room ?? null;
    }

    getActiveClientId(): string | null {
        return this.activeClientId;
    }

    isClientInRoom(clientId: string, roomId: string): boolean {
        return this.clients.get(clientId)?.roomWrappers.some(roomWrapper => roomWrapper.roomId === roomId) ?? false;
    }

    private runComponentHookMethod<K extends ComponentHookMethodName>(component: Component, methodName: K) {
        if (!(methodName in component) || !this.activeRoom) {
            return;
        }

        let api = this.commonApi.reset({
            roomWrapper: this.activeRoom,
            capabilities: 'client',
            clientId: this.activeClientId
        });

        (component[methodName] as any)(api);

        api.destroy();
    }

    getRoomInfo(roomId: string): RoomInfo | undefined {
        return this.rooms.get(roomId)?.getInfo();
    }

    getAllRoomInfo(): RoomInfo[] {
        return [...this.rooms.values()].map(roomWrapper => roomWrapper.getInfo());
    }

    getAllRooms(): Room[] {
        return [...this.rooms.values()].map(wrapper => wrapper.room);
    }

    getSerializer(): Serializer {
        return this.serializer;
    }

    getDeserializer(): Deserializer {
        return this.deserializer;
    }

    hasInteractionCallbackStarted(component: Component, key: string): boolean {
        return this.ongoingInteractions.get(component)?.get(key)?.api?.hasStarted() ?? false;
    }

    mountComponent(component: Component) {
        this.registerComponentHooks(component);
        this.runComponentHookMethod(component, 'onMount');
        this.startComponentInteractions(component);
    }

    unmountComponent(component: Component) {
        this.unregisterComponentHooks(component);
        this.stopComponentInteractions(component);
        this.runComponentHookMethod(component, 'onUnmount');
    }

    private registerComponentHooks(component: Component) {
        if (component.onNewFrame) {
            this.onNewFrameComponents.add(component);
        }
    }

    private unregisterComponentHooks(component: Component) {
        if (component.onNewFrame) {
            this.onNewFrameComponents.delete(component);
        }
    }

    private startComponentInteractions(component: Component) {
        let interactionKeys = this.constructorToInteractionKeys.get(component.constructor);

        if (!interactionKeys) {
            interactionKeys = Object.getOwnPropertyNames(component.constructor.prototype)
                .filter(key => key.startsWith('$') && typeof (component as any)[key] === 'function');
            this.constructorToInteractionKeys.set(component.constructor, interactionKeys);
        }

        if (interactionKeys.length === 0) {
            return;
        }

        let interactions: Map<string, OngoingInteraction> = new Map();
        let componentPath = getObjectPath(this.activeRoom?.room, component) ?? null;

        for (let key of interactionKeys) {
            interactions.set(key, {
                api: null,
                path: componentPath && [...componentPath, key],
                component,
                key,
                aborted: false
            });
        }

        this.ongoingInteractions.set(component, interactions);
    }

    private stopComponentInteractions(component: Component) {
        let interactions = this.ongoingInteractions.get(component);

        if (!interactions) {
            return;
        }

        for (let { api } of interactions.values()) {
            api?.destroy();
        }

        this.ongoingInteractions.delete(component);
    }

    updateClientInteractions() {
        for (let interactions of this.ongoingInteractions.values()) {
            for (let interaction of interactions.values()) {
                if (interaction.api || interaction.aborted) {
                    continue;
                }

                this.runClientInteraction(interaction);
            }
        }
    }

    private async runClientInteraction(interaction: OngoingInteraction) {
        let clientId = this.client?.getClientId()!;
        let client = clientId && this.clients.get(clientId);

        if (!client || !this.activeRoom) {
            interaction.api = null;
            return;
        }

        let { path, component, key } = interaction;
        let api = new RoomApi(this);

        interaction.api = api;
        await this.runInteractionCallback(api, this.activeRoom, component, key, path, clientId, null, null)

        let shouldRestart = false;

        if (api.getState() === RoomApiState.Aborted) {
            interaction.aborted = true;
            interaction.api = null;
            return;
        }

        if (api.didUserInteract()) {
            let promise = api.getInteractionCompletionPromise();

            if (promise) {
                await promise;
            }

            if (!api.isDestroyed()) {
                shouldRestart = true;
            }
        }

        if (shouldRestart) {
            this.runClientInteraction(interaction);
        } else {
            interaction.api = null;
        }
    }

    async processCompletedInteraction(
        roomWrapper: RoomWrapper,
        interactionPath: string[],
        clientId: string,
        clientData: ClientData[],
        serverData: Result<ServerData[]> | null
    ): Promise<InteractionCallbackResult> {
        let callback = getObjectCallback<InteractionCallback>(roomWrapper.room, interactionPath, key => key.startsWith('$'));
        let api = this.commonApi;

        if (!callback) {
            return Result.error(`path ${JSON.stringify(interactionPath)} is not a valid interaction callback`);
        }

        let [component, key] = callback;

        return this.runInteractionCallback(api, roomWrapper, component, key, interactionPath, clientId, clientData, serverData);
    }

    private async runInteractionCallback(
        api: RoomApi,
        roomWrapper: RoomWrapper,
        component: Component,
        interactionKey: string,
        interactionPath: string[] | null,
        clientId: string,
        clientData: ClientData[] | null,
        serverData: Result<ServerData[]> | null
    ): Promise<InteractionCallbackResult> {
        let client = roomWrapper.clients.get(clientId)!;
        let onComplete = makePromise();

        api.reset({
            capabilities: 'client',
            roomWrapper,
            clientId,
            interactionPath,
            saveData: clientData === null,
            preLoadedData: clientData,
            serverData: serverData?.unwrap(),
            source: [component, interactionKey],
            onComplete
        });

        let result: InteractionCallbackResult;

        try {
            await (component as any)[interactionKey](api, client);
            result = Result.ok({
                serverData: api.getServerData(),
                local: api.isLocalServerResponse()
            });
        } catch (error: unknown) {
            if (error && error instanceof Error) {
                Logger.error(error);
                result = Result.error(error.message);
            } else {
                result = Result.error();
            }

            if (error && error instanceof GracefulAbort && error.message) {
                Logger.warn(error.message);
            }

            if (!error || !(error instanceof GracefulAbort)) {
                api.setAborted();
            }
        }

        onComplete.resolve();
        api.destroy();

        return result;
    }

    getEventCallbackPath(roomWrapper: RoomWrapper, callback: RoomEventCallback): string[] | null {
        let path = this.eventCallbackPaths.get(callback);

        if (path === undefined) {
            path = getObjectPath(roomWrapper.room, callback, true) ?? null;
            this.eventCallbackPaths.set(callback, path);
        }

        return path;
    }
}

function warn(message: string): EventOutput<any> {
    Logger.warn(message);

    return null;
}
globalThis.ALL_FUNCTIONS.push(RoomManager);