import { PLAYER_ACCELERATION, PLAYER_ANGULAR_SPEED, PLAYER_BRAKE_DECELERATION, PLAYER_NATURAL_DECELERATION, PLAYER_NATURAL_DECELERATION_FACTOR, PLAYER_ROAD_MAX_SPEED } from '../constants.ts';
import { Strength } from './strength.ts';

export class EngineStrength extends Strength {
    turningRight: boolean = false;
    turningLeft: boolean = false;
    accelerating: boolean = false;
    braking: boolean = false;

    update(elapsedSecs: number): void {
        let rotation = 0;

        if (this.turningRight) {
            rotation += 1;
        }

        if (this.turningLeft) {
            rotation -= 1;
        }

        this.angle += rotation * elapsedSecs * PLAYER_ANGULAR_SPEED;

        if (this.accelerating) {
            this.speed += PLAYER_ACCELERATION * elapsedSecs;
        }

        if (this.braking) {
            this.speed -= PLAYER_BRAKE_DECELERATION * elapsedSecs;
        }

        if (!this.braking && !this.accelerating) {
            this.speed *= PLAYER_NATURAL_DECELERATION_FACTOR ** elapsedSecs;
            this.speed -= Math.min(PLAYER_NATURAL_DECELERATION * elapsedSecs, Math.abs(this.speed)) * Math.sign(this.speed);

            if (Math.abs(this.speed) < 1) {
                this.speed = 0;
            }
        }

        if (Math.abs(this.speed) > PLAYER_ROAD_MAX_SPEED) {
            this.speed = PLAYER_ROAD_MAX_SPEED * Math.sign(this.speed);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(EngineStrength);