import { Vector } from 'outpost';

export class Strength {
    angle: number = 0;
    speed: number = 0;

    update(elapsedSecs: number) {

    }

    getVelocity(): Vector {
        return Vector.top().rotate(this.angle).withLength(this.speed);
    }
};
globalThis.ALL_FUNCTIONS.push(Strength);