import { RenderParams } from './render-animation/render-params.ts';
import { Client } from '../client/client.ts';
import { AnimationCallback, AnimationId } from './animation-types.ts';
import { EmitParticlesParams } from './emit-particles-animation/emit-particles-params.ts';
import { PlayAudioParams } from './play-audio-animation/play-audio-params.ts';
import { playAudioAnimation } from './play-audio-animation/play-audio-animation.ts';
import { emitParticlesAnimation } from './emit-particles-animation/emit-particles-animation.ts';
import { UpdateClockParams } from './update-clock-animation/update-clock-params.ts';
import { UpdateLayerParams } from './update-layer-animation/update-layer-params.ts';
import { Component } from '../component/component.ts';
import { AnimationGroup } from './animation-group.ts';
import { renderAnimation } from './render-animation/render-animation.ts';
import { updateClockAnimation } from './update-clock-animation/update-clock-animation.ts';
import { updateLayerAnimation } from './update-layer-animation/update-layer-animation.ts';
import { waitAnimation } from './wait-animation/wait-animation.ts';
import { List } from '../../utils/memory/list.ts';
import { AnimationApi } from './animation-api.ts';
import { stopAnimation } from './stop-animation/stop-animation.ts';
import { AnimationQueueDummy } from './animation-queue-dummy.ts';
import { ClockProperties } from '../../utils/time/clock-types.ts';
import { Clock } from '../../utils/time/clock.ts';

export type AnimationQueueParams = {
    nonBlocking?: boolean;
    instant?: boolean;
};

/**
 * Allows to schedule various actions, called "animations", (e.g render components, play sounds, emit particles) relative to one another.
 * 
 * Multiple animation queues can co-exist, in which case they are completely independant from one another.
 * You can retrieve an animation queue via {@link ComponentApi.getAnimationQueue}, {@link ComponentApi.queue} or {@link ComponentApi.now}.
 * @category Core
 * @example
 * class MyComponent implements Component {
 *     async runInteraction(api: ComponentApi): Promise<void> {
 *         await api.waitForKeyPress('Enter');
 * 
 *         // This does the following:
 *         // - Play a sound and update the components' graphics over 500ms (both at the same time)
 *         // - Then, when both are finished, wait for 1 second
 *         // - Then, play another sound
 *         api.queue()
 *             .playAudio({ audioUrl: 'assets/sounds/boom.mp3' })
 *             .render({ duration: 500 })
 *             .queue()
 *             .wait(1000)
 *             .queue()
 *             .playAudio({ audioUrl: 'assetts/sounds/smoke.mp3' })
 *     }
 * }
 */
export class AnimationQueue {
    private client: Client;
    private clock: Clock = new Clock();
    private groups: List<AnimationGroup> = new List();
    private nonBlocking: boolean;
    private instant: boolean;

    constructor(client: Client, params: AnimationQueueParams = {}) {
        this.client = client;
        this.nonBlocking = params.nonBlocking ?? false;
        this.instant = params.instant ?? false;
    }

    schedule(animation: AnimationCallback<void>): AnimationQueue;
    schedule<T>(animation: AnimationCallback<T>, params: T): AnimationQueue;
    schedule<T>(animation: AnimationCallback<T>, params?: T): AnimationQueue {
        let api = new AnimationApi(this.client, {
            clock: this.clock,
            animation,
            params
        });

        this.requireLastGroup().add(api);

        if (this.instant) {
            api.stop();
        }

        return this;
    }

    scheduleFromApi(animationApi: AnimationApi): AnimationQueue {
        this.requireLastGroup().add(animationApi);

        return this;
    }

    async awaitAll() {
        await Promise.all([...this.animations()].map(api => api.getOnCompletePromise()));
    }

    async awaitLast() {
        await this.groups.getLast()?.getLast()?.getOnCompletePromise();
    }

    /**
     * Make so all subsequent scheduled animations will start after the currently scheduled animations.
     * 
     * Note: some animations provide a `blocking` parameter (e.g {@link PlayAudioParams}).
     * If this field is set to `false`, it does not block subsequent animations. It is `true` by default for most animations.
     * @returns 
     */
    queue() {
        this.groups.push(new AnimationGroup(this.clock));

        return this;
    }

    update() {
        this.clock.tick();

        for (let group of this.groups) {
            group.update();

            if (!this.nonBlocking && group.isBlocking()) {
                break;
            }
        }

        for (let group of this.groups) {
            if (group.isCompleted()) {
                this.groups.remove(group);
            }
        }
    }

    forceStopNow(predicate: AnimationId | ((id: AnimationId) => boolean)): AnimationQueue {
        let callback = typeof predicate === 'function' ? predicate : (() => predicate);

        for (let animation of this.animations()) {
            if (callback(animation.getId())) {
                animation.stop();
            }
        }

        return this;
    }

    clear(): AnimationQueue {
        return this.forceStopNow(() => true);
    }

    getClockProperties(): ClockProperties {
        return this.clock.getProperties();
    }

    private requireLastGroup(): AnimationGroup {
        if (this.groups.size === 0) {
            this.groups.push(new AnimationGroup(this.clock));
        }

        return this.groups.getLast()!;
    }

    private *animations() {
        for (let group of this.groups) {
            yield* group.animations();
        }
    }

    /**
     * Wait for the specified duration (in milliseconds).
     * @param duration 
     * @returns 
     */
    wait(duration: number): AnimationQueue {
        return this.schedule(waitAnimation, duration);
    }

    stop(predicate: AnimationId | ((id: AnimationId) => boolean) = () => true): AnimationQueue {
        return this.schedule(stopAnimation, predicate);
    }

    /**
     * Renders the specified components over the specified duration.
     * Effectively, it calls {@link Component.render} to create a new "display state" of the view, and interpolates it
     * with the previous state over the duration to make a transition animation.
     * If no duration is specified, there is no animation and the new state is instantly displayed.
     * 
     * If a component is rendered for the first time, {@link Component.renderBirth} is also called to create the previous state.
     * If any of a rendered component's children have just been removed from the component tree, they are rendered one last time
     * with {@link Component.renderDeath}.
     * @param params If an array is passed, it is interpreted as the `components` property.
     * If a number is passed, it is intepreted as the `duration` property.
     * If nothing is passed, all components are re-rendered instantly.
     * @returns 
     * @example
     * export class RootComponent implements Component {
     *     items = {
     *         square: null as (null | SquareComponent)
     *     }
     * 
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         // Create the square the first time the enter key is pressed.
     *         // Then make it move to the next corner every time the key is pressed.
     *         // When it reaches its starting position, remove it.
     * 
     *         // You can try pressing 'Enter' multiple times in a row to check that the animations are properly queued.
     * 
     *         await api.waitForKeyPress('Enter');
     * 
     *         if (!this.items.square) {
     *             this.items.square = new SquareComponent();
     *         } else if (this.items.square.landmark === 'top-left') {
     *             this.items.square.landmark = 'top-right';
     *         } else if (this.items.square.landmark === 'top-right') {
     *             this.items.square.landmark = 'bottom-right';
     *         } else if (this.items.square.landmark === 'bottom-right') {
     *             this.items.square.landmark = 'bottom-left';
     *         } else {
     *             this.items.square.landmark = 'top-left';
     *             this.items.square = null;
     *         }
     * 
     *         api.queue()
     *             .render({
     *                 components: [this],
     *                 duration: 500
     *             });
     *     }
     * 
     *     render(view: View): void {
     *         view.paint()
     *             .backgroundColor('white')
     * 
     *         // No rectangle is specified, so the square will inherit from the parent's rectangle
     *         view.renderChild(this.items.square);
     *     }
     * }
     * 
     * class SquareComponent implements Component {
     *     landmark: RectLandmark = 'top-left';
     * 
     *     render(view: View): void {
     *         view.paint()
     *             .transform(rect => rect.strip(50).fromLandmark(this.landmark, 100, 100))
     *             .backgroundColor('red')
     *     }
     * 
     *     renderBirth(view: View): void {
     *         view.paint()
     *             .transform(rect => rect.scale(0))
     *     }
     * 
     *     renderDeath(view: View): void {
     *         view.paint()
     *             .transform(rect => rect.scale(0))
     *     }
     * }
     */
    render<T extends Component>(params: RenderParams<T> | T = {}): AnimationQueue {
        let formattedParams: RenderParams<T> =
            'render' in params ? { component: params } :
            params;

        return this.schedule(renderAnimation, formattedParams);
    }

    /**
     * Update the clock properties. Each animation queue has its own clock.
     * @param params 
     * @returns 
     * @example
     * class MyComponent implements Component {
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         await api.waitForKeyPress();
     * 
     *         // The components will actually be rendered over 500 ms, because we double the clock speed.
     * 
     *         api.queue()
     *             .updateClock({ speed: 2 })
     *             .render(1000)
     *             .queue()
     *             .updateClock({ speed: 1 })
     *     }
     * }
     */
    updateClock(params: UpdateClockParams): AnimationQueue {
        return this.schedule(updateClockAnimation, params);
    }

    /**
     * Update the specified layer. If it does not exist, it is created.
     * @param layerId 
     * @param params 
     * @returns
     * @example
     * enum Scene {
     *     Background,
     *     World,
     * }
     * 
     * enum Queue {
     *     Zoom,
     *     Toggle
     * }
     * 
     * export class RootComponent implements Component {
     *     items = {
     *         children: new ItemArray([
     *             new ChildComponent(0, 0),
     *             new ChildComponent(700, 300),
     *             new ChildComponent(-500, 150),
     *         ])
     *     };
     * 
     *     onMountClient(api: ComponentApi): void {
     *         // When the component is initialized, position the camera of the `World` scene at (0, 0)
     *         // The camera "size" is still the default 1600 x 900
     *         api.now()
     *             .updateScene({
     *                 layerId: Scene.World,
     *                 properties: {
     *                     cameraX: 0,
     *                     cameraY: 0,
     *                     cameraZoom: 1
     *                 }
     *             })
     *     }
     * 
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         // Allow to zoom and dezoom when the user scrolls.
     *         // Also allow to show/hide the scene when the user presses enter.
     * 
     *         let { action } = await api.waitForUserInput({
     *             shortcuts: {
     *                 'ScrollUp': { action: 'zoom' },
     *                 'ScrollDown': { action: 'dezoom' },
     *                 'Enter': { action: 'toggle' },
     *             }
     *         });
     * 
     *         let multiplier = 1;
     *         let toggle = false;
     *         let duration = 200;
     *         let queue = Queue.Zoom;
     * 
     *         if (action === 'zoom') {
     *             multiplier = 2;
     *         } else if (action === 'dezoom') {
     *             multiplier = 0.5;
     *         } else {
     *             duration = 0;
     *             toggle = true;
     *             queue = Queue.Toggle;
     *         }
     * 
     *         // By specifying a queue id, both zooming and toggling can be done independantly
     *         api.now(queue)
     *             .updateScene({
     *                 layerId: Scene.World,
     *                 duration,
     *                 properties: (current) => ({
     *                     cameraZoom: current.cameraZoom * multiplier,
     *                     shown: toggle ? !current.shown : current.shown
     *                 })
     *             })
     *     }
     * 
     *     render(view: View): void {
     *         view.paint()
     *             .scene(Scene.Background)
     *             .backgroundColor('white')
     * 
     *         view.layout()
     *             .leftToRight()
     *             .childAspectRatio(1)
     *             .addChild(this.items.children, {
     *                 layerId: Scene.World
     *             })
     *     }
     * }
     * 
     * class ChildComponent implements Component {
     *     x: number;
     *     y: number;
     * 
     *     constructor(x: number, y: number) {
     *         this.x = x;
     *         this.y = y;
     *     }
     * 
     *     render(view: View): void {
     *         view.paint()
     *             .x(this.x)
     *             .y(this.y)
     *             .width(100)
     *             .height(100)
     *             .backgroundColor('red')
     *     }
     * }
     */
    updateLayer(params: UpdateLayerParams): AnimationQueue {
        return this.schedule(updateLayerAnimation, params);
    }

    /**
     * Start emitting particles.
     * @param params 
     * @returns 
     * @example
     * const PARTICLES_SCENE_ID = 0;
     * const PARTICLES_ANIMATION_ID = 'sound';
     * 
     * export class RootComponent implements Component {
     *     onMountClient(api: ComponentApi): void {
     *         // Set the virtual viewport to a square a side of 1000 virtual pixel.
     *         // This will cause the canvas to be a square. The default is 1600x900.
     *         api.configureRenderer({
     *             virtualViewport: [1000, 1000]
     *         });
     * 
     *         api.now().updateScene({
     *             layerId: PARTICLES_SCENE_ID,
     *             properties: {
     *                 cameraX: 0,
     *                 cameraY: 0
     *             }
     *         });
     *     }
     * 
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         await api.waitForKeyPress('Enter');
     * 
     *         api.queue()
     *             .stop(PARTICLES_ANIMATION_ID)
     *             .emitParticles({
     *                 animationId: PARTICLES_ANIMATION_ID,
     *                 layerId: PARTICLES_SCENE_ID,
     *                 countPerSecond: 500,
     *                 globalCompositeOperation: 'lighter',
     *                 editParticle: particle => {
     *                     particle.setColor('red', 'yellow');
     *                     particle.setAlpha(1, 0);
     *                     particle.setSize(30, 15)
     *                     particle.setPosition(0, 0);
     *                     particle.setAngle(Math.random() * Math.PI * 2);
     *                     particle.setDurationFromRangeAndSpeed(400, 250)
     *                 },
     *             })
     *     }
     * 
     *     render(view: View): void {
     *         view.paint()
     *             .backgroundColor('#222222')
     *     }
     * }
     */
    emitParticles(params: EmitParticlesParams): AnimationQueue {
        return this.schedule(emitParticlesAnimation, params);
    }

    /**
     * Plays the specified audio file.
     * 
     * See the {@link AnimationQueue.playSpriteAnimation} documentation for an example.
     * @param params 
     * @returns 
     */
    playAudio(params: PlayAudioParams | string): AnimationQueue {
        let formattedParams =
            typeof params === 'string' ? { audioUrl: params } :
            params;

        return this.schedule(playAudioAnimation, formattedParams);
    }
}

for (let key of Object.getOwnPropertyNames(AnimationQueue.prototype)) {
    if (key !== 'constructor') {
        (AnimationQueueDummy.prototype as any)[key] = function () {
            return this;
        };
    }
}
globalThis.ALL_FUNCTIONS.push(AnimationQueue);