import { Point } from '../../utils/geometry/point.ts';
import { Vector } from '../../utils/geometry/vector.ts';
import { collectionToArray, getCollectionItemAt } from '../../utils/language/collection.ts';
import { evalFunction } from '../../utils/language/function.ts';
import { Properties } from '../../utils/language/types.ts';
import { TEXT_CONTENT_SCHEMA, TextContent } from '../../utils/text/text-content.ts';
import { enumSchema } from '../../utils/type-schema/enum-schema.ts';
import { objectSchema } from '../../utils/type-schema/object-schema.ts';
import { GenericButtonCombination, GenericButtonName, USER_INPUT_SOURCES, USER_INPUT_TRIGGERS, UserInputSource, UserInputTrigger, WaitForUserInputParams } from './user-input-types.ts';

export class UserInput<T = any> {
    selection!: T;
    trigger!: UserInputTrigger;
    source: UserInputSource = null;
    position: Point = Point.zero();
    movement: Vector = Vector.zero();
    action: UserInputAction = 'none';
    button: GenericButtonName = 'None';
    combination: GenericButtonCombination = 'None';
    metaKey: boolean = false;
    ctrlKey: boolean = false;
    shiftKey: boolean = false;
    altKey: boolean = false;
    scrollDeltaY: number = 0;
    repeat: boolean = false;
    text: TextContent = new TextContent();
    nativeEvent: Event | null = null;

    constructor(action: UserInputAction) {
        this.action = action;
    }

    clone(): UserInput<T> {
        let result = new UserInput('none');;

        return Object.assign(result, this);
    }
};

export type UserInputAction = typeof USER_INPUT_ACTIONS[number];
export const USER_INPUT_ACTIONS = <const>['down', 'up', 'move', 'scroll', 'text', 'focus', 'none'];

export type UserInputSelectionKind = typeof USER_INPUT_SELECTION_KINDS[number];
export const USER_INPUT_SELECTION_KINDS = <const>['none', 'pointer', 'shortcut'];

export type UserInputData = Omit<Properties<UserInput>, 'selection'> & {
    selectionKind: UserInputSelectionKind;
    selectionIndex: number;
};

export const USER_INPUT_SCHEMA = objectSchema<UserInputData>({
    selectionKind: enumSchema(USER_INPUT_SELECTION_KINDS),
    selectionIndex: 'integer',
    source: enumSchema(USER_INPUT_SOURCES),
    trigger: enumSchema(USER_INPUT_TRIGGERS),
    position: Point.schema,
    movement: Vector.schema,
    action: enumSchema(USER_INPUT_ACTIONS),
    button: 'string',
    combination: 'string',
    metaKey: 'boolean',
    ctrlKey: 'boolean',
    shiftKey: 'boolean',
    altKey: 'boolean',
    scrollDeltaY: 'number',
    repeat: 'boolean',
    text: TEXT_CONTENT_SCHEMA,
    nativeEvent: 'null'
});

export function wrapUserInput(userInput: UserInput, params: WaitForUserInputParams<any, any>): UserInputData {
    let selectionKind: UserInputSelectionKind = 'none';
    let selectionIndex: number = -1;

    if (userInput.selection !== undefined) {
        if (userInput.combination && params.shortcuts && userInput.combination in params.shortcuts) {
            selectionKind = 'shortcut';
            selectionIndex = Object.values(params.shortcuts).indexOf(userInput.selection);
        } else {
            selectionKind = 'pointer';
            selectionIndex = collectionToArray(evalFunction(params.selectable)).indexOf(userInput.selection);
        }
    }

    return {
        selectionKind,
        selectionIndex,
        source: userInput.source,
        trigger: userInput.trigger,
        position: userInput.position,
        movement: userInput.movement,
        action: userInput.action,
        button: userInput.button,
        combination: userInput.combination,
        metaKey: userInput.metaKey,
        ctrlKey: userInput.ctrlKey,
        shiftKey: userInput.shiftKey,
        altKey: userInput.altKey,
        scrollDeltaY: userInput.scrollDeltaY,
        repeat: userInput.repeat,
        text: userInput.text,
        nativeEvent: userInput.nativeEvent
    };
}

export function unwrapUserInputData(data: UserInputData, params: WaitForUserInputParams<any, any>): UserInput {
    let result = new UserInput('none');
    let selection: any = undefined;

    if (data.selectionKind === 'shortcut') {
        selection = Object.values(params.shortcuts!)[data.selectionIndex];
    } else if (data.selectionKind === 'pointer') {
        selection = getCollectionItemAt(evalFunction(params.selectable), data.selectionIndex);
    }

    result.selection = selection;
    result.source = data.source;
    result.trigger = data.trigger;
    result.position = data.position;
    result.movement = data.movement;
    result.action = data.action;
    result.button = data.button;
    result.combination = data.combination;
    result.metaKey = data.metaKey;
    result.ctrlKey = data.ctrlKey;
    result.shiftKey = data.shiftKey;
    result.altKey = data.altKey;
    result.scrollDeltaY = data.scrollDeltaY;
    result.repeat = data.repeat;
    result.text = data.text;
    result.nativeEvent = data.nativeEvent;

    return result;
}
globalThis.ALL_FUNCTIONS.push(UserInput);