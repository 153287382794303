export class CallbackLoop {
    private callback: () => void;
    private interval: number;
    private timer: NodeJS.Timeout | null = null;

    constructor(callback: () => void, interval: number) {
        this.callback = callback;
        this.interval = interval;
    }

    start() {
        if (this.timer) {
            return;
        }

        let update = () => {
            this.callback();
            this.timer = setTimeout(update, this.interval);
        };

        this.timer = setTimeout(update, this.interval);
    }

    stop() {
        if (!this.timer) {
            return;
        }

        clearTimeout(this.timer);
        this.timer = null;
    }

    setInterval(duration: number) {
        this.interval = duration;
    }
}
globalThis.ALL_FUNCTIONS.push(CallbackLoop);