import { Counter } from '../data-structures/counter.js';
import { Hash } from '../hash/hash.js';

export type SerializableAsset =
    | (new (...params: any[]) => object)
    | ((...args: any[]) => any)
    | Symbol
    | object;

export type SerializableAssetIndexLike = SerializableAsset[] | SerializableAssetIndex | undefined;

export class SerializableAssetIndex {
    private assetIdCounter = new Counter();
    private assetsToId: Map<SerializableAsset, number> = new Map();
    private idToAsset: Map<number, SerializableAsset> = new Map();

    constructor(assets: SerializableAsset[]) {
        for (let asset of assets) {
            this.register(asset);
        }
    }

    static from(value: SerializableAssetIndexLike): SerializableAssetIndex {
        if (!value || Array.isArray(value)) {
            return new SerializableAssetIndex(value ?? []);
        } else {
            return value;
        }
    }

    private register(asset: SerializableAsset) {
        if (this.assetsToId.has(asset) || asset === Object) {
            return;
        }

        let id = this.assetIdCounter.next();

        this.assetsToId.set(asset, id);
        this.idToAsset.set(id, asset);
    }

    has(asset: any): boolean {
        return this.assetsToId.has(asset);
    }

    getIdFromAsset(asset: any): number | undefined {
        return this.assetsToId.get(asset);
    }

    getAssetFromId(id: number): any | undefined {
        return this.idToAsset.get(id);
    }
}

function hashKey(id: string) {
    return int32AsUint32(new Hash().string(id).finish());
}

function int32AsUint32(value: number) {
    let a = new Uint32Array(1);
    a[0] = value;

    return a[0];
}
globalThis.ALL_FUNCTIONS.push(SerializableAssetIndex);