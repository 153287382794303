import { collectionToArray } from '../../../utils/language/collection.ts';
import { ComponentModifier, componentModifierToCallback } from '../../component/component-modifier.ts';
import { Component } from '../../component/component.ts';
import { AnimationApi } from '../animation-api.ts';
import { RenderParams } from './render-params.ts';

export async function renderAnimation<T extends Component>(api: AnimationApi, params: RenderParams<T>) {
    let client = api.client;
    let sourceId = client.getNextRenderSourceId();
    let components = collectionToArray(params.component ?? client.getActiveRoom());
    let views = components.map(component => client.updateView(sourceId, component, null));

    if (params.callback) {
        for (let view of views) {
            let modifier = params.callback(view.getComponent() as T);

            if (modifier) {
                view.addFragment(sourceId, 0, componentModifierToCallback(modifier as ComponentModifier<Component>));
            }
        }
    }

    for (let view of views) {
        view.fillSelfFragment(sourceId);
    }

    // TODO: set self fragment delay based on modifier delay

    for (let view of views) {
        view.enableFragment(sourceId);
    }
}