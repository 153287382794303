import { Properties } from '../../utils/language/types.ts';
import { Component } from '../component/component.ts';
import { EntityEvent, EntityEventCallback } from './entity-event.ts';
import { RoomApi } from './room-api.ts';

export class EntityEventEmitter {
    preEventPriorities: number[] = [];
    postEventPriorities: number[] = [];
    entriesByPriority: Map<number, Set<EntityEventEmitterEntry>> = new Map();
    entriesByEntity: Map<Component, EntityEventEmitterEntry[]> = new Map();

    addListener(target: Component, callback: EntityEventCallback, priority: number) {
        let priorityEntries = this.entriesByPriority.get(priority);

        if (!priorityEntries) {
            let priorities = priority < 0 ? this.preEventPriorities : this.postEventPriorities;

            priorities.push(priority);
            priorities.sort((a, b) => a - b);
            priorityEntries = new Set();
            this.entriesByPriority.set(priority, priorityEntries);
        }

        let entry: EntityEventEmitterEntry = { target, callback, priority };

        priorityEntries.add(entry);

        let entityEntries = this.entriesByEntity.get(target);

        if (!entityEntries) {
            entityEntries = [];
            this.entriesByEntity.set(target, entityEntries);
        }

        entityEntries.push(entry);
    }

    removeListener(target: Component, callback: EntityEventCallback) {
        let entityEntries = this.entriesByEntity.get(target);
        let entry = entityEntries?.find(entry => entry.callback === callback);

        if (!entityEntries || !entry) {
            return;
        }

        entityEntries.remove(entry);

        this.entriesByPriority.get(entry.priority)?.delete(entry);
    }

    emitEntityEvent<E extends EntityEvent>(evtConstructor: new () => E, properties: Partial<Properties<E>>, api: RoomApi) {
        let evt = new evtConstructor();

        for (let key in properties) {
            (evt as any)[key] = properties[key];
        }

        for (let priority of this.preEventPriorities) {
            this.triggerEntries(priority, api, evt);
        }

        evt.resolve(api);

        for (let priority of this.postEventPriorities) {
            this.triggerEntries(priority, api, evt);
        }
    }

    private triggerEntries(priority: number, api: RoomApi, evt: EntityEvent) {
        let entries = this.entriesByPriority.get(priority);

        if (entries) {
            for (let entry of entries) {
                entry.callback.call(entry.target, api, evt, entry.target);
            }
        }
    }
}

type EntityEventEmitterEntry = {
    target: Component;
    callback: EntityEventCallback;
    priority: number;
};
globalThis.ALL_FUNCTIONS.push(EntityEventEmitter);