import { WidgetStyle, mergeWidgetStyles } from './widget-types.ts';
import { Widget } from './widget.ts';

export const DEFAULT_LABEL_STYLE: WidgetStyle = {};

export class Label extends Widget {
    constructor(text: string = '', style?: WidgetStyle) {
        super(text, mergeWidgetStyles(DEFAULT_LABEL_STYLE, style));
    }
}
globalThis.ALL_FUNCTIONS.push(Label);