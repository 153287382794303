export type NumberRange = [number, number] | number;

export function getRangeMin(value: NumberRange | undefined, defaultValue: number = 0): number {
    if (Array.isArray(value)) {
        return value[0];
    } else {
        return value ?? defaultValue;
    }
}

export function getRangeMax(value: NumberRange | undefined, defaultValue: number = Infinity): number {
    if (Array.isArray(value)) {
        return Math.max(value[0], value[1]);
    } else {
        return value ?? defaultValue;
    }
}

export function getMinGridDimension(value: [number, number] | number | undefined): number {
    return getRangeMin(value, 1);
}

export function getMaxGridDimension(value: [number, number] | number | undefined): number {
    return getRangeMax(value, Infinity);
}