const LOG_LEVELS = ['error', 'warn', 'info', 'debug'] as const;

export type LogLevel = typeof LOG_LEVELS[number];

export type LoggerParams = {
    level?: LogLevel | null;
    displayTime?: boolean | 'server';
}

export class Logger {
    private static console: { [key in LogLevel]: (...args: any[]) => void } = console;
    private static level: LogLevel | null = 'debug';
    private static levelIndex: number = LOG_LEVELS.indexOf(this.level ?? 'error');
    private static displayTime: boolean = shouldDisplayTime();

    static configure(params: LoggerParams) {
        let { level, displayTime } = params;

        if (level !== undefined) {
            this.level = level;
            this.levelIndex = level ? LOG_LEVELS.indexOf(level) : -1;
        }

        if (displayTime !== undefined) {
            this.displayTime = typeof displayTime === 'boolean' ? displayTime : shouldDisplayTime();
        }
    }

    static disable() {
        this.configure({ level: null });
    }

    static log(level: LogLevel, ...args: any[]) {
        if (LOG_LEVELS.indexOf(level) <= this.levelIndex) {
            if (this.displayTime) {
                this.console[level](`[${getCurrentTime()}]`, ...args);
            } else {
                this.console[level](...args);
            }
        }
    }

    static error(...args: any[]) {
        this.log("error", ...args);
    }

    static warn(...args: any[]) {
        this.log("warn", ...args);
    }

    static info(...args: any[]) {
        this.log("info", ...args);
    }

    static debug(...args: any[]) {
        this.log("debug", ...args);
    }

    static now(): string {
        return getCurrentTime();
    }
}

export function getCurrentTime(): string {
    let date = new Date();
    let yyyy = date.getFullYear().toString();
    let MM = formatFragment(date.getMonth() + 1);
    let dd = formatFragment(date.getDate());
    let hh = formatFragment(date.getHours());
    let mm = formatFragment(date.getMinutes());
    let ss = formatFragment(date.getSeconds());

    return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
}

function shouldDisplayTime(): boolean {
    return typeof window === 'undefined';
}

function formatFragment(n: number): string {
    return n.toString().padStart(2, "0");
}
globalThis.ALL_FUNCTIONS.push(Logger);