import { Component } from '../../framework/component/component.ts';
import { View } from '../../framework/view/view.ts';
import { ColorLike } from '../../utils/color/color.ts';
import { Player } from '../player.ts';

export class LobbyPlayer extends Player implements Component {
    isHost: boolean = false;
    color: ColorLike = 'black';

    render(view: View): void {
        view.layout()
            .leftToRight()
            .addChild({
                text: this.isHost ? '👑' : '',
                textSize: '50%',
                horizontalAlign: 'left',
            })
            .force(0.6)
            .addChild({
                color: this.color,
                borderRadius: '50%',
                scale: 0.4,
            })
            .force(0.6)
            .aspectRatio(1)
            .addChild({
                offsetX: 2,
                text: this.id,
                horizontalAlign: 'left',
            })
            .force(4)
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyPlayer);