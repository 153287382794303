import { LobbyPreview } from '../lobby-room/lobby-preview.ts';
import { Player } from '../player.ts';

export class HubPlayer extends Player {
    lobby: LobbyPreview | null = null;
    gameId: string | null = null;

    isIdle(): boolean {
        return this.lobby === null && this.gameId === null;
    }
}
globalThis.ALL_FUNCTIONS.push(HubPlayer);