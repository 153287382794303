import { Graphics } from '../graphics-engine/graphics/graphics.ts';
import { View } from './view.ts';

type ViewFragmentAtom = {
    graphics: Graphics[];
    index: number;
};

export class ViewFragment {
    sourceId: number;
    priority: number;
    atoms: Map<string, ViewFragmentAtom> = new Map();
    children: View[] = [];
    birthTime: number = 0;
    deathTime: number = Infinity;
    duration: number = 0;
    delay: number = 0;
    nextSourceId: number = 0;
    isEnabled: boolean = false;

    constructor(sourceId: number, priority: number) {
        this.sourceId = sourceId;
        this.priority = priority;
    }

    addGraphics(graphics: Graphics, forceKey?: string): string {
        let key = forceKey ?? graphics.key ?? '';
        let atom = this.atoms.get(key);

        if (!atom) {
            atom = { graphics: [], index: this.atoms.size };
            this.atoms.set(key, atom);
        }

        atom.graphics.push(graphics);

        return key;
    }

    getAtomIndex(key: string): number | undefined {
        return this.atoms.get(key)?.index;
    }

    addChild(view: View) {
        this.children.push(view);
    }

    isModifier(): boolean {
        return this.priority >= 0;
    }

    computeLifetime(startTime: number) {
        if (this.birthTime === 0) {
            let mainAtom = this.atoms.get('');

            if (!mainAtom && this.atoms.size === 1) {
                mainAtom = this.atoms.values().next().value!;
            }

            if (!mainAtom) {
                return;
            }

            for (let graphics of mainAtom.graphics) {
                if (graphics.duration !== undefined) {
                    this.duration = graphics.duration;
                }

                if (graphics.delay !== undefined) {
                    this.delay = graphics.delay;
                }
            }

            this.birthTime = startTime + this.delay;
            this.deathTime = this.duration > 0 ? this.birthTime + this.duration : Infinity;
        }
    }

    getAttributeValue<K extends keyof Graphics>(key: K): Graphics[K] | undefined {
        let result: any = undefined;

        for (let atom of this.atoms.values()) {
            for (let graphics of atom.graphics) {
                if (graphics[key] !== undefined) {
                    result = graphics[key];
                }
            }
        }

        return result;
    }
}
globalThis.ALL_FUNCTIONS.push(ViewFragment);