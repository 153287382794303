import { objectSchema } from '../type-schema/object-schema.ts';
import { Line } from './line.ts';
import { Vector, VectorLike } from './vector.ts';

/**
 *  Union type used by {@link Point.from} to create a new point, depending on what is specified:
 * - `Point`: creates a copy of the point
 * - `[x: number, y: number]`: creates a point with coordinates (x, y)
 * - `{ x?: number, y?: number }`: creates a point with coordinates (x, y), unspecified coordinates default to 0
 */
export type PointLike =
    | Point
    | { x?: number, y?: number }
    | [ number, number ];

export type PointProperties = { x: number; y: number; };

/**
 * Represents a 2D point with coordinates (x, y).
 * 
 * All methods return a new instance unless specified otherwise.
 */
export class Point {
    /**
     * X coordinate of the point.
     */
    x: number;
    /**
     * Y coordinate of the point.
     */
    y: number;

    private static RESOLVE_POINT = new Point(0, 0);

    static readonly ZERO = new Point(0, 0);

    /**
     * 
     * @param x 
     * @param y 
     */
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    static schema = objectSchema({
        x: 'number',
        y: 'number'
    }, Point);

    /**
     * Creates a new point from the specified {@link PointLike}.
     * @param pointLike 
     * @returns 
     */
    static from(pointLike: PointLike, target = new Point(0, 0)): Point {
        if (pointLike instanceof Point) {
            target.x = pointLike.x;
            target.y = pointLike.y;
        } else if (Array.isArray(pointLike)) {
            target.x = pointLike[0];
            target.y = pointLike[1];
        } else {
            target.x = pointLike.x ?? 0;
            target.y = pointLike.y ?? 0;
        }

        return target;
    }

    static resolve(pointLike: PointLike): Point {
        return Point.from(pointLike, Point.RESOLVE_POINT);
    }
    
    /**
     * Creates a point with coordinates (0, 0).
     * @returns 
     */
    static zero() {
        return new Point(0, 0);
    }
    
    /**
     * Creates a copy of the point.
     * @returns 
     */
    clone(): Point {
        return new Point(this.x, this.y);
    }

    /**
     * Creates a vector with the same coordinates as the point.
     * @returns 
     */
    toVector(): Vector {
        return new Vector(this.x, this.y);
    }

    /**
     * Creates a new point by adding the specified vector to this point.
     * @param vector 
     * @returns 
     */
    add(vector: VectorLike): Point {
        let { x, y } = Vector.from(vector);

        return new Point(this.x + x, this.y + y);
    }

    /**
     * Creates a new point by removing the specified vector from this point.
     * @param vector 
     * @returns 
     */
    sub(vector: VectorLike): Point {
        let { x, y } = Vector.from(vector);

        return new Point(this.x - x, this.y - y);
    }

    /**
     * Creates a new point by multiplying both coordinates of the point by the specified value.
     * If a tuple of values is specified, x is multiplied by the first value and y by the second.
     * @param value
     * @returns 
     */
    mult(value: number | [number, number]): Point {
        if (typeof value === 'number') {
            return new Point(this.x * value, this.y * value);
        } else {
            return new Point(this.x * value[0], this.y * value[1]);
        }
    }

    /**
     * Creates a new point by diviiding both coordinates of the point by the specified value.
     * If a tuple of values is specified, x is divided by the first value and y by the second.
     * @param value
     * @returns 
     */
    div(value: number): Point {
        if (typeof value === 'number') {
            return new Point(this.x / value, this.y / value);
        } else {
            return new Point(this.x / value[0], this.y / value[1]);
        }
    }

    /**
     * Creates a vector that goes from this point to the specified point.
     * @param point 
     * @returns 
     */
    getVectorTo(point: PointLike): Vector {
        return Vector.from([this, point]);
    }

    /**
     * Returns the distance from this point to the specified point.
     * @param point 
     * @returns 
     */
    getDistanceTo(point: PointLike): number {
        return Vector.from([this, point]).getLength();
    }

    /**
     * Returns the angle of the vector that goes from this point to the specified point.
     * @param point 
     * @returns 
     */
    getAngleTo(point: PointLike): number {
        return Vector.from([this, point]).getAngle();
    }

    /**
     * Creates a new point that is this point rotated by the specified angle around the specified point.
     * @param angle 
     * @param center 
     * @returns 
     */
    rotate(angle: number, center: PointLike) {
        let c = Point.from(center);
        let vector = c.getVectorTo(this).rotate(angle);
        
        return c.add(vector);
    }

    isZero(): boolean {
        return this.x === 0 && this.y === 0;
    }

    translate(x: number, y: number): Point {
        return new Point(this.x + x, this.y + y);
    }

    getDistanceToLine(line: Line): number {
        let { x, y } = this;
        let { x1, y1, x2, y2 } = line;
        let dx = x2 - x1;
        let dy = y2 - y1;

        if (Math.abs(dx) < 0.000001) {
            // The segment is vertical
            let [m, n] = y1 < y2 ? [y1, y2] : [y2, y1];

            if (y >= m && y <= n) {
                return Math.abs(x - x1);
            } else if (y < m) {
                return Math.hypot(x - x1, y - m);
            } else {
                return Math.hypot(x - x1, y - n);
            }
        }

        // We find `a` and `b` such as (x1,y1) + (dx,dy) * a = (x,y) + (-dy,dx) * b
        // Then if `a` is in [0, 1], it means the closest intersection point between the line and the point lies on the segment
        // otherwise, it is outside the segment and we simply calculate the distance from p to closest extremity of the segment
        let b = (y1 - y + ((x - x1) / dx) * dy) / (dx + (dy * dy / dx));
        let a = (x - b * dy - x1) / dx;

        if (a >= 0 && a <= 1) {
            return Math.abs(b * Math.hypot(dx, dy));
        } else if (a < 0) {
            return Math.hypot(x1 - x, y1 - y);
        } else {
            return Math.hypot(x2 - x, y2 - y);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Point);