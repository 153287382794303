import { Color, ColorLike } from '../../utils/color/color.ts';
import { SortedMap } from '../../utils/data-structures/sorted-map.ts';
import { ImageLike, ImageLoader } from '../../utils/dom/image-loader.ts';
import { WindowManager } from '../../utils/dom/window-manager.ts';
import { DisplaySize, DisplaySizeLike, DisplaySizeProperties } from '../../utils/geometry/display-size.ts';
import { Point } from '../../utils/geometry/point.ts';
import { Rect } from '../../utils/geometry/rect.ts';
import { clamp } from '../../utils/language/math.ts';
import { Clock } from '../../utils/time/clock.ts';
import { WebglBufferWriter } from '../../utils/webgl/webgl-buffer-writer.ts';
import { WebglDataTexture } from '../../utils/webgl/webgl-data-texture.ts';
import { WebglFrameBuffer } from '../../utils/webgl/webgl-frame-buffer.ts';
import { WebglImageTexture } from '../../utils/webgl/webgl-image-texture.ts';
import { DEFAULT_IMAGE_COLOR_REPLACE_DISTANCE_THRESHOLD, DEFAULT_VIRTUAL_VIEWPORT } from './graphics-engine-constants.ts';
import { ConfigureRendererParams, CreateSpriteSheetParams, IconAliases, ImageMetadata, LoadImageParams, UsedImageData } from './graphics-engine-types.ts';
import { GraphicsLoader } from './graphics/graphics-loader.ts';
import { GRAPHICS_INSTANCE_ATTRIBUTES, GraphicsWebglProgram, createGraphicsProgram } from './graphics/graphics-program.ts';
import { Cursor, Font, Progress } from './graphics/graphics-types.ts';
import { TextManager } from './text/text-manager.ts';
import { LayerId, LayerProperties } from './layer-types.ts';
import { Layer } from './layer.ts';
import { AudioManager, loadAudio } from './audio/audio-manager.ts';
import { MAX_UINT_16 } from '../../utils/number-constants.ts';
import { applyWebglBlending } from '../../utils/webgl/webgl-blend-function.ts';
import { GraphicsAttributeList } from './graphics/graphics-attribute-list.ts';
import { destructureSidesTuple, getImageSize } from './graphics-engine-utils.ts';
import { createCanvas } from '../../utils/dom/canvas.ts';

const DEFAULT_SPRITE_SHEET_SIZE: [number, number] = [1, 1];

export type GraphicsEngineParams = {
    clock?: Clock;
    windowManager: WindowManager;
    imageManager: ImageLoader;
};

export class GraphicsEngine {
    private windowManager: WindowManager;
    private imageLoader: ImageLoader<ImageMetadata>;
    private clock: Clock;
    private virtualViewportWidth: number = DEFAULT_VIRTUAL_VIEWPORT[0];
    private virtualViewportHeight: number = DEFAULT_VIRTUAL_VIEWPORT[1];
    private defaultLayer!: Layer;
    private layers: Map<LayerId, Layer> = new Map();
    private sortedLayers: Layer[] = [];
    private canvas: HTMLCanvasElement;
    private gl: WebGL2RenderingContext;
    private frameBuffer: WebglFrameBuffer;
    // private imageTexture: WebglImageTexture;
    // private attributesDataTexture: WebglDataTexture;
    private graphicsProgram: GraphicsWebglProgram;
    private graphicsAttributeList: GraphicsLoader = new GraphicsLoader(this);
    private colorEncodingCache: Map<ColorLike, number> = new Map();
    private displaySizeEncoding: Map<DisplaySizeLike, number> = new Map();
    private progressEncodingCache: Map<Progress, number> = new Map();
    private imageTextureCoordsEncodingCache: Map<string | null, number> = new Map();
    private imageTextureSizeEncodingCache: Map<string | null, number> = new Map();
    private dataView: DataView = new DataView(new ArrayBuffer(128));
    private iconAliases: IconAliases = {};
    private defaultFont: Font = 'Arial';
    private blockingAssetsLoadingCount: number = 0;
    private textManager: TextManager;
    private audioManager: AudioManager = new AudioManager();
    private backgroundColor: Color = Color.white();
    private loadedResourcesSinceLastFrame: Set<string> = new Set();
    private usedImages: Map<string, UsedImageData> = new Map();
    private cursor: Cursor | null = null;
    private graphicsInstanceCount: number = 0;
    private loadedFonts: Set<string> = new Set();

    constructor(params: GraphicsEngineParams) {
        this.windowManager = params.windowManager;
        this.imageLoader = params.imageManager;
        this.clock = params.clock ?? new Clock();
        this.canvas = document.createElement('canvas');
        this.gl = this.canvas.getContext('webgl2', {
            antialias: false,
            // alpha: true,
            // depth: true,
            // stencil: true,
        })!;
        this.frameBuffer = new WebglFrameBuffer(this.gl);
        // this.imageTexture = new WebglImageTexture(this.gl);
        // this.attributesDataTexture = new WebglDataTexture(this.gl, {
        //     atomicAllocationSize: DATA_TEXTURE_BLOCK_SIZE
        // });
        this.graphicsProgram = createGraphicsProgram(this.gl);
        this.textManager = new TextManager(this);
        this.imageLoader.onLoad(imageUrl => this.notifyResourceLoaded(imageUrl));
        this.resetLayers();

        // this.canvas.addEventListener('webglcontextlost', (event) => {
        //     console.log(event);
        // });
    }

    async init() {
        await this.windowManager.init();

        this.audioManager.init();

        this.resizeCanvas();
        this.windowManager.addElement(this.canvas);
        this.windowManager.onResizeEvent(() => this.resizeCanvas());

        await this.graphicsProgram.init();

        this.frameBuffer.setBackgroundColor(this.backgroundColor);
        this.clock.tick();
    }

    requestAnimationFrame(callback: () => void) {
        this.windowManager.requestAnimationFrame(callback);
    }

    private resizeCanvas() {
        this.canvas.width = this.windowManager.getWidth();
        this.canvas.height = this.windowManager.getHeight();
        this.frameBuffer.notifyResize();
        this.reloadAllTexts();
    }

    setVirtualViewport(width: number, height: number) {
        this.virtualViewportWidth = width;
        this.virtualViewportHeight = height;
        this.defaultLayer.setProperties({
            viewport: Rect.fromSize(width, height),
            cameraX: width / 2,
            cameraY: height / 2,
        });
        this.windowManager.setAspectRatio(width / height);
    }

    getVirtualViewport(): [number, number] {
        return [this.virtualViewportWidth, this.virtualViewportHeight];
    }

    getCanvas(): HTMLCanvasElement {
        return this.canvas;
    }

    getCurrentTime(): number {
        return this.clock.getCurrentTime();
    }

    getClock(): Clock {
        return this.clock;
    }

    prepareFrame() {
        this.resetUnusedImages();
        this.audioManager.resetPlayingAudios();
        this.clearGraphicsInstances();
        this.cursor = null;
    }

    renderFrame() {
        // console.time('frame');
        // this.imageTexture.syncWithGpu();
        // this.attributesDataTexture.syncWithGpu();
        this.clock.tick();
        this.frameBuffer.clear();
        this.renderLayers();
        this.frameBuffer.show();
        this.updateCursor();
        this.clearUnusedImages();
        this.audioManager.clearNonPlayingAudios();
        this.loadedResourcesSinceLastFrame.clear();

        // this.gl.finish();
        // console.timeEnd('frame');
    }

    private updateCursor() {
        this.windowManager.setCursor(this.cursor ?? 'default');
    }

    private configureWebglLayer(layer: Layer, virtualToRealRatio: number) {
        let gl = this.gl;

        layer.imageTexture.syncWithGpu();
        layer.attributesDataTexture.syncWithGpu();

        if (layer.viewport.width === this.virtualViewportWidth && layer.viewport.height === this.virtualViewportHeight) {
            gl.disable(gl.SCISSOR_TEST);
        } else {
            let viewport = layer.viewport.multiply(virtualToRealRatio);

            gl.enable(gl.SCISSOR_TEST);
            gl.scissor(viewport.x1, viewport.y1, viewport.width, viewport.height);
        }

        applyWebglBlending(gl, layer.blending);
    }

    private renderLayers() {
        let virtualToRealRatio = this.canvas.width / this.virtualViewportWidth;

        // console.log(`render`, this.clock.getCurrentTime());

        for (let layer of this.sortedLayers) {
            if (!layer.shown) {
                continue;
            }

            this.configureWebglLayer(layer, virtualToRealRatio);

            this.graphicsProgram.draw(layer.index, {
                u_currentTime: this.clock.getCurrentTime(),
                u_realCanvasSize: [this.canvas.width, this.canvas.height],
                u_virtualToRealRatio: virtualToRealRatio,
                u_cameraCenter: [layer.cameraX, layer.cameraY],
                u_cameraZoom: layer.cameraZoom,
                u_viewportCenter: [layer.viewport.x, layer.viewport.y],
                u_imageColorReplaceDistanceThreshold: DEFAULT_IMAGE_COLOR_REPLACE_DISTANCE_THRESHOLD,
                u_imageTexture: layer.imageTexture.bindGlTextureArray(0),
                u_attributesDataTexture: layer.attributesDataTexture.bindGlTexture(1),
                // u_virtualCanvasSize: [this.virtualViewportWidth, this.virtualViewportHeight],
                // u_dataTextureBlockSize: DATA_TEXTURE_BLOCK_SIZE
            });
        }
    }

    configureRenderer(params: ConfigureRendererParams) {
        let {
            waitForLoading = true
        } = params;

        if (params.backgroundColor !== undefined) {
            this.backgroundColor = Color.from(params.backgroundColor);
            this.frameBuffer.setBackgroundColor(this.backgroundColor);
        }

        if (params.virtualViewport) {
            this.setVirtualViewport(params.virtualViewport[0], params.virtualViewport[1]);
        }

        if (params.layerss) {
            for (let [key, properties] of Object.entries(params.layerss)) {
                this.updateLayer(key, properties);
            }
        }

        if (params.defaultFont) {
            this.defaultFont = params.defaultFont;
            // TODO: reload texts
        }

        if (params.iconAliases) {
            this.iconAliases = structuredClone(params.iconAliases);
            // TODO: reload texts

            for (let iconUrl of Object.values(params.iconAliases)) {
                this.loadImage(iconUrl, { waitForLoading });
            }
        }

        if (params.fonts) {
            for (let [fontFamily, fontParams] of Object.entries(params.fonts)) {
                if (this.loadedFonts.has(fontFamily)) {
                    continue;
                }

                this.loadedFonts.add(fontFamily);

                if (typeof fontParams === 'string') {
                    fontParams = { url: fontParams };
                }

                let { url, ...descriptors } = fontParams;
                let font = new FontFace(fontFamily, `url(${url})`, descriptors);

                if (waitForLoading) {
                    this.blockingAssetsLoadingCount += 1;
                }

                font.load().then(() => {
                    document.fonts.add(font);

                    this.textManager.notifyResourceLoaded(fontFamily);

                    if (waitForLoading) {
                        this.blockingAssetsLoadingCount -= 1;
                    }
                });
            }
        }

        if (params.audios) {
            for (let audioUrl of params.audios) {
                this.blockRendering(waitForLoading);

                loadAudio(audioUrl).then(() => {
                    this.unblockRendering(waitForLoading);
                })
            }
        }
    }

    private blockRendering(value: boolean) {
        if (value) {
            this.blockingAssetsLoadingCount += 1;
        }
    }

    private unblockRendering(value: boolean) {
        if (value) {
            this.blockingAssetsLoadingCount -= 1;
        }
    }

    getVirtualToRealRatio(): number {
        return this.canvas.width / this.virtualViewportWidth;
    }

    setCursor(cursor: Cursor) {
        if (cursor) {
            this.cursor = cursor;
        }
    }

    private requireLayer(layerId: LayerId): Layer {
        let layer = this.layers.get(layerId);

        if (!layer) {
            throw new Error(`unknown layer \`${layerId}\``);
        }

        return layer;
    }

    resetLayers() {
        this.layers.clear();
        this.sortedLayers.length = 0;
        this.defaultLayer = new Layer({
            gl: this.gl,
            index: 0,
            id: null,
            viewportWidth: DEFAULT_VIRTUAL_VIEWPORT[0],
            viewportHeight: DEFAULT_VIRTUAL_VIEWPORT[1]
        });
        this.defaultLayer.zIndex = 1;
        this.layers.set(null, this.defaultLayer);
        this.sortedLayers.push(this.defaultLayer)
    }

    initLayer(layerId: LayerId, properties?: Partial<LayerProperties>) {
        let previousLayer = this.layers.get(layerId);

        if (previousLayer) {
            this.layers.delete(layerId);
            this.sortedLayers.remove(previousLayer);
        }

        let layer = new Layer({
            gl: this.gl,
            index: this.layers.size,
            id: layerId,
            viewportWidth: this.virtualViewportWidth,
            viewportHeight: this.virtualViewportHeight,
            imageTexture: this.defaultLayer.imageTexture
        });

        if (properties) {
            layer.setProperties(properties);
        }

        this.layers.set(layerId, layer);
        this.sortedLayers.push(layer);
        this.sortedLayers.sort((a, b) => a.zIndex - b.zIndex);
    }

    updateLayer(layerId: LayerId, properties: Partial<LayerProperties>): void {
        this.requireLayer(layerId).setProperties(properties);

        if (properties.zIndex !== undefined) {
            this.sortedLayers.sort((a, b) => a.zIndex - b.zIndex);
        }
    }

    getLayerProperties(layerId: LayerId): LayerProperties {
        return (this.layers.get(layerId) ?? this.defaultLayer).getProperties();
    }

    getPointInLayer(layerId: LayerId, point: Point, isReal: boolean): Point {
        let layer = this.layers.get(layerId) ?? this.defaultLayer;
        let virtualToRealRatio = isReal ? this.getVirtualToRealRatio() : 1;
        let realX = point.x / virtualToRealRatio;
        let realY = point.y / virtualToRealRatio;

        return layer.getPointInLayer(realX, realY);
    }

    getDistanceToCameraCenter(layerId: LayerId, x: number, y: number): number {
        let layer = this.layers.get(layerId) ?? this.defaultLayer;
        let distance = Math.sqrt((layer.cameraX - x) ** 2 + (layer.cameraY - y) ** 2);

        return distance;
    }

    getDistanceToCameraEdges(layerId: LayerId, x: number, y: number): number {
        let layer = this.layers.get(layerId) ?? this.defaultLayer;
        let cameraHalfWidth = (layer.viewport.width / layer.cameraZoom) / 2;
        let cameraHalfHeight = (layer.viewport.height / layer.cameraZoom) / 2;
        let x1 = layer.cameraX - cameraHalfWidth;
        let x2 = layer.cameraX + cameraHalfWidth;
        let y1 = layer.cameraY - cameraHalfHeight;
        let y2 = layer.cameraY + cameraHalfHeight;

        // positive value is the point is inside the camera, negative value othwerwise
        let d = Math.min(x - x1, x2 - x, y - y1, y2 - y);

        return d;
    }

    getImageLoader(): ImageLoader {
        return this.imageLoader;
    }

    getIconAliases(): IconAliases {
        return this.iconAliases;
    }

    getDefaultFont(): Font {
        return this.defaultFont;
    }

    isImageLoaded(imageUrl: string): boolean {
        return this.getLayer(null).imageTexture.hasImage(imageUrl);
    }

    async loadImage(imageUrl: string, params: LoadImageParams = {}) {
        let {
            spriteSheetSize,
            waitForLoading = true
        } = params;

        if (waitForLoading) {
            this.blockingAssetsLoadingCount += 1;
        }

        let image = this.imageLoader.get(imageUrl);

        if (!image) {
            image = await this.imageLoader.load(imageUrl);
        }

        if (image) {
            this.storeImageInTexture(imageUrl, image);
        }

        if (!spriteSheetSize) {
            let match = imageUrl.match(/\d+x\d+/);

            if (match) {
                let [rowSize, columnSize] = match[0].split('x').map(x => parseInt(x));

                spriteSheetSize = [rowSize, columnSize];
            }
        }

        if (spriteSheetSize) {
            this.imageLoader.setMetadata(imageUrl, { spriteSheetSize });
        }

        if (waitForLoading) {
            this.blockingAssetsLoadingCount -= 1;
        }
    }

    async createSpriteSheet(params: CreateSpriteSheetParams) {
        let waitForLoading = params.waitForLoading ?? true;
        let rowSize = params.spriteUrls.length;
        let columnSize = 1;

        if (params.spriteSheetSize) {
            rowSize = params.spriteSheetSize[0];
            columnSize = params.spriteSheetSize[1];
        }

        if (waitForLoading) {
            this.blockingAssetsLoadingCount += 1;
        }

        let spriteImageList = await Promise.all(params.spriteUrls.map(url => this.imageLoader.load(url)));
        let spriteSizeList = spriteImageList.map(image => getImageSize(image));
        let [spriteWidth, spriteHeight] = spriteSizeList.reduce((acc, value) => [Math.max(acc[0], value[0]), Math.max(acc[1], value[1])], [0, 0]);
        let [stripPercentTop, stripPercentRight, stripPercentBottom, stripPercentLeft] = destructureSidesTuple(params.strip);
        let strippedSpriteWidth = Math.ceil(spriteWidth * (1 - stripPercentRight - stripPercentLeft));
        let strippedSpriteHeight = Math.ceil(spriteHeight * (1 - stripPercentTop - stripPercentBottom));
        let spriteSheetWidth = strippedSpriteWidth * rowSize;
        let spriteSheetHeight = strippedSpriteHeight * columnSize;

        if (strippedSpriteWidth <= 0 || strippedSpriteHeight <= 0) {
            return;
        }

        let spriteSheetCanvas = createCanvas({
            width: spriteSheetWidth,
            height: spriteSheetHeight,
        });
        let offsetX = Math.floor(spriteWidth * stripPercentLeft);
        let offsetY = Math.floor(spriteHeight * stripPercentTop);
        let ctx = spriteSheetCanvas.getContext('2d')!;
        
        for (let x = 0; x < rowSize; ++x) {
            for (let y = 0; y < columnSize; ++y) {
                let index = y * rowSize + x;
                let image = spriteImageList[index];

                if (!image) {
                    continue;
                }

                let leftoverX =  Math.ceil((spriteWidth - image.width) / 2);
                let leftoverY = Math.ceil((spriteHeight - image.height) / 2);
                let sx = offsetX - leftoverX;
                let sy = offsetY - leftoverY;
                let sWidth = Math.min(strippedSpriteWidth, image.width);
                let sHeight = Math.min(strippedSpriteHeight, image.height);
                let dx = x * strippedSpriteWidth;
                let dy = y * strippedSpriteHeight;
                let dWidth = strippedSpriteWidth;
                let dHeight = strippedSpriteHeight;

                ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
            }
        }

        this.imageLoader.register(params.imageId, spriteSheetCanvas);
        this.imageLoader.setMetadata(params.imageId, {
            spriteSheetSize: [rowSize, columnSize]
        });
        this.storeImageInTexture(params.imageId, spriteSheetCanvas);

        if (waitForLoading) {
            this.blockingAssetsLoadingCount -= 1;
        }
    }

    loadText(graphics: GraphicsAttributeList): string {
        let { imageId, image } = this.textManager.getTextImage(graphics);

        this.storeImageInTexture(imageId, image);

        return imageId;
    }

    loadAudio(graphics: GraphicsAttributeList): number | null {
        return this.audioManager.load(graphics);
    }

    playAudio(audioId: number) {
        this.audioManager.play(audioId);
    }

    private storeImageInTexture(imageId: string, image: ImageLike) {
        this.getImageTexture(null).addImage(imageId, image);
        this.imageTextureCoordsEncodingCache.clear();
        this.imageTextureSizeEncodingCache.clear();
        this.usedImages.set(imageId, { imageId, isUsed: true });
    }

    private discardImageFromTexture(imageId: string) {
        this.getImageTexture(null).removeImage(imageId);
        this.imageTextureCoordsEncodingCache.clear();
        this.imageTextureSizeEncodingCache.clear();
        this.usedImages.delete(imageId);
    }

    isLoadingBlockingAssets(): boolean {
        return this.blockingAssetsLoadingCount !== 0;
    }

    reloadAllTexts() {
        for (let textId of this.textManager.getAllTextIds()) {
            this.notifyResourceLoaded(textId);
        }
    }

    notifyResourceLoaded(resourceId: string) {
        this.textManager.notifyResourceLoaded(resourceId);
        this.loadedResourcesSinceLastFrame.add(resourceId);

        this.discardImageFromTexture(resourceId);
    }

    hasResourceBeenReloaded(resourceId: string | null): boolean {
        if (!resourceId) {
            return false;
        }

        return this.loadedResourcesSinceLastFrame.has(resourceId);
    }

    notifyUsedImage(imageId: string) {
        let data = this.usedImages.get(imageId);

        if (data) {
            data.isUsed = true;
            // throw new Error(`notify the usage of a non-existing image ${imageId}`);
        }
    }

    resetUnusedImages() {
        for (let data of this.usedImages.values()) {
            data.isUsed = false;
        }
    }

    clearUnusedImages() {
        for (let { imageId, isUsed } of this.usedImages.values()) {
            // TODO: different keep duration for texts?
            if (!isUsed) {
                this.textManager.deleteTextImage(imageId);
                this.discardImageFromTexture(imageId);
            }
        }
    }

    getGraphicsLoader(): GraphicsLoader {
        return this.graphicsAttributeList;
    }

    getLayerSizeMultiplier(layerId: LayerId) {
        let layer = this.layers.get(layerId) ?? this.defaultLayer;

        return this.getVirtualToRealRatio() * layer.cameraZoom;
    }

    encodeUint32ToFloat32(value: number): number {
        this.dataView.setUint32(0, value, true);

        return this.dataView.getFloat32(0, true);
    }

    encodeColorToFloat32(color: ColorLike): number {
        let value = this.colorEncodingCache.get(color);

        if (value === undefined) {
            let [r, g, b, a] = Color.from(color).toUint8Array();
            let bits = (r << 24) + (g << 16) + (b << 8) + a;

            value = this.encodeUint32ToFloat32(bits);
            this.colorEncodingCache.set(color, value);
        }

        return value;
    }

    encodeDisplaySizeToFloat32(displaySize: DisplaySizeLike, defaultPercentKey?: keyof DisplaySizeProperties): number {
        let value = this.displaySizeEncoding.get(displaySize);

        if (value === undefined) {
            let result = DisplaySize.from(displaySize, defaultPercentKey);
            let fixed = clamp(result.fixedVirtual, -8191, 8191) | 0;
            let scaledFromParentWidth = clamp(result.scaledFromParentWidth, -1, 1) * 200 | 0
            let scaledFromParentHeight = clamp(result.scaledFromParentHeight, -1, 1) * 200 | 0
            let fixedSignBits = fixed >= 0 ? 1 : 0;
            let scaledFromParentWidthSignBits =  scaledFromParentWidth >= 0 ? 1 : 0;
            let scaledFromParentHeightSignBits = scaledFromParentHeight >= 0 ? 1: 0
            let fixedBits = Math.abs(fixed) | 0;
            let scaledFromParentWidthBits = Math.abs(scaledFromParentWidth) | 0;
            let scaledFromParentHeightBits = Math.abs(scaledFromParentHeight) | 0;
            let bits = (fixedSignBits << 31)
                + (scaledFromParentWidthSignBits << 30)
                + (scaledFromParentHeightSignBits << 29)
                + (fixedBits << 16)
                + (scaledFromParentWidthBits << 8)
                + scaledFromParentHeightBits;

            value = this.encodeUint32ToFloat32(bits);
            this.displaySizeEncoding.set(displaySize, value);
        }

        return value;
    }

    encodeProgressToFloat32(progress: Progress) {
        let value = this.progressEncodingCache.get(progress);

        if (value === undefined) {
            let fixedValue = 0;
            let percentValue = 0;

            if (typeof progress === 'number') {
                fixedValue = progress | 0;
            } else {
                let number = parseFloat(progress);

                if (!isNaN(number)) {
                    if (!progress.endsWith('%')) {
                        fixedValue = number;
                    } else {
                        percentValue = number / 100;
                    }
                }
            }

            let fixedBits = clamp(fixedValue, 0, MAX_UINT_16);
            let percentBits = clamp(percentValue, 0, 1) * MAX_UINT_16 | 0;
            let bits = (fixedBits << 16) + percentBits;

            value = this.encodeUint32ToFloat32(bits);
            this.progressEncodingCache.set(progress, value);
        }

        return value;
    }

    encodeImageTextureCoordsToFloat32(layerId: LayerId, imageUrl: string | null): number {
        let value = this.imageTextureCoordsEncodingCache.get(imageUrl);

        if (true || value === undefined) {
            let rect = this.getImageTexture(layerId).getImageRect(imageUrl) ?? Rect.ZERO;

            value = this.encodeUint16PairToFloat32(rect.x1, rect.y1);
            this.imageTextureCoordsEncodingCache.set(imageUrl, value);
        }

        return value;
    }

    encodeImageTextureSizeToFloat32(layerId: LayerId, imageUrl: string | null): number {
        let value = this.imageTextureSizeEncodingCache.get(imageUrl);

        if (true || value === undefined) {
            let rect = this.getImageTexture(layerId).getImageRect(imageUrl) ?? Rect.ZERO;

            value = this.encodeUint16PairToFloat32(rect.width, rect.height);
            this.imageTextureSizeEncodingCache.set(imageUrl, value);
        }

        return value;
    }

    encodeUint16PairToFloat32(value1: number, value2: number): number {
        let data = (value1 << 16) + value2;

        this.dataView.setUint32(0, data, true);

        return this.dataView.getFloat32(0, true);
    }

    getImageSpriteSheetSize(imageId: string | null): [number, number] {
        return imageId ? this.imageLoader.getMetadata(imageId)?.spriteSheetSize ?? DEFAULT_SPRITE_SHEET_SIZE : DEFAULT_SPRITE_SHEET_SIZE;
    }

    getAttributesDataTexture(layerId: LayerId): WebglDataTexture {
        return this.getLayer(layerId).attributesDataTexture;
    }

    getImageTexture(layerId: LayerId): WebglImageTexture {
        return this.getLayer(layerId).imageTexture;
    }

    private getLayer(layerId: LayerId): Layer {
        return this.layers.get(layerId) ?? this.defaultLayer;
    }

    clearGraphicsInstances() {
        this.graphicsProgram.clearInstances();
        this.graphicsInstanceCount = 0;
    }

    allocateGraphicsInstance(layerId: LayerId = null): WebglBufferWriter<typeof GRAPHICS_INSTANCE_ATTRIBUTES> {
        let layer = this.getLayer(layerId);
        let renderPassIndex = layer.index;

        this.graphicsInstanceCount += 1;

        return this.graphicsProgram.allocateInstance(renderPassIndex);
    }

    // getComponentIdAt(x: number, y: number): number {
    //     // If nothing is displayed and we read a pixel, for some reason the background turns black after a single frame.
    //     // To avoid that we don't read any pixel if nothing is displayed.
    //     if (this.graphicsInstanceCount === 0) {
    //         return 0;
    //     }

    //     return this.frameBuffer.getComponentIdAt(x, y);
    // }

    async getComponentIdAt(x: number, y: number): Promise<number> {
        // If nothing is displayed and we read a pixel, for some reason the background turns black after a single frame.
        // To avoid that we don't read any pixel if nothing is displayed.
        if (this.graphicsInstanceCount === 0) {
            return 0;
        }

        return this.frameBuffer.getComponentIdAtAsync(x, y);
    }
}
globalThis.ALL_FUNCTIONS.push(GraphicsEngine);