import { Counter } from '../../../utils/data-structures/counter.ts';
import { MaybeAsync } from '../../../utils/language/async.ts';
import { Logger } from '../../../utils/logging/logger.ts';
import { GraphicsAttributeList } from '../graphics/graphics-attribute-list.ts';

export type AudioItem = {
    id: number;
    audioUrl: string;
    audio: MaybeAsync<HTMLAudioElement | null>;
    startTime: number;
    volume: number;
    mute: boolean;
    loop: boolean;
    started: boolean;
    playing: boolean;
}

export class AudioManager {
    private itemsById: Map<number, AudioItem> = new Map();
    private itemsByAudio: Map<string, Map<number, AudioItem>> = new Map();
    private itemIdCounter: Counter = new Counter();
    private canPlay: boolean = false;

    init() {
        let allowPlayback = () => {
            this.canPlay = true;
            document.removeEventListener('mousedown', allowPlayback);
            document.removeEventListener('keydown', allowPlayback);
        }

        document.addEventListener('mousedown', allowPlayback);
        document.addEventListener('keydown', allowPlayback);
    }

    load(attributes: GraphicsAttributeList): number | null {
        let audioAttribute = attributes.getBodyDetails('audio');
        let audioUrl = audioAttribute?.end;
        
        if (!audioUrl) {
            return null;
        }

        let startTime =  audioAttribute!.startTime;
        let item: AudioItem | undefined = this.itemsByAudio.get(audioUrl)?.get(startTime);

        if (!item) {
            item = {
                id: this.itemIdCounter.next(),
                audio: loadAudio(audioUrl).then(audio => item!.audio = audio),
                audioUrl,
                startTime,
                volume: 1,
                mute: false,
                loop: false,
                playing: false,
                started: false,
            };

            if (!this.itemsByAudio.has(audioUrl)) {
                this.itemsByAudio.set(audioUrl, new Map());
            }

            this.itemsById.set(item.id, item);
            this.itemsByAudio.get(audioUrl)!.set(startTime, item);
        }

        item.volume = attributes.requireValue('audioVolume');
        item.mute = attributes.requireValue('audioMute');
        item.loop = attributes.requireValue('audioLoop');

        if (item.audio && !(item.audio instanceof Promise)) {
            item.audio.volume = item.volume;
            item.audio.muted = item.mute;
            item.audio.loop = item.loop;
        }

        return item.id;
    }

    play(audioId: number | null) {
        let item = audioId && this.itemsById.get(audioId);

        if (!item) {
            return;
        }

        if (this.canPlay && !item.started && item.audio && !(item.audio instanceof Promise)) {
            item.audio.currentTime = 0;
            item.audio.volume = item.volume;
            item.audio.loop = item.loop;
            item.audio.muted = item.mute;
            item.audio.play();
            item.started = true;
        }

        item.playing = true;
    }

    resetPlayingAudios() {
        for (let item of this.itemsById.values()) {
            item.playing = false;
        }
    }

    clearNonPlayingAudios() {
        for (let item of this.itemsById.values()) {
            if (!item.playing) {
                if (item.audio && !(item.audio instanceof Promise)) {
                    item.audio.pause();
                }

                this.itemsById.delete(item.id);
                this.itemsByAudio.get(item.audioUrl)!.delete(item.startTime);
            }
        }
    }

    notifyCanPlay() {
        this.canPlay = true;
    }
}

export function loadAudio(url: string): Promise<HTMLAudioElement | null> {
    let audio = new Audio(url);

    return new Promise<HTMLAudioElement | null>(resolve => {
        audio.addEventListener('canplaythrough', () => {
            resolve(audio);
        });
        audio.onerror = () => {
            Logger.warn(`could not load image at ${url}`);
            resolve(null);
        };
    });
}
globalThis.ALL_FUNCTIONS.push(AudioManager);