import { Collection } from '../../utils/language/collection.ts';
import { Connection } from '../../utils/network/connection.ts';
import { arraySchema } from '../../utils/type-schema/array-schema.ts';
import { objectSchema } from '../../utils/type-schema/object-schema.ts';
import { CLIENT_DATA_SCHEMA } from '../client/client-data.ts';
import { ClientInteractionParams, EventKindMapping, EventParams } from '../room/room-manager-types.ts';

export type ServerOngoingInteraction = {
    connection: Connection;
    clientRequestId: number;
};

export type ProcessClientInteractionsParams = Omit<ClientInteractionParams, 'clientId' | 'result'>;

export const CLIENT_INTERACTION_SCHEMA = objectSchema<ProcessClientInteractionsParams>({
    requestId: 'number',
    roomId: 'string',
    interactionPath: arraySchema('string'),
    clientData: arraySchema(CLIENT_DATA_SCHEMA),
});

export type EmitEventParams<K extends keyof EventKindMapping> = {
    kind: K;
    data: EventKindMapping[K];
    eventId: number;
    clientIds: Collection<string>;
};

export type ConfigureServerParams = {
    updateInterval?: number;
};