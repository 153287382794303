import { RoomClient } from './room.ts';

export const SET_WRAPPED_CLIENTS = Symbol();

export class RoomClientListWrapper<T extends RoomClient = RoomClient> {
    private clients: Map<string, T> = new Map();

    [Symbol.iterator]() {
        return this.clients[Symbol.iterator]();
    }

    has(key: string) {
        return this.clients.has(key);
    }

    get(key: string): T | undefined {
        return this.clients.get(key);
    }

    entries() {
        return this.clients.entries();
    }

    values() {
        return this.clients.values();
    }

    keys() {
        return this.clients.keys();
    }

    forEach(callback: (value: T, key: string) => void) {
        this.clients.forEach(callback);
    }

    [SET_WRAPPED_CLIENTS](wrappedMap: Map<string, T>) {
        this.clients = wrappedMap;
    }
}
globalThis.ALL_FUNCTIONS.push(RoomClientListWrapper);