import { AddGridParams, GridPanel } from '../../helpers/widgets/grid-panel.ts';
import { Point } from '../../utils/geometry/point.ts';
import { Rect, RectLike } from '../../utils/geometry/rect.ts';
import { Collection, iterateCollection } from '../../utils/language/collection.ts';
import { Constructor } from '../../utils/language/types.ts';
import { Easing } from '../../utils/time/easing.ts';
import { ClientApiReading } from '../client/client-api-reading.ts';
import { Client } from '../client/client.ts';
import { DrawContext } from '../client/draw-context.ts';
import { ComponentModifier, componentModifierToCallback } from '../component/component-modifier.ts';
import { Component, ComponentLike } from '../component/component.ts';
import { DummyComponent, formatComponent } from '../component/dummy-component.ts';
import { GraphicsEngine } from '../graphics-engine/graphics-engine.ts';
import { destructureGraphicsAttribute } from '../graphics-engine/graphics/graphics-attribute-details.ts';
import { GraphicsAttributeList } from '../graphics-engine/graphics/graphics-attribute-list.ts';
import { GraphicsAttributeAlias } from '../graphics-engine/graphics/graphics-attribute-metadata.ts';
import { getGraphicsAttributeValue } from '../graphics-engine/graphics/graphics-attribute-types.ts';
import { DrawOrder } from '../graphics-engine/graphics/graphics-types.ts';
import { COPIED_ATTRIBUTES_FROM_MAIN_ATOM, GRAPHICS_METADATA, Graphics } from '../graphics-engine/graphics/graphics.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { RoomClient } from '../room/room.ts';
import { ViewFragment } from './view-fragment.ts';
import { ViewHelperStack } from './view-helper-stack.ts';
import { ViewLayout } from './view-layout.ts';
import { ViewAtom, ViewBasicAttributes, ViewFragmentPriority, ViewState, ViewTooltipOptions, getDefaultBasicAttributes } from './view-types.ts';

export class View extends ClientApiReading {
    readonly client: Client;
    private graphicsEngine: GraphicsEngine;
    private parent: View | null;
    private component: Component;
    private atoms: Map<string, ViewAtom> = new Map();
    private sortedAtoms: ViewAtom[] = [];
    private fragmentsBySourceId: Map<number, ViewFragment[]> = new Map();
    private sortedFragments: ViewFragment[] = [];
    private activeFragment: ViewFragment | null = null;
    private layouts: ViewLayout[] = [];
    private isTooltipFor: Component | null = null;
    private shouldReload: boolean = false;
    private displayIndex: number = 0;
    private dummyComponents: ViewHelperStack<DummyComponent> = new ViewHelperStack(() => new DummyComponent());
    private gridPanels: ViewHelperStack<GridPanel> = new ViewHelperStack(() => new GridPanel());
    private basicAttributesFromParent: ViewBasicAttributes = getDefaultBasicAttributes();
    private basicAttributes: ViewBasicAttributes = getDefaultBasicAttributes();
    private isHiddenFlag: boolean = false;
    private tooltipShowDelay: number = 0;
    private tooltipHideDelay: number = 0;
    private ownerSourceId: number = 0;
    private state: ViewState = ViewState.Pending;
    private birthTime: number = 0;
    private transitionStartTime: number = 0;
    private transitionEndTime: number = 0;

    constructor(client: Client, component: Component, parent: View | null) {
        super(client);
        this.client = client;
        this.graphicsEngine = client.getGraphicsEngine();
        this.component = component;
        this.parent = parent;
    }

    get rect(): Rect {
        return this.basicAttributes.rect;
    }

    getComponent(): Component {
        return this.component;
    }

    addFragment(sourceId: number, priority: number, callback: (view: View, component: Component) => void) {
        let sourceFragmentList = this.fragmentsBySourceId.get(sourceId);

        if (!sourceFragmentList) {
            sourceFragmentList = [];
            this.fragmentsBySourceId.set(sourceId, sourceFragmentList);
        }

        let currentFragment = this.sortedFragments.findLast(fragment => fragment.priority === priority);
        let currentChildren: View[] = [];

        if (currentFragment) {
            currentChildren = currentFragment.children.slice();
        }

        let newFragment = new ViewFragment(sourceId, priority);

        sourceFragmentList.push(newFragment);
        this.sortedFragments.push(newFragment);
        this.sortedFragments.sort((a, b) => a.priority - b.priority);
        this.activeFragment = newFragment;

        callback(this, this.component);

        let newChildren = new Set(newFragment.children);

        for (let child of currentChildren) {
            if (!newChildren.has(child)) {
                child.destroy(sourceId);
            }
        }
    }

    enableFragment(sourceId: number) {
        let currentTime = this.graphicsEngine.getCurrentTime();
        let fragmentList = this.fragmentsBySourceId.get(sourceId);

        if (!fragmentList) {
            return;
        }

        for (let fragment of fragmentList) {
            let startTime = currentTime;

            if (fragment.priority === ViewFragmentPriority.Self) {
                startTime = Math.max(startTime, this.transitionEndTime);
            }

            fragment.computeLifetime(startTime);

            if (fragment.priority === ViewFragmentPriority.Self) {
                this.transitionStartTime = fragment.birthTime;
                this.transitionEndTime = fragment.birthTime + fragment.duration;
            }

            for (let other of this.sortedFragments) {
                if (other !== fragment && other.priority === fragment.priority) {
                    other.deathTime = Math.min(other.deathTime, fragment.birthTime);
                    other.nextSourceId = sourceId;
                }
            }

            for (let childView of fragment.children) {
                childView.enableFragment(sourceId);
            }
        }
    }

    deleteFragment(sourceId: number) {
        let list = this.fragmentsBySourceId.get(sourceId);

        if (!list) {
            return;
        }

        for (let fragment of list) {
            for (let childView of fragment.children) {
                childView.destroy();
            }

            this.sortedFragments.remove(fragment);
        }

        this.fragmentsBySourceId.delete(sourceId);
        this.shouldReload = true;
    }

    private getActiveFragment(): ViewFragment {
        return this.activeFragment!;
    }

    paint(key: string, graphics: Graphics | undefined): void;
    paint(graphics: Graphics | undefined): void;
    paint(arg1: Graphics | undefined | string, arg2?: Graphics | undefined) {
        let graphics: Graphics | undefined = undefined;;
        let forceKey: string | undefined = undefined;

        if (arguments.length > 1) {
            graphics = arg2 as Graphics;
            forceKey = arg1 as string;
        } else {
            graphics = arg1 as Graphics;
        }

        if (!graphics) {
            return;
        }

        let activeFragment = this.getActiveFragment();
        let key = activeFragment.addGraphics(graphics, forceKey);

        if (!this.atoms.has(key)) {
            let atom: ViewAtom = {
                key,
                index: -1,
                attributes: new GraphicsAttributeList(this.graphicsEngine),
            };

            this.atoms.set(key, atom);
            this.sortedAtoms.push(atom);
        }

        if (!key && !activeFragment.isModifier()) {
            let rectLike = getGraphicsAttributeValue(graphics.rect);
            let positionLike = getGraphicsAttributeValue(graphics.position);
            let rect = rectLike && Rect.resolve(rectLike);
            let position = positionLike && Point.resolve(positionLike);
            let x = getGraphicsAttributeValue(graphics.x);
            let y = getGraphicsAttributeValue(graphics.y);
            let width = getGraphicsAttributeValue(graphics.width);
            let height = getGraphicsAttributeValue(graphics.height);
            let detectable = getGraphicsAttributeValue(graphics.detectable);
            let layerId = getGraphicsAttributeValue(graphics.layerId);
            let target = activeFragment.priority === ViewFragmentPriority.Parent ? this.basicAttributesFromParent : this.basicAttributes;

            target.rect.x = x ?? position?.x ?? rect?.x ?? target.rect.x;
            target.rect.y = y ?? position?.y ?? rect?.y ?? target.rect.y;
            target.rect.width = width ?? rect?.width ?? target.rect.width;
            target.rect.height = height ?? rect?.height ?? target.rect.height;

            target.detectable = detectable ?? target.detectable;
            target.layerId = layerId !== undefined ? layerId : target.layerId;
        }
    }

    addChild(child: Collection<ComponentLike>, modifier: ComponentModifier = null) {
        let activeFragment = this.getActiveFragment();
        let sourceId = activeFragment.sourceId;
        let modifierCallback = modifier ? componentModifierToCallback(modifier) : null;

        for (let childComponentLike of iterateCollection(child)) {
            let childComponent = formatComponent(childComponentLike, () => this.getNextDummyComponent());
            let childView = this.client.updateView(sourceId, childComponent, this);

            activeFragment.addChild(childView);

            childView.parent = this;
            childView.fillParentFragment(sourceId, childView => {
                let { layerId, detectable, rect } = this.basicAttributes;

                childView.paint({ layerId, detectable, rect });
                modifierCallback?.(childView, childComponent);
            });
            childView.fillSelfFragment(sourceId);
        }
    }

    fillParentFragment(sourceId: number, callback: (view: View) => void) {
        this.addFragment(sourceId, ViewFragmentPriority.Parent, self => {
            callback(self);
        });
    }

    fillSelfFragment(sourceId: number) {
        this.addFragment(sourceId, ViewFragmentPriority.Self, self => {
            self.ownerSourceId = sourceId;
            self.resetBasicAttributes();
            self.resetHelpers();
            self.component.render(self);
            self.processLayouts();
        });
    }

    addGrid(params: AddGridParams) {
        let gridPanel = this.gridPanels.next();
        let rect = this.rect;

        if (typeof params.rect === 'function') {
            rect = params.rect(rect);
        } else if (params.rect) {
            rect = Rect.from(params.rect);
        }

        gridPanel.setup(params);

        this.addChild(gridPanel, rect);
    }

    layout(rect?: RectLike): ViewLayout {
        let layout = new ViewLayout(this);

        this.layouts.push(layout);

        if (rect) {
            layout.setRootRect(rect);
        }

        return layout;
    }

    processLayouts() {
        for (let layout of this.layouts) {
            layout.finish();
        }

        this.layouts.length = 0;
    }

    getRect(): Rect {
        return this.basicAttributes.rect.clone();
    }

    getParent(): View | null {
        return this.parent;
    }

    getTransitionStartTime(): number {
        return this.transitionStartTime;
    }

    getTransitionEndTime(): number {
        return this.transitionEndTime;
    }

    setTransitionEndTime(transitionEndTime: number) {
        this.transitionEndTime = Math.max(this.transitionEndTime, transitionEndTime);
    }

    deleteDeadFragments(currentTime: number) {
        let fragmentIndex = this.sortedFragments.findIndex(fragment => currentTime >= fragment.deathTime);

        if (fragmentIndex === -1) {
            return;
        }

        let fragment = this.sortedFragments[fragmentIndex];
        let list = this.fragmentsBySourceId.get(fragment.sourceId)!;

        list.remove(fragment);

        if (list.length === 0) {
            this.fragmentsBySourceId.delete(fragment.sourceId)
        }

        this.sortedFragments.splice(fragmentIndex, 1);

        for (let childView of fragment.children) {
            childView.destroyIfForgotten(fragment.nextSourceId);
        }

        this.shouldReload = true;
        // console.log(`DELETE FRAGMENT ${this.component.constructor.name} (${fragment.priority})`);

        if (this.state !== ViewState.Dead && fragment.priority === ViewFragmentPriority.Self && !this.sortedFragments.find(fragment => fragment.priority === ViewFragmentPriority.Self)) {
            // console.log(`DELETE COMPONENT ${this.component.constructor.name} (${fragment.priority})`);

            // TODO: only destroy the view if it is not displayed elsewhere
            this.state = ViewState.Dead;
        }

        this.deleteDeadFragments(currentTime);
    }

    enableBornFragments(currentTime: number) {
        for (let fragment of this.sortedFragments) {
            if (!fragment.isEnabled && currentTime >= fragment.birthTime) {
                fragment.isEnabled = true;
                this.shouldReload = true;
                // console.log(`ENABLE FRAGMENT ${this.component.constructor.name} (${fragment.priority})`);

                if (this.state === ViewState.Pending && fragment.priority === ViewFragmentPriority.Self) {
                    // console.log(`ENABLE COMPONENT ${this.component.constructor.name} (${fragment.priority})`);
                    this.state = ViewState.Alive;

                    if (this.birthTime === 0) {
                        this.birthTime = fragment.birthTime;
                    }
                }
            }
        }
    }

    private computeAtoms() {
        let mainAtomAttributes = this.atoms.get('')!.attributes;

        for (let atom of this.sortedAtoms) {
            atom.attributes.clear();
            atom.index = -1;

            if (atom.key !== '') {
                for (let attributeKey of COPIED_ATTRIBUTES_FROM_MAIN_ATOM) {
                    atom.attributes.copyAttributeFrom(mainAtomAttributes, attributeKey);
                }

                let defaultAnchorX = mainAtomAttributes.getBodyDetails('x');
                let defaultAnchorY = mainAtomAttributes.getBodyDetails('y');

                if (defaultAnchorX && !atom.attributes.getBodyDetails('anchorX')) {
                    atom.attributes.setDetails('anchorX', defaultAnchorX, false);
                }

                if (defaultAnchorY && !atom.attributes.getBodyDetails('anchorY')) {
                    atom.attributes.setDetails('anchorY', defaultAnchorY, false);
                }
            }

            for (let i = 0; i < this.sortedFragments.length; ++i) {
                let fragment = this.sortedFragments[i];
                let startTime = fragment.birthTime;
                let fragmentAtom = fragment.atoms.get(atom.key);
                let isFragmentModifier = fragment.isModifier();
                let mainDefaultDuration: number | undefined = undefined;
                let mainDefaultEasing: Easing | undefined = undefined;

                if (!fragmentAtom || !fragment.isEnabled) {
                    continue;
                }

                if (!isFragmentModifier && fragment.duration === 0) {
                    startTime = this.birthTime;
                }

                if (atom.index === -1) {
                    atom.index = (i << 16) + fragmentAtom.index;
                }
                
                if (atom.key === '') {
                    for (let graphics of fragmentAtom.graphics) {
                        mainDefaultDuration = graphics.duration ?? mainDefaultDuration;
                        mainDefaultEasing = graphics.easing ?? mainDefaultEasing;
                    }
                }

                for (let graphics of fragmentAtom.graphics) {
                    let defaultDuration = graphics.duration ?? mainDefaultDuration;
                    let defaultDelay = undefined;
                    let defaultEasing = graphics.easing ?? mainDefaultEasing;

                    for (let key in graphics) {
                        let graphicsKey = key as keyof Graphics;
                        let value = graphics[graphicsKey];
                        let metadata = GRAPHICS_METADATA[graphicsKey];
                        let isModifier = !!(metadata.glsl && isFragmentModifier);
                        let defaultToEndValue = !isFragmentModifier;
                        let alias = metadata.alias as GraphicsAttributeAlias<any> | undefined;

                        if (alias) {
                            alias(atom.attributes, value, isModifier, startTime, defaultDuration, defaultDelay, defaultEasing, defaultToEndValue);
                        } else {
                            let attributeDetails = destructureGraphicsAttribute(value as any, startTime, defaultDuration, defaultDelay, defaultEasing, defaultToEndValue);

                            atom.attributes.setDetails(graphicsKey, attributeDetails, isModifier);
                        }
                    }
                }
            }
        }

        this.sortedAtoms.sort((a, b) => a.index - b.index);
    }

    private loadIfNecessary() {
        if (this.shouldReload) {
            this.computeAtoms();
            this.shouldReload = false;
        }

        for (let atom of this.atoms.values()) {
            atom.attributes.loadIfNecessary();
        }
    }

    draw(context: DrawContext) {
        this.deleteDeadFragments(context.currentTime);
        this.enableBornFragments(context.currentTime);

        if (this.state === ViewState.Pending) {
            return;
        }

        if (this.state === ViewState.Dead) {
            this.destroy();
            return;
        }

        this.displayIndex = context.register(this.basicAttributes.detectable ? this.component : null);

        this.loadIfNecessary();
        this.drawAtoms(this.displayIndex, 'before-children');
        this.drawChildren(context);
        this.drawAtoms(this.displayIndex, 'after-children');
    }

    private drawAtoms(displayIndex: number, layer: DrawOrder) {
        for (let atom of this.sortedAtoms) {
            if (atom.attributes.getLayer() === layer) {
                atom.attributes.draw(displayIndex);
            }
        }
    }

    private drawChildren(context: DrawContext) {
        for (let fragment of this.sortedFragments) {
            if (!fragment.isEnabled) {
                continue;
            }

            let filter = false;

            for (let childView of fragment.children) {
                childView.draw(context);

                if (childView.isDead()) {
                    filter = true;
                }
            }

            if (filter) {
                fragment.children = fragment.children.filter(child => !child.isDead());
            }
        }
    }

    tooltip(params: ViewTooltipOptions) {
        this.tooltipShowDelay = params.showDelay ?? 0;
        this.tooltipHideDelay = params.hideDelay ?? 0;
    }

    getTooltipShowDelay(): number {
        return this.tooltipShowDelay;
    }

    getTooltipHideDelay(): number {
        return this.tooltipHideDelay;
    }

    isDead(): boolean {
        return this.state === ViewState.Dead;
    }

    destroyIfForgotten(sourceId: number) {
        if (this.client.getView(this.component) !== this) {
            this.destroy(sourceId);
        }
    }

    destroy(sourceId?: number) {
        if (sourceId && sourceId === this.ownerSourceId) {
            return;
        }

        for (let atom of this.atoms.values()) {
            atom.attributes.destroy();
        }

        this.client.notifyViewDestroyed(this);

        for (let fragment of this.sortedFragments) {
            for (let childView of fragment.children) {
                childView.destroy(sourceId);
            }
        }
    }

    getPointerPosition(layerId?: LayerId): Point {
        if (layerId === undefined) {
            layerId = this.basicAttributes.layerId;
        }

        return super.getPointerPosition(layerId);
    }

    *viewTree(): IterableIterator<View> {
        yield this;

        for (let fragment of this.sortedFragments) {
            for (let view of fragment.children) {
                yield* view.viewTree();
            }
        }
    }

    getActiveClientId(): string {
        return this.client.getClientId()!;
    }

    getActiveClient<T extends RoomClient>(constructor: Constructor<T>): T {
        let roomManager = this.client.getRoomManager();
        let roomWrapper = roomManager.getActiveRoomWrapper();
        let clientId = roomManager.getActiveClientId()!;
        let client = roomWrapper?.clients.get(clientId);

        if (client && client instanceof constructor) {
            return client;
        } else {
            throw new Error(`active client is not an instance of ${constructor.name}`);
        }
    }

    getComponentView(component: Component): View | undefined {
        return this.client.getView(component);
    }

    markAsTooltipFor(component: Component) {
        for (let view of this.viewTree()) {
            view.isTooltipFor = component;
        }
    }

    getTooltipTarget(): Component | null {
        return this.isTooltipFor;
    }

    getDisplayIndex(): number {
        return this.displayIndex;
    }

    setHidden(value: boolean) {
        this.isHiddenFlag = value;
    }

    isHidden(): boolean {
        return this.isHiddenFlag;
    }

    private resetBasicAttributes() {
        this.basicAttributes.layerId = this.basicAttributesFromParent.layerId;
        this.basicAttributes.detectable = this.basicAttributesFromParent.detectable;
        this.basicAttributes.rect.x = this.basicAttributesFromParent.rect.x;
        this.basicAttributes.rect.y = this.basicAttributesFromParent.rect.y;
        this.basicAttributes.rect.width = this.basicAttributesFromParent.rect.width;
        this.basicAttributes.rect.height = this.basicAttributesFromParent.rect.height;
    }

    private resetHelpers() {
        this.dummyComponents.reset();
        this.gridPanels.reset();
    }

    private getNextDummyComponent(): DummyComponent {
        return this.dummyComponents.next();
    }

    getCurrentTime() {
        return this.graphicsEngine.getCurrentTime();
    }

    private getPrevFragment(priority: ViewFragmentPriority): ViewFragment | undefined {
        return this.sortedFragments.findLast(fragment => {
            return fragment.sourceId !== this.activeFragment?.sourceId
                && fragment.priority === priority;
        });
    }

    getPrevRect(): Rect | undefined {
        let parentFragment = this.getPrevFragment(ViewFragmentPriority.Parent);
        let selfFragment = this.getPrevFragment(ViewFragmentPriority.Self);
        let attribute = selfFragment?.getAttributeValue('rect') ?? parentFragment?.getAttributeValue('rect');
        let value = getGraphicsAttributeValue(attribute);

        return value && Rect.from(value);
    }
}
globalThis.ALL_FUNCTIONS.push(View);