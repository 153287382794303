import { Line, Point, Rect, Vector } from 'outpost';
import { Player } from './player.ts';
import { PLAYER_HEIGHT, PLAYER_WIDTH } from './constants.ts';

export function getBounceAngle(player: Player, line: Line): number | null {
    let mustBounce = player.position.getDistanceToLine(line) < PLAYER_WIDTH / 2;

    if (mustBounce) {
        let angle = player.engineStrength.angle;
        let lineAngle = modAngle(line.getOrientationVector().getAngle());
        let playerAngle = modAngle(angle);
        let a1 = lineAngle - playerAngle;
        let a2 = playerAngle - lineAngle;
        let additionalAngle = Math.abs(a1) < Math.abs(a2) ? a1 : a2;

        return angle + Math.PI - additionalAngle * 2;
    }

    return null;
}

function modAngle(angle: number): number {
    return (angle + Math.PI) % Math.PI;
}

function rayCast(source: Point, direction: Vector, wall: Line): number | null {
    let d = wall.getStart(); // start point of the wall
    let m = Vector.from(wall); // orientation vector of the wall

    let crossProduct = (m.x * direction.y - m.y * direction.x);

    if (crossProduct === 0) {
        return null;
    }

    let a, b;

    if (m.x !== 0) {
        a = (m.x * (d.y - source.y) + m.y * (source.x - d.x)) / crossProduct; // number of steps for the ray to reach the intersection point
        b = (source.x + a * direction.x - d.x) / m.x; // number of steps for the point on the wall to reach the intersection point
    } else {
        a = (m.y * (d.x - source.x) + m.x * (source.y - d.y)) / (-crossProduct);
        b = (source.y + a * direction.y - d.y) / m.y;
    }

    if (b < 0 || b > 1) {
        return null;
    }

    return a; 
}