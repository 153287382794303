import { emptyIterator, singleValueIterator } from './iterator.ts';

/**
 * A generic collection of items. It is compatible with arrays, sets, maps, {@link ItemArray}, {@link ItemMap}.
 */
export type Collection<T> =
    | null
    | T
    | Iterable<T>

/**
 * If `T` is a collection, refers to the item type of the collection.
 * Otherwise, `never`.
 */
export type CollectionItem<T> = T extends Collection<infer R> ? R : never;

export function iterateCollection<T>(collection: Collection<T> | undefined): Iterable<T> {
    if (collection === null || collection === undefined) {
        return emptyIterator();
    } else if (typeof collection === 'object' && Symbol.iterator in collection) {
        return collection;
    } else {
        return singleValueIterator(collection);
    }
}

export function collectionToArray<T>(collection: Collection<T> | undefined): T[] {
    return [...iterateCollection(collection)];
}

export function collectionToSet<T>(collection: Collection<T> | undefined, target?: Set<T>): Set<T> {
    if (target) {
        target.clear();

        for (let item of iterateCollection(collection)) {
            target.add(item);
        }

        return target;
    } else {
        return new Set(iterateCollection(collection));
    }
}

export function isCollection<T>(value: any): value is Collection<T> {
    return value === undefined || value === null || (typeof value === 'object' && Symbol.iterator in value);
}

export function getCollectionSize<T>(collection: Collection<T> | undefined): number {
    let result: number | undefined;

    if (collection === undefined || collection === null) {
        return 0;
    } else {
        result = (collection as any).length ?? (collection as any).size;
    }

    if (result === undefined) {
        result = 0;

        for (let _ of iterateCollection(collection)) {
            result += 1;
        }
    }

    return result;
}

export function getCollectionItemAt<T>(collection: Collection<T> | undefined, index: number): T | undefined {
    if (Array.isArray(collection)) {
        return collection[index];
    }

    let currentIndex = 0;
    
    for (let item of iterateCollection(collection)) {
        if (currentIndex === index) {
            return item;
        }

        currentIndex += 1;
    }

    return undefined;
}

export function getCollectionIndexOf<T>(collection: Collection<T> | undefined, item: T): number {
    if (Array.isArray(collection)) {
        return collection.indexOf(item);
    }

    let index = 0;
        
    for (let currentItem of iterateCollection(collection)) {
        if (currentItem === item) {
            return index;
        }

        index += 1;
    }

    return -1;
}