import { getAbsoluteRatio, mix } from '../language/math.ts';
import { Writeable } from '../language/object.ts';
import { DisplaySize, DisplaySizeLike } from './display-size.ts';
import { GridLayoutParams } from './rect-types.ts';
import { Line } from './line.ts';
import { Point, PointLike } from './point.ts';
import { getLandmarkCoefficients, RectLandmark } from './rect-landmark.ts';
import { Transform, TransformLike } from './transform.ts';
import { objectSchema } from '../type-schema/object-schema.ts';
import { TypeSchema } from '../type-schema/type-schema.ts';
import { getHorizontalAlignMultiplier } from './horizontal-align.ts';
import { getVerticalAlignMultiplier } from './vertical-align.ts';

/**
 * Union type used by {@link Rect.from} to create a new rectangle, depending to what is specified:
 * - `Rect`: creates a clone of the specified instance.
 * - `size: number`: creates a `Rect` with both the width and height set to the specified value and the top-left corner set to (0, 0).
 * - `[width: number, height: number]`: creates a `Rect` with the specified width and height, and the top-left corner set to (0, 0).
 * - `[x: number, y: number, width: number, height: number]`: creates a `Rect` with the specified center (x, y), width and height.
 * - `object`: creates a `Rect` based on the specified fields. The rectangle's properties are computed as follows:
 *      - width:
 *          - copied from `width` if it is specified;
 *          - or else from `x1` and `x2` if they are both specified;
 *          - or else from the computed height and `aspectRatio` if it is specified;
 *          - or else defaults to 0.
 *      - height:
 *          - copied from `height` if it is specified;
 *          - or else from `y1` and `y2` if they are both specified;
 *          - or else from the computed width and `aspectRatio` if it is specified;
 *          - or else defaults to 0.
 *      - x:
 *          - copied from `x` if it is specified;
 *          - or else computed from `x1` and `x2` if they are both specified;
 *          - or else computed from the computed width and either `x1` or `x2` if only one of them is specified;
 *          - or else defaults to half of the computed width.
 *      - y:
 *          - copied from `y` if it is specified;
 *          - or else computed from `y1` and `y2` if they are both specified;
 *          - or else computed from the computed height and either `y1` or `y2` if only one of them is specified;
 *          - or else defaults to half of the computed height.
 * 
 */
export type RectLike =
    | Rect
    | [number, number]
    | [number, number, number, number]
    | { x?: number, y?: number, width?: number, height?: number, x1?: number, y1?: number, x2?: number, y2?: number, aspectRatio?: number; };
// TODO: change this last line to force at least one field

export type RectProperties = { x: number; y: number; height: number; width: number; };

/**
 * Represents a rectangle with center (`x`, `y`) and size (`width`, `height`).
 * 
 * Unless specified otherwise, methods create new instances of `Rect` and do not modify the instance it is called on.
 */
export class Rect {
    /**
     * X coordinate of center of the rectangle.
     */
    x: number;
    /**
     * Y coordinate of center of the rectangle.
     */
    y: number;
    /**
     * Width of the rectangle.
     */
    width: number;
    /**
     * Height of the rectangle.
     */
    height: number;

    static readonly ZERO = new Rect(0, 0, 0, 0);
    static readonly NEGATIVE = new Rect(-1, -1, -1, -1);
    private static RESOLVE_RECT = new Rect(0, 0, 0, 0);

    /**
     * X coordinate of the top-left corner of the rectangle.
     */
    get x1(): number {
        return this.x - this.width / 2;
    }

    /**
     * Y coordinate of the top-left corner of the rectangle.
     */
    get y1(): number {
        return this.y - this.height / 2;
    }

    /**
     * X coordinate of the bottom-right corner of the rectangle.
     */
    get x2(): number {
        return this.x + this.width / 2;
    }

    /**
     * Y coordinate of the bottom-right corner of the rectangle.
     */
    get y2(): number {
        return this.y + this.height / 2;
    }

    /**
     * @param x X coordinate of center of the rectangle.
     * @param y Y coordinate of center of the rectangle.
     * @param width Width of the rectangle.
     * @param height Height of the rectangle.
     */
    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    static schema = objectSchema<{}>({
        x: 'number',
        y: 'number',
        width: 'number',
        height: 'number',
    }, Rect) as TypeSchema<Rect>;

    /**
     * Creates a `Rect` with all properties set to 0.
     * @returns 
     */
    static zero(): Rect {
        return new Rect(0, 0, 0, 0);
    }

    /**
     * Creates a `Rect` by specifying its top-left corner coordinates and its size.
     * @param x1 
     * @param y1 
     * @param width 
     * @param height 
     * @returns 
     */
    static fromTopLeft(x1: number, y1: number, width: number, height: number): Rect {
        let x = x1 + width / 2;
        let y = y1 + height / 2;

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a `Rect` by only specifying its size. `x` and `y` are set so that the top-left corner is at (0, 0).
     * @param width 
     * @param height 
     * @returns 
     * @example
     * let rect = Rect.fromSize(6, 2); // { x: 3, y: 1, width: 6, height: 2 }
     */
    static fromSize(width: number, height: number): Rect {
        let x = width / 2.0;
        let y = height / 2.0;

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a `Rect` by specifying its top-left and bottom-right corners.
     * @param x1 
     * @param y1 
     * @param x2 
     * @param y2 
     * @returns 
     */
    static fromCorners(x1: number, y1: number, x2: number, y2: number): Rect {
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let width = x2 - x1;
        let height = y2 - y1;

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a `Rect` by specifying one of its landmark point (e.g top-left corner, center) and its size.
     * @param landmark 
     * @param x 
     * @param y 
     * @param width 
     * @param height 
     * @returns 
     */
    static fromLandmark(landmark: RectLandmark, x: number, y: number, width: number, height: number): Rect {
        let coef = getLandmarkCoefficients(landmark);

        return new Rect(x - width * coef.x / 2, y - height * coef.y / 2, width, height);
    }

    /**
     * Creates a `Rect` that includes all the specified rectangles.
     * If none is passed, returns a `Rect` with all properties set to 0.
     * @param rectList 
     * @returns 
     */
    static fromRectList(rectList: RectLike[]): Rect {
        if (rectList.length === 0) {
            return Rect.zero();
        }

        let x1 = Infinity;
        let y1 = Infinity;
        let x2 = -Infinity;
        let y2 = -Infinity;

        for (let rectLike of rectList) {
            let rect = Rect.from(rectLike);

            x1 = Math.min(x1, rect.x1);
            y1 = Math.min(y1, rect.y1);
            x2 = Math.max(x2, rect.x2);
            y2 = Math.max(y2, rect.y2);
        }

        return Rect.fromCorners(x1, y1, x2, y2);
    }

    /**
     * Creates a `Rect` depending on the specified {@link RectLike}.
     * @param value 
     * @returns 
     */
    static from(value: RectLike, target: Rect = new Rect(0, 0, 0, 0)): Rect {
        let x: number | undefined = undefined;
        let y: number | undefined = undefined;
        let width: number | undefined = undefined;
        let height: number | undefined = undefined;

        if (value instanceof Rect) {
            x = value.x;
            y = value.y;
            width = value.width;
            height = value.height;
        } else if (Array.isArray(value)) {
            let array = value as any[];

            if (value.length === 2) {
                x = value[0] / 2;
                y = value[1] / 2;
                width = value[0];
                height = value[1];
            } else {
                x = array[0];
                y = array[1];
                width = array[2];
                height = array[3];
            }
        } else {
            if (value.width !== undefined) {
                width = value.width;
            } else if (value.x1 !== undefined && value.x2 !== undefined) {
                width = value.x2 - value.x1;
            }

            if (value.height !== undefined) {
                height = value.height;
            } else if (value.y1 !== undefined && value.y2 !== undefined) {
                height = value.y2 - value.y1;
            }

            if (width === undefined && value.aspectRatio !== undefined && height !== undefined) {
                width = height * value.aspectRatio;
            } else if (height === undefined && value.aspectRatio !== undefined && width !== undefined) {
                height = width / value.aspectRatio;
            }

            width = width ?? 0;
            height = height ?? 0;

            if (value.x !== undefined) {
                x = value.x;
            } else if (value.x1 !== undefined && value.x2 !== undefined) {
                x = (value.x1 + value.x2) / 2;
            } else if (value.x1 !== undefined) {
                x = value.x1 + width / 2;
            } else if (value.x2 !== undefined) {
                x = value.x2 - width / 2;
            } else {
                x = width / 2;
            }

            if (value.y !== undefined) {
                y = value.y;
            } else if (value.y1 !== undefined && value.y2 !== undefined) {
                y = (value.y1 + value.y2) / 2;
            } else if (value.y1 !== undefined) {
                y = value.y1 + height / 2;
            } else if (value.y2 !== undefined) {
                y = value.y2 - height / 2;
            } else {
                y = height / 2;
            }
        }

        target.x = x ?? 0;
        target.y = y ?? 0;
        target.width = width ?? 0;
        target.height = height ?? 0;

        return target;
    }

    static resolve(value: RectLike): Rect {
        return Rect.from(value, Rect.RESOLVE_RECT);
    }

    /**
     * Creates a `Rect` by doing a linear interpolaton of the two specified rectangles. Each property is computed independantely.
     * @param rectStart 
     * @param rectEnd 
     * @param t Value between 0 and 1.
     * @param target If specified, copy the result on the given instance rather than creating a new one.
     * @returns 
     */
    static mix(rectStart: Rect, rectEnd: Rect, t: number, target?: Rect): Rect {
        let result = (target ?? Rect.zero()) as Writeable<Rect>;

        result.x = mix(rectStart.x, rectEnd.x, t);
        result.y = mix(rectStart.y, rectEnd.y, t);
        result.width = mix(rectStart.width, rectEnd.width, t);
        result.height = mix(rectStart.height, rectEnd.height, t);

        return result;
    }

    static floor(rectLike: RectLike): Rect {
        return Rect.from(rectLike).floor();
    }

    /**
     * Copy all properties of the instance from the specified other `Rect`.
     * @param source 
     * @returns The instance the method is called on.
     */
    copyFrom(source: Rect): Rect {
        this.x = source.x;
        this.y = source.y;
        this.width = source.width;
        this.height = source.height;

        return this;
    }

    /**
     * Creates a copy of the rectangle.
     * @returns 
     */
    clone(): Rect {
        return new Rect(this.x, this.y, this.width, this.height);
    }

    /**
     * Returns the width of the rectangle divided by 2.
     * @returns 
     */
    getHalfWidth(): number {
        return this.width / 2;
    }

    /**
     * Returns the height of the rectangle divided by 2.
     * @returns 
     */
    getHalfHeight(): number {
        return this.height / 2;
    }

    /**
     * Returns the width of the rectangle divided by its height.
     * @returns
     */
    getAspectRatio(): number {
        return this.width / this.height;
    }

    getArea(): number {
        return this.width * this.height;
    }

    /**
     * Creates a copy of the rectangle, except that its `x` property is set to the specified value.
     * @param x 
     * @returns 
     */
    withX(x: number): Rect {
        return new Rect(x, this.y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except that its `y` property is set to the specified value.
     * @param y 
     * @returns 
     */
    withY(y: number): Rect {
        return new Rect(this.x, y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except that its `width` property is set to the specified value.
     * @param width 
     * @returns 
     */
    withWidth(width: DisplaySizeLike): Rect {
        let realWidth = DisplaySize.resolve(width, this, 'scaledFromParentWidth');

        return new Rect(this.x, this.y, realWidth, this.height);
    }

    /**
     * Creates a copy of the rectangle, except that its `height` property is set to the specified value.
     * @param height
     * @returns 
     */
    withHeight(height: DisplaySizeLike): Rect {
        let realHeight = DisplaySize.resolve(height, this, 'scaledFromParentHeight');

        return new Rect(this.x, this.y, this.width, realHeight);
    }

    /**
     * Creates a copy of the rectangle, except that its `width` and `height` properties are set to the specified values.
     * @param width 
     * @param height 
     * @returns 
     */
    withSize(width: DisplaySizeLike, height: DisplaySizeLike): Rect {
        let realWidth = DisplaySize.resolve(width, this, 'scaledFromParentWidth');
        let realHeight = DisplaySize.resolve(height, this, 'scaledFromParentHeight');

        return new Rect(this.x, this.y, realWidth, realHeight);
    }

    /**
     * Creates a copy of the rectangle, except its `x` and `width` properties are set so its left side lands on the specified X coordinate.
     * @param x1 
     * @returns 
     */
    withX1(x1: number): Rect {
        let x = x1 + this.width / 2;

        return new Rect(x, this.y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except its `y` and `height` properties are set so its top side lands on the specified Y coordinate.
     * @param x1 
     * @returns 
     */
    withY1(y1: number): Rect {
        let y = y1 + this.height / 2;

        return new Rect(this.x, y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except its `x` and `width` properties are set so its right side lands on the specified X coordinate.
     * @param x1 
     * @returns 
     */
    withX2(x2: number): Rect {
        let x = x2 - this.width / 2;

        return new Rect(x, this.y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except its `y` and `height` properties are set so its bottom side lands on the specified Y coordinate.
     * @param x1 
     * @returns 
     */
    withY2(y2: number): Rect {
        let y = y2 - this.height / 2;

        return new Rect(this.x, y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except that its `x` and `y` properties are set to the specified values.
     * @param x 
     * @param y 
     * @returns 
     */
    withCenter(x: number, y: number): Rect {
        return new Rect(x, y, this.width, this.height);
    }

    swapDimensions() {
        return new Rect(this.x, this.y, this.height, this.width);
    }

    /**
     * Returns `true` if both the width and heigh of the rectanle are equal to 0.
     * @returns 
     */
    isZero(): boolean {
        return this.width === 0 && this.height === 0;
    }

    isEmpty(): boolean {
        return this.width === 0 || this.height === 0;
    }

    /**
     * Indicates if the specified point is contained in the rectangle. Points which are exactly on the edge of the rectangle are considered to be outside of it.
     * @param x 
     * @param y 
     * @returns 
     */
    contains(x: number, y: number): boolean {
        return x > this.x1 && x < this.x2 && y > this.y1 && y < this.y2;
    }

    /**
     * Returns the center of the rectangle.
     * @returns 
     */
    getCenter(): Point {
        return new Point(this.x, this.y);
    }

    getCorners(): Point[] {
        return [
            this.getTopLeftCorner(),
            this.getTopRightCorner(),
            this.getBottomRightCorner(),
            this.getBottomLeftCorner(),
        ];
    }

    /**
     * Returns the four lines making up the rectangle, in this order:
     * - top-left to top-right
     * - top-right to bottom-right
     * - bottom-right to bottom-left
     * - bottom-left to top-left
     * @returns 
     */
    getSides(): Line[] {
        return [
            this.getTopSide(),
            this.getRightSide(),
            this.getBottomSide(),
            this.getLeftSide(),
        ];
    }

    getTopSide(): Line {
        return new Line(this.x1, this.y1, this.x2, this.y1);
    }

    getRightSide(): Line {
        return new Line(this.x2, this.y1, this.x2, this.y2);
    }

    getBottomSide(): Line {
        return new Line(this.x2, this.y2, this.x1, this.y2);
    }

    getLeftSide(): Line {
        return new Line(this.x1, this.y2, this.x1, this.y1);
    }

    getTopLeftCorner(): Point {
        return new Point(this.x1, this.y1);
    }

    getTopRightCorner(): Point {
        return new Point(this.x2, this.y1);
    }

    getBottomRightCorner(): Point {
        return new Point(this.x2, this.y2);
    }

    getBottomLeftCorner(): Point {
        return new Point(this.x1, this.y2);
    }

    /**
     * Same as {@link Rect.contains}, but takes a {@link PointLike} as parameter.
     * @param point 
     * @returns 
     */
    containsPoint(point: PointLike): boolean {
        let { x, y } = Point.resolve(point);

        return this.contains(x, y);
    }

    /**
     * Returns the point at the specified rectangle's landmark.
     * @param landmark 
     * @returns 
     */
    getLandmarkPoint(landmark: RectLandmark): Point {
        let coef = getLandmarkCoefficients(landmark);
        let x = this.x + this.width * coef.x / 2;
        let y = this.y + this.height * coef.y / 2;

        return new Point(x, y);
    }

    /**
     * Returns the lowest of these two values:
     * - distance from this rectangle's right side to the other rectangle's left side
     * - distance from this rectangle's left side to the other rectangle's right side
     * @param other 
     * @returns 
     */
    getHorizontalSpaceUntil(other: Rect): number {
        let dx1 = other.x1 - this.x2;
        let dx2 = this.x1 - other.x2;

        return Math.max(0, dx1, dx2);
    }

    /**
     * Returns the lowest of these two values:
     * - distance from this rectangle's bottom side to the other rectangle's top side
     * - distance from this rectangle's top side to the other rectangle's bottom side
     * @param other 
     * @returns 
     */
    getVerticalSpaceUntil(other: Rect): number {
        let dy1 = other.y1 - this.y2;
        let dy2 = this.y1 - other.y2;

        return Math.max(0, dy1, dy2);
    }

    /**
     * Creates a copy of the rectangle, except its corners' coordinates are rounded to the nearest integer.
     * @returns 
     */
    round(): Rect {
        let x1 = Math.round(this.x1);
        let y1 = Math.round(this.y1);
        let x2 = Math.round(this.x2);
        let y2 = Math.round(this.y2);

        return Rect.fromCorners(x1, y1, x2, y2);
    }

    /**
     * Creates a copy of the rectangle, except its corners' coordinates are floored to the closest integer below.
     * @returns 
     */
    floor(): Rect {
        let x1 = Math.floor(this.x1);
        let y1 = Math.floor(this.y1);
        let x2 = Math.floor(this.x2);
        let y2 = Math.floor(this.y2);

        return Rect.fromCorners(x1, y1, x2, y2);
    }

    /**
     * Creates a copy of the rectangle, except its corners' coordinates are ceiled to the closest integer above.
     * @returns 
     */
    ceil(): Rect {
        let x1 = Math.ceil(this.x1);
        let y1 = Math.ceil(this.y1);
        let x2 = Math.ceil(this.x2);
        let y2 = Math.ceil(this.y2);

        return Rect.fromCorners(x1, y1, x2, y2);
    }

    /**
     * Creates a copy of the rectangle, except its center is translated by the specified values.
     * @param tx 
     * @param ty 
     * @returns 
     */
    translate(tx: number, ty: number): Rect {
        let x = this.x + tx;
        let y = this.y + ty;

        return new Rect(x, y, this.width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except its width and height are multiplied by the specified values.
     * @param ratioWidth 
     * @param ratioHeight 
     * @returns 
     */
    scale(ratioWidth: number, ratioHeight: number = ratioWidth): Rect {
        let width = this.width * ratioWidth;
        let height = this.height * ratioHeight;

        return new Rect(this.x, this.y, width, height);
    }

    /**
     * Creates a copy of the rectangle, except its width is multiplied by the specified value.
     * @param ratio 
     * @returns 
     */
    scaleWidth(ratio: number): Rect {
        let width = this.width * ratio;

        return new Rect(this.x, this.y, width, this.height);
    }

    /**
     * Creates a copy of the rectangle, except its height is multiplied by the specified value.
     * @param ratio 
     * @returns 
     */
    scaleHeight(ratio: number): Rect {
        let height = this.height * ratio;

        return new Rect(this.x, this.y, this.width, height);
    }

    /**
     * Creates a copy of the rectangle, except all its properties are multiplied by the specified value.
     * @param ratio 
     * @returns 
     */
    multiply(ratio: number): Rect {
        let x = this.x * ratio;
        let y = this.y * ratio;
        let width = this.width * ratio;
        let height = this.height * ratio;

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a copy of the rectangle, except its width and height are multiplied by the specified values.
     * The rectangle's center is positionned so the specified point does not move.
     * @param cx 
     * @param cy 
     * @param ratioWidth 
     * @param ratioHeight 
     * @returns 
     */
    scaleTowards(cx: number, cy: number, ratioWidth: number, ratioHeight: number = ratioWidth): Rect {
        let x = cx + (this.x - cx) * ratioWidth;
        let y = cy + (this.y - cy) * ratioHeight;
        let width = this.width * ratioWidth;
        let height = this.height * ratioHeight;

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a copy of the rectangle, except its width and height are multiplied by the specified values.
     * The rectangle's center is positionned so the point at the specified landmark does not move.
     * @param landmark 
     * @param ratioWidth 
     * @param ratioHeight 
     * @returns 
     */
    scaleTowardsLandmark(landmark: RectLandmark, ratioWidth: number, ratioHeight: number = ratioWidth): Rect {
        let { x, y } = this.getLandmarkPoint(landmark);

        return this.scaleTowards(x, y, ratioWidth, ratioHeight);
    }

    /**
     * Creates a new rectangle by adding twice the specified values to its width and height.
     * @param borderWidth 
     * @param borderHeight 
     * @returns 
     */
    pad(borderWidth: DisplaySizeLike, borderHeight: DisplaySizeLike = borderWidth): Rect {
        let width = this.width + DisplaySize.resolve(borderWidth, this, 'scaledFromParentWidth') * 2;
        let height = this.height + DisplaySize.resolve(borderHeight, this, 'scaledFromParentHeight') * 2;

        return new Rect(this.x, this.y, width, height);
    }

    /**
     * Creates a new rectangle by substracting twice the specified values from its width and height.
     * @param borderWidth 
     * @param borderHeight 
     * @returns 
     */
    strip(borderWidth: DisplaySizeLike, borderHeight: DisplaySizeLike = borderWidth): Rect {
        let width = this.width - DisplaySize.resolve(borderWidth, this, 'scaledFromParentWidth') * 2;
        let height = this.height - DisplaySize.resolve(borderHeight, this, 'scaledFromParentHeight') * 2;

        return new Rect(this.x, this.y, width, height);
    }

    /**
     * Creates a new rectangle by adding the specified border to either its width, height, or both.
     * The center of the rectangle is computed so only the sides in contact with the landmark move.
     * @param landmark 
     * @param border 
     * @returns 
     */
    padFromLandmark(landmark: RectLandmark, border: DisplaySizeLike): Rect {
        let coef = getLandmarkCoefficients(landmark);
        let realBorder = DisplaySize.resolve(border, this);
        let dw = realBorder * coef.x;
        let dh = realBorder * coef.y;
        let x = this.x + dw / 2;
        let y = this.y + dh / 2;
        let width = this.width + Math.abs(dw);
        let height = this.height + Math.abs(dh);

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a new rectangle by substracting the specified border to either its width, height, or both.
     * The center of the rectangle is computed so only the sides in contact with the landmark move.
     * @param landmark 
     * @param border 
     * @returns 
     */
    stripFromLandmark(landmark: RectLandmark, border: DisplaySizeLike): Rect {
        let coef = getLandmarkCoefficients(landmark);
        let realBorder = DisplaySize.resolve(border, this);
        let dw = realBorder * coef.x;
        let dh = realBorder * coef.y;
        let x = this.x - dw / 2;
        let y = this.y - dh / 2;
        let width = this.width - Math.abs(dw);
        let height = this.height - Math.abs(dh);

        return new Rect(x, y, width, height);
    }

    /**
     * Creates a new rectangle by increasing either its width or height, so it matches the specified aspect ratio.
     * @param aspectRatio 
     * @returns 
     */
    padToMatchAspectRatio(aspectRatio: number | null): Rect {
        // TODO: add an optional direction towards which to scale the rectangle
        if (!aspectRatio) {
            return this;
        }

        let widthFromHeight = this.height * aspectRatio;
        let heightFromWidth = this.width / aspectRatio;
        let widthToPad = 0;
        let heightToPad = 0;

        if (this.width < widthFromHeight) {
            widthToPad = widthFromHeight - this.width;
        } else {
            heightToPad = heightFromWidth - this.height;
        }

        let width = this.width + widthToPad;
        let height = this.height + heightToPad;

        return new Rect(this.x, this.y, width, height);
    }

    /**
     * Creates a new rectangle by decreasing either its width or height, so it matches the specified aspect ratio.
     * @param aspectRatio 
     * @returns 
     */
    stripToMatchAspectRatio(aspectRatio: number | null): Rect {
        if (!aspectRatio) {
            return this;
        }

        let widthFromHeight = this.height * aspectRatio;
        let heightFromWidth = this.width / aspectRatio;
        let widthToStrip = 0;
        let heightToStrip = 0;

        if (this.width > widthFromHeight) {
            widthToStrip = this.width - widthFromHeight;
        } else {
            heightToStrip = this.height - heightFromWidth;
        }

        let width = this.width - widthToStrip;
        let height = this.height - heightToStrip;

        return new Rect(this.x, this.y, width, height);
    }

    /**
     * Creates a new rectangle whose center is symmetrical relative to the specified point.
     * @param cx 
     * @param cy 
     * @returns 
     */
    mirror(cx: number, cy: number): Rect {
        let x = 2 * cx - this.x;
        let y = 2 * cy - this.y;

        return new Rect(x, y, this.width, this.height);
    }

    /**
     * Split the rectangle into multiple smaller ones.
     * @param fromLandmark The landmark from which to split. Must be `top`, `bottom`, `left` or `right`.
     * @param size Array of sizes (either width or height, depending on `landmark`) of the created rectangles.
     * If a non-array is passed, it is converted to a single-item array.
     * @param margin Empty space between the rectangles.
     * @returns An array of rectangles containing one more items than the number of sizes specified in `size` (the last one is computed from the remaining space).
     */
    split(fromLandmark: RectLandmark, size: (DisplaySizeLike | null)[], margin: DisplaySizeLike = 0): Rect[] {
        let result: Rect[] = [];
        let sizeList = size.map(value => value === null ? null : DisplaySize.resolve(value, this));
        let innerMargin = DisplaySize.resolve(margin, this);
        let coef = getLandmarkCoefficients(fromLandmark);
        let mx = Math.abs(coef.x);
        let my = Math.abs(coef.y);
        let totalSize = this.width * mx + this.height * my;
        let remainingSize = totalSize - sizeList.reduce<number>((a, b) => a + (b ?? 0), 0) - sizeList.length * innerMargin;
        let { x, y } = this.getLandmarkPoint(fromLandmark);
        let nullReplaced = false;

        for (let i = 0; i < sizeList.length; ++i) {
            if (sizeList[i] === null) {
                sizeList[i] = remainingSize;
                nullReplaced = true;
                break;
            }
        }

        if (!nullReplaced) {
            sizeList.push(remainingSize);
        }

        for (let size of sizeList) {
            if (size === null) {
                continue;
            }

            let width = size * mx + this.width * (1 - mx);
            let height = size * my + this.height * (1 - my);
            let rect = Rect.fromLandmark(fromLandmark, x, y, width, height);

            result.push(rect);
            x -= (size + innerMargin) * coef.x;
            y -= (size + innerMargin) * coef.y;
        }

        return result;
    }

    transform(transform: TransformLike): Rect {
        let { tx, ty, sx, sy } = Transform.from(transform);

        return new Rect(
            this.x + tx,
            this.y + ty,
            this.width * sx,
            this.height * sy,
        );
    }

    /**
    * Creates a new rectangle adjacent to this one.
    * @param selfLandmark Landmark of this rectangle from which to compute the neighbor.
    * @param spawnLandmark Landmark of the created rectangle from which it "grows".
    * @param width Width of the created rectangle.
    * @param height Height of the cretaed rectange.
    * @param margin Empty space between this rectangle and the created rectangle.
    * @returns 
    */
    from(selfLandmark: RectLandmark, spawnLandmark: RectLandmark, width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        let baseCoef = getLandmarkCoefficients(selfLandmark);
        let spawnCoef = getLandmarkCoefficients(spawnLandmark);
        let basePoint = this.getLandmarkPoint(selfLandmark);
        let realWidth = DisplaySize.resolve(width, this, 'scaledFromParentWidth');
        let realHeight = DisplaySize.resolve(height, this, 'scaledFromParentHeight');
        let realMargin = DisplaySize.resolve(margin, this, 'scaledFromParentHeight');
        let dx = Math.sign(baseCoef.x + spawnCoef.x * 1.5) * -1;
        let dy = Math.sign(baseCoef.y + spawnCoef.y * 1.5) * -1;
        let x = basePoint.x + realMargin * dx;
        let y = basePoint.y + realMargin * dy;

        return Rect.fromLandmark(spawnLandmark, x, y, realWidth, realHeight);
    }

    fromInwards(landmark: RectLandmark, width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from(landmark, landmark, width, height, margin);
    }

    fromOutwards(landmark: RectLandmark, width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0) {
        let coef = getLandmarkCoefficients(landmark);
        let spawnLandmark = { x: -coef.x, y: -coef.y };

        return this.from(landmark, spawnLandmark, width, height, margin);
    }

    fromCentered(landmark: RectLandmark, width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0) {
        return this.from(landmark, 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same center and the specified width and height.
     * @param width 
     * @param height 
     * @returns 
     */
    fromCenter(width: DisplaySizeLike, height: DisplaySizeLike): Rect {
        return this.fromInwards('center', width, height);
    }

    /**
     * Creates a new rectangle with the same left side and the specified width and height.
     * @param width 
     * @param height
     * @param margin
     */
    fromLeftInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('left', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same right side and the specified width and height.
     * @param width 
     * @param height
     * @param margin
     */
    fromRightInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('right', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same top side and the specified height and width.
     * @param width 
     * @param height 
     * @param margin
     */
    fromTopInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('top', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same bottom side and the specified height and width.
     * @param width 
     * @param height 
     * @param margin
     */
    fromBottomInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('bottom', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same top-left corner and the specified width and height.
     * @param width 
     * @param height 
     * @param margin
     * @returns 
     */
    fromTopLeftInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('top-left', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same top-right corner and the specified width and height.
     * @param width 
     * @param height 
     * @param margin
     * @returns 
     */
    fromTopRightInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('top-right', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same bottom-left corner and the specified width and height.
     * @param width 
     * @param height 
     * @param margin
     * @returns 
     */
    fromBottomLeftInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('bottom-left', width, height, margin);
    }

    /**
     * Creates a new rectangle with the same bottom-right corner and the specified width and height.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromBottomRightInwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromInwards('bottom-right', width, height, margin);
    }

    /**
    * Creates a new rectangle on the left of this one, with the same height and the specified width.
    * @param width 
    * @param height 
    * @param margin 
    * @returns 
    */
    fromLeftOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.fromOutwards('left', width, height, margin);
    }

    /**
     * Creates a new rectangle on the right of this one, with the same height and the specified width.
     * @param width 
     * @param height
     * @param margin 
     * @returns 
     */
    fromRightOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.fromOutwards('right', width, height, margin);
    }

    /**
     * Creates a new rectangle above this one, with the same width and the specified height.
     * @param width
     * @param height 
     * @param margin 
     * @returns 
     */
    fromTopOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.fromOutwards('top', width, height, margin);
    }

    /**
     * Creates a new rectangle below this one, with the same width and the specified height.
     * @param width
     * @param height 
     * @param margin 
     * @returns 
     */
    fromBottomOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.fromOutwards('bottom', width, height, margin);
    }

    /**
    * Creates a new rectangle whose bottom-right corner matches the parent's top-left corner.
    * @param width 
    * @param heavily
    * @param height 
    * @returns 
    */
    fromTopLeftOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromOutwards('top-left', width, height, margin);
    }

    /**
     * Creates a new rectangle whose bottom-left corner matches the parent's top-right corner.
     * @param width 
     * @param height 
     * @param margin
     * @returns 
     */
    fromTopRightOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromOutwards('top-right', width, height, margin);
    }

    /**
     * Creates a new rectangle whose top-right corner matches the parent's bottom-left corner.
     * @param width 
     * @param height 
     * @param margin
     * @returns 
     */
    fromBottomLeftOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromOutwards('bottom-left', width, height, margin);
    }

    /**
     * Creates a new rectangle whose top-left corner matches the parent's bottom-right corner.
     * @param width 
     * @param height 
     * @param margin
     * @returns 
     */
    fromBottomRightOutwards(width: DisplaySizeLike, height: DisplaySizeLike, margin?: DisplaySizeLike): Rect {
        return this.fromOutwards('bottom-right', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center matches the parent's top side middle.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromTopCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('top', 'center', width, height, margin);
    }

    /**
    * Creates a new rectangle whose center matches the parent's right side middle.
    * @param width 
    * @param height 
    * @param margin 
    * @returns 
    */
    fromRightCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('right', 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center matches the parent's bottom side middle.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromBottomCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('bottom', 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center matches the parent's left side middle.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromLeftCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('left', 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center is the top-left corner of this one, with the specified width and height.
     * Specifying a margin pushes this new rectangle out of this one by the specified distance.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromTopLeftCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('top-left', 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center is the top-right corner of this one, with the specified width and height.
     * Specifying a margin pushes this new rectangle out of this one by the specified distance.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromTopRightCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('top-right', 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center is the bottom-right corner of this one, with the specified width and height.
     * Specifying a margin pushes this new rectangle out of this one by the specified distance.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromBottomRightCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('bottom-right', 'center', width, height, margin);
    }

    /**
     * Creates a new rectangle whose center is the bottom-left corner of this one, with the specified width and height.
     * Specifying a margin pushes this new rectangle out of this one by the specified distance.
     * @param width 
     * @param height 
     * @param margin 
     * @returns 
     */
    fromBottomLeftCentered(width: DisplaySizeLike, height: DisplaySizeLike, margin: DisplaySizeLike = 0): Rect {
        return this.from('bottom-left', 'center', width, height, margin);
    }

    /**
     * Layout the rectangle as a grid according to the specified parameters and returns the computed cells.
     * @param params 
     * @returns {object} grid
     * @returns {Rect[]} grid.cells Cells of the grid.
     * @returns {number} grid.itemCountPerRow Number of cells per row.
     * @returns {number} grid.itemCountPerColumn Number of cells per column.
     */
    computeGridLayout(params: Partial<GridLayoutParams>): { cells: Rect[], itemCountPerRow: number, itemCountPerColumn: number; } {
        let {
            itemCount,
            itemAspectRatio,
            minRowSize,
            maxRowSize,
            minColumnSize,
            maxColumnSize,
            margin,
            outerMargin,
            innerMargin,
            horizontalOuterMargin,
            verticalOuterMargin,
            horizontalInnerMargin,
            verticalInnerMargin,
            appendHorizontalThenVertical,
            horizontalAlign,
            verticalAlign,
            ignoreEmptyCells,
        } = Object.assign(new GridLayoutParams(), params);

        let rowSize = Math.max(minRowSize, 1);
        let columnSize = Math.max(minColumnSize, 1);
        let rectAspectRatio = this.getAspectRatio();
        let horizontalAlignMultiplier = getHorizontalAlignMultiplier(horizontalAlign);
        let verticalAlignMultiplier = getVerticalAlignMultiplier(verticalAlign);

        while (rowSize * columnSize < itemCount) {
            let horizontalPreviewAspectRatio = ((rowSize + 1) / columnSize) * itemAspectRatio;
            let verticalPreviewAspectRatio = (rowSize / (columnSize + 1)) * itemAspectRatio;

            let horizontalRatio = getAbsoluteRatio(rectAspectRatio, horizontalPreviewAspectRatio);
            let verticalRatio = getAbsoluteRatio(rectAspectRatio, verticalPreviewAspectRatio);

            let canExpandHorizontally = rowSize < maxRowSize;
            let canExpandVertically = columnSize < maxColumnSize;
            let preferExpandHorizontally = horizontalRatio < verticalRatio || (horizontalRatio === verticalRatio && !appendHorizontalThenVertical);

            if (canExpandHorizontally && (preferExpandHorizontally || !canExpandVertically)) {
                rowSize += 1;
            } else if (canExpandVertically) {
                columnSize += 1;
            } else {
                break;
            }
        }

        let displayedItemCount: number = Math.min(itemCount, Math.round(rowSize * columnSize));
        let itemCountPerStep: number = rowSize;

        if (!appendHorizontalThenVertical) {
            itemCountPerStep = columnSize;
        }

        let finalHorizontalOuterMargin = DisplaySize.resolve(horizontalOuterMargin ?? outerMargin ?? margin ?? 0, this);
        let finalVerticalOuterMargin = DisplaySize.resolve(verticalOuterMargin ?? outerMargin ?? margin ?? 0, this);
        let finalHorizontalInnerMargin = DisplaySize.resolve(horizontalInnerMargin ?? innerMargin ?? margin ?? 0, this);
        let finalVerticalInnerMargin = DisplaySize.resolve(verticalInnerMargin ?? innerMargin ?? margin ?? 0, this);

        let itemWidth = (this.width - (finalHorizontalOuterMargin * 2) - (finalHorizontalInnerMargin * (rowSize - 1))) / rowSize;
        let itemHeight = (this.height - (finalVerticalOuterMargin * 2) - (finalVerticalInnerMargin * (columnSize - 1))) / columnSize;
        let itemRect = new Rect(0, 0, itemWidth, itemHeight).stripToMatchAspectRatio(itemAspectRatio);
        itemWidth = itemRect.width;
        itemHeight = itemRect.height;

        // rowSize = Math.floor((this.width - 2 * finalHorizontalOuterMargin - finalHorizontalInnerMargin) / (itemWidth + finalHorizontalInnerMargin));
        // columnSize = Math.floor((this.height - 2 * finalVerticalOuterMargin - finalVerticalInnerMargin) / (itemHeight + finalVerticalInnerMargin));

        let actualRowSize = rowSize;
        let actualColumnSize = columnSize;

        if (ignoreEmptyCells) {
            actualRowSize = Math.min(rowSize, appendHorizontalThenVertical ? itemCount : Math.ceil(itemCount / columnSize));
            actualColumnSize = Math.min(columnSize, !appendHorizontalThenVertical ? itemCount : Math.ceil(itemCount / rowSize));
        }

        let leftoverWidth = this.width - (finalHorizontalOuterMargin * 2) - (finalHorizontalInnerMargin * (actualRowSize - 1)) - (itemWidth * actualRowSize);
        let leftoverHeight = this.height - (finalVerticalOuterMargin * 2) - (finalVerticalInnerMargin * (actualColumnSize - 1)) - (itemHeight * actualColumnSize);

        finalHorizontalOuterMargin += leftoverWidth * horizontalAlignMultiplier;
        finalVerticalOuterMargin += leftoverHeight * verticalAlignMultiplier;

        let xStart = this.x1 + finalHorizontalOuterMargin;
        let yStart = this.y1 + finalVerticalOuterMargin;
        let cells: Rect[] = [];

        for (let i = 0; i < displayedItemCount; ++i) {
            let xIndex = i % itemCountPerStep;
            let yIndex = Math.floor(i / itemCountPerStep);

            if (!appendHorizontalThenVertical) {
                let tmp = xIndex;
                xIndex = yIndex;
                yIndex = tmp;
            }

            let itemX = xStart + xIndex * (finalHorizontalInnerMargin + itemWidth) + (itemWidth / 2);
            let itemY = yStart + yIndex * (finalVerticalInnerMargin + itemHeight) + (itemHeight / 2);

            itemRect = itemRect.withCenter(itemX, itemY);

            cells.push(itemRect);
        }

        // let finalGridRect = Rect.fromRectList(cells);
        // let dx = (this.width - finalHorizontalOuterMargin * 2) - finalGridRect.width;
        // let dy = (this.height - finalVerticalOuterMargin * 2) - finalGridRect.height;

        // for (let rect of cells) {
        //     // @ts-ignore
        //     rect.x += dx * horizontalAlign;
        //     // @ts-ignore
        //     rect.y += dy * verticalAlign;
        // }

        return {
            cells,
            itemCountPerRow: rowSize,
            itemCountPerColumn: columnSize
        };
    }
}

function getItemCounts(itemCount: number, itemAspectRatio: number, gridAspectRatio: number): [number, number] {
    // TODO: minimize empty space on the sides
    let itemWidth = itemAspectRatio / gridAspectRatio;
    let itemHeight = 1;
    let totalWidth = itemWidth;
    let totalHeight = itemHeight;
    let w = 1;
    let h = 1;

    while (w * h < itemCount) {
        if (totalWidth <= totalHeight || Math.floor(totalWidth + itemWidth) === Math.floor(totalWidth)) {
            w += 1;
            totalWidth += itemWidth;
        } else {
            h += 1;
            totalHeight += itemHeight;
        }
    }

    return [w, h];
}
globalThis.ALL_FUNCTIONS.push(Rect);