import { ColorLike } from '../../../utils/color/color.ts';
import { DisplaySizeLike } from '../../../utils/geometry/display-size.ts';
import { HorizontalAlign } from '../../../utils/geometry/horizontal-align.ts';
import { Point, PointLike } from '../../../utils/geometry/point.ts';
import { Rect, RectLike } from '../../../utils/geometry/rect.ts';
import { VerticalAlign } from '../../../utils/geometry/vertical-align.ts';
import { Easing } from '../../../utils/time/easing.ts';
import { LayerId } from '../layer-types.ts';
import { GraphicsAttributeDetails, destructureGraphicsAttributesMapped } from './graphics-attribute-details.ts';
import { GraphicsAttributeMetadata, GraphicsAttributeType, aliasValue, animatableColor, animatableDisplaySize, animatableFloat, animatableOptionalFloat, animatableProgress, staticValue } from './graphics-attribute-metadata.ts';
import { Cursor, FillDirection, Font, ImageFit, DrawOrder, RotationDirection } from './graphics-types.ts';

export type Graphics = { [Key in keyof typeof GRAPHICS_METADATA]?: GraphicsAttributeType<typeof GRAPHICS_METADATA[Key]> };

export const GRAPHICS_METADATA = {
    key: staticValue<string | null>(null),
    duration: staticValue<number>(0),
    delay: staticValue<number>(0),
    easing: staticValue<Easing>('linear'),
    layerId: staticValue<LayerId>(null),
    drawOrder: staticValue<DrawOrder>('before-children'),
    detectable: staticValue<boolean>(true),
    cursor: staticValue<Cursor | null>(null),
    x: animatableFloat(0),
    y: animatableFloat(0),
    width: animatableFloat(0),
    height: animatableFloat(0),
    offsetX: animatableDisplaySize(0),
    offsetY: animatableDisplaySize(0),
    anchorX: animatableOptionalFloat(null),
    anchorY: animatableOptionalFloat(null),
    angle: animatableFloat(0),
    scale: animatableFloat(1),
    borderRadius: animatableDisplaySize(0),
    strokeSize: animatableDisplaySize('100%'),
    fillPercentRadial: animatableFloat(1),
    fillPercentRadialDirection: staticValue<RotationDirection>('clockwise'),
    fillPercentRadialStartAngle: animatableFloat(0),
    fillPercentLinear: animatableFloat(1),
    fillPercentLinearDirection: staticValue<FillDirection>('left-to-right'),
    replaceColorSrc: animatableColor(null),
    replaceColorDst: animatableColor(null),
    alpha: animatableFloat(1),
    brightness: animatableFloat(1),
    grayscale: animatableFloat(0),
    tint: animatableColor('white'),
    stretch: animatableFloat(0),
    stretchMultiplier: animatableFloat(1),
    horizontalAlign: staticValue<HorizontalAlign>('center'),
    verticalAlign: staticValue<VerticalAlign>('middle'),
    color: animatableColor(null),
    image: staticValue<string | null>(null),
    imageFit: staticValue<ImageFit>('contain'),
    imageSprite: animatableProgress(0),
    text: staticValue<string | null>(null),
    textFont: staticValue<Font>('default'),
    textSize: staticValue<DisplaySizeLike>('50%'),
    textColor: staticValue<ColorLike>('black'),
    textPadding: staticValue<DisplaySizeLike>(0),
    textBold: staticValue<boolean>(false),
    textItalic: staticValue<boolean>(false),
    textMultiline: staticValue<boolean>(false),
    textCursorIndex: staticValue<number>(-1),
    textCursorShow: staticValue<boolean>(false),
    audio: staticValue<string | null>(null),
    audioVolume: staticValue<number>(1),
    audioMute: staticValue<boolean>(false),
    audioLoop: staticValue<boolean>(false),

    rect: aliasValue<RectLike>((list, value, isModifier, startTime, duration, delay, easing, useEndValue) => {
        list.setDetails('x', destructureGraphicsAttributesMapped(value, rect => Rect.resolve(rect).x, startTime, duration, delay, easing, useEndValue), isModifier);
        list.setDetails('y', destructureGraphicsAttributesMapped(value, rect => Rect.resolve(rect).y, startTime, duration, delay, easing, useEndValue), isModifier);
        list.setDetails('width', destructureGraphicsAttributesMapped(value, rect => Rect.resolve(rect).width, startTime, duration, delay, easing, useEndValue), isModifier);
        list.setDetails('height', destructureGraphicsAttributesMapped(value, rect => Rect.resolve(rect).height, startTime, duration, delay, easing, useEndValue), isModifier);
    }),
    position: aliasValue<PointLike>((list, value, isModifier, startTime, duration, delay, easing, useEndValue) => {
        list.setDetails('x', destructureGraphicsAttributesMapped(value, point => Point.resolve(point).x, startTime, duration, delay, easing, useEndValue), isModifier);
        list.setDetails('y', destructureGraphicsAttributesMapped(value, point => Point.resolve(point).y, startTime, duration, delay, easing, useEndValue), isModifier);
    }),
    anchor: aliasValue<PointLike>((list, value, isModifier, startTime, duration, delay, easing, useEndValue) => {
        list.setDetails('anchorX', destructureGraphicsAttributesMapped(value, point => Point.resolve(point).x, startTime, duration, delay, easing, useEndValue), isModifier);
        list.setDetails('anchorY', destructureGraphicsAttributesMapped(value, point => Point.resolve(point).y, startTime, duration, delay, easing, useEndValue), isModifier);
    }),
} satisfies { [key: string]: GraphicsAttributeMetadata<any, boolean>; };

export function getGraphicsAttributeMetadata<K extends keyof Graphics>(key: K) {
    return GRAPHICS_METADATA[key];
}

export const DISPLAY_GRAPHICS_KEYS: (keyof Graphics)[] = ['color', 'image', 'text'];
export const COPIED_ATTRIBUTES_FROM_PARENT: (keyof Graphics)[] = ['layerId', 'detectable', 'x', 'y', 'width', 'height'];

export const COPIED_ATTRIBUTES_FROM_MAIN_ATOM: Array<keyof Graphics> = Object.keys(GRAPHICS_METADATA)
    .map(key => key as keyof Graphics)
    .filter(key => !DISPLAY_GRAPHICS_KEYS.includes(key) && !GRAPHICS_METADATA[key].alias);

export const ANIMATED_GRAPHICS_ATTRIBUTES: Array<keyof Graphics> = Object.entries(GRAPHICS_METADATA)
    .filter(([key, metadata]) => !!metadata.glsl)
    .map(([key, metadata]) => key as keyof Graphics);

export type AnimatedGraphicsAttributeDetails<K extends keyof Graphics> = GraphicsAttributeDetails<Exclude<Graphics[K], undefined>>;

function setGlslAttributeIndex() {
    let index = 0;

    for (let metadata of Object.values(GRAPHICS_METADATA)) {
        if (metadata.glsl) {
            metadata.glsl.attributeIndex = index;
            index += 1;
        }
    }
}

setGlslAttributeIndex();