import { GraphicsEngine } from '../graphics-engine.ts';
import { LayerId } from '../layer-types.ts';
import { GraphicsAttributeDetails } from './graphics-attribute-details.ts';
import { UnwrapAttribute } from './graphics-attribute-types.ts';
import { Cursor, DrawOrder } from './graphics-types.ts';
import { AnimatedGraphicsAttributeDetails, DISPLAY_GRAPHICS_KEYS, GRAPHICS_METADATA, Graphics } from './graphics.ts';

export type GraphicsAttributeListEntry<T> = {
    body: GraphicsAttributeDetails<T> | null;
    modifier: GraphicsAttributeDetails<T> | null;
}

export class GraphicsAttributeList {
    private graphicsEngine: GraphicsEngine;
    private attributes: Map<keyof Graphics, GraphicsAttributeListEntry<any>> = new Map();
    private attributesDataPointer: number = 0;
    private layerId: LayerId | null = null;
    private imageId: string | null = null;
    private audioId: number | null = null;
    private cursor: Cursor | null = null;
    private detectable: boolean = false;
    private drawOrder: DrawOrder = 'before-children';
    private shouldReload: boolean = false;
    private layerInitialized: boolean = false;

    constructor(graphicsEngine: GraphicsEngine) {
        this.graphicsEngine = graphicsEngine;
    }

    clear() {
        this.attributes.clear();
        this.unload();
        this.imageId = null;
        this.audioId = null;
        this.cursor = null;
        this.shouldReload = true;
    }

    getBodyDetails<K extends keyof Graphics>(key: K): AnimatedGraphicsAttributeDetails<K> | undefined {
        return this.attributes.get(key)?.body ?? undefined;
    }

    getModifierDetails<K extends keyof Graphics>(key: K): AnimatedGraphicsAttributeDetails<K> | undefined {
        return this.attributes.get(key)?.modifier ?? undefined;
    }

    getValue<K extends keyof Graphics>(key: K): UnwrapAttribute<Exclude<Graphics[K], undefined>> | undefined {
        return this.attributes.get(key)?.body?.end ?? undefined;
    }

    requireValue<K extends keyof Graphics>(key: K): UnwrapAttribute<Exclude<Graphics[K], undefined>> {
        return this.attributes.get(key)?.body?.end ?? GRAPHICS_METADATA[key].defaultValue;
    }

    setDetails<K extends keyof Graphics>(key: K, value: AnimatedGraphicsAttributeDetails<K>, isModifier: boolean) {
        let attribute = this.updateAttribute(key);

        if (isModifier) {
            attribute.modifier = value;
        } else {
            attribute.body = value;
        }
    }

    copyAttributeFrom(source: GraphicsAttributeList, key: keyof Graphics) {
        let src = source.attributes.get(key);

        if (src) {
            let attribute = this.updateAttribute(key);

            attribute.body = src.body;
            attribute.modifier = src.modifier;
        }
    }

    hasAttribute(key: keyof Graphics): boolean {
        return this.attributes.has(key);
    }

    getLayerId(): LayerId {
        return this.layerId;
    }

    getLayer(): DrawOrder {
        return this.drawOrder;
    }

    hasDisplayData(): boolean {
        for (let key of DISPLAY_GRAPHICS_KEYS) {
            if (this.attributes.has(key)) {
                return true;
            }
        }

        return false;
    }

    private updateAttribute<K extends keyof Graphics>(key: K): GraphicsAttributeListEntry<any> {
        let attribute = this.attributes.get(key);

        if (!attribute) {
            attribute = { body: null, modifier: null };
            this.attributes.set(key, attribute);
        }

        return attribute;
    }

    private unload() {
        if (!this.layerInitialized) {
            return;
        }

        this.attributesDataPointer = this.graphicsEngine.getAttributesDataTexture(this.getLayerId()).deallocate(this.attributesDataPointer);
    }

    private load() {
        if (this.attributesDataPointer === 0 && this.attributes.size === 0) {
            return;
        }

        if (!this.layerInitialized) {
            this.layerId = this.requireValue('layerId');
            this.layerInitialized = true;
        }

        let graphicsEngine = this.graphicsEngine;
        let layerId = this.layerId;
        let attributesDataTexture = graphicsEngine.getAttributesDataTexture(layerId);

        this.attributesDataPointer = attributesDataTexture.deallocate(this.attributesDataPointer);

        this.detectable = false;
        this.imageId = null;
        this.audioId = graphicsEngine.loadAudio(this);
        this.cursor = this.requireValue('cursor');

        if (!this.hasDisplayData()) {
            return;
        }

        let imageId = this.requireValue('image');
        let text = this.requireValue('text');

        if (text) {
            imageId = graphicsEngine.loadText(this);
        }

        if (imageId && !graphicsEngine.isImageLoaded(imageId)) {
            graphicsEngine.loadImage(imageId, { waitForLoading: false });
        }

        let graphicsLoader = graphicsEngine.getGraphicsLoader();

        this.detectable = this.requireValue('detectable');
        this.drawOrder = this.requireValue('drawOrder');
        this.imageId = imageId;
        this.attributesDataPointer = graphicsLoader.loadGraphics(this, imageId);
    }

    loadIfNecessary() {
        if (this.shouldReload || this.graphicsEngine.hasResourceBeenReloaded(this.imageId)) {
            this.load();
            this.shouldReload = false;
        }
    }

    draw(displayIndex: number) {
        if (this.imageId) {
            this.graphicsEngine.notifyUsedImage(this.imageId);
        }

        if (this.audioId) {
            this.graphicsEngine.playAudio(this.audioId);
        }

        if (this.cursor) {
            this.graphicsEngine.setCursor(this.cursor);
        }

        if (this.attributesDataPointer === 0) {
            return;
        }

        this.graphicsEngine.allocateGraphicsInstance(this.getLayerId())
            .a_attributesDataPointer(this.attributesDataPointer)
            .a_componentId(this.detectable ? displayIndex : 0);
    }

    destroy() {
        this.unload();
    }
}
globalThis.ALL_FUNCTIONS.push(GraphicsAttributeList);