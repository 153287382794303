import { ColorLike } from '../../../utils/color/color.ts';
import { DisplaySizeProperties, DisplaySizeLike } from '../../../utils/geometry/display-size.ts';
import { Easing } from '../../../utils/time/easing.ts';
import { GraphicsEngine } from '../graphics-engine.ts';
import { GraphicsAttributeList } from './graphics-attribute-list.ts';
import { GraphicsAttribute } from './graphics-attribute-types.ts';
import { Progress } from './graphics-types.ts';

export type GraphicsAttributeMetadata<T, U extends boolean> = {
    defaultValue: T,
    animatable: U
    baseAddValue?: T | undefined;
    baseMultValue?: T | undefined;
    glsl: GraphicsAttributeGlslMetadata<T> | null,
    alias?: GraphicsAttributeAlias<T>;
};

export type GraphicsAttributeAlias<T> = (
    list: GraphicsAttributeList,
    value: GraphicsAttribute<T>,
    isModifier: boolean,
    startTime: number,
    defaultDuration: number | undefined,
    defaultDelay: number | undefined,
    defaultEasing: Easing | undefined,
    defaultToEndValue: boolean
) => void;

export type GraphicsAttributeGlslMetadata<T> = {
    encodeFunction: (value: T, graphicsEngine: GraphicsEngine) => number;
    type: GlslType;
    defaultComponentValue: number | string;
    attributeIndex: number;
};

export type GlslTypeMetadata = {
    componentCount: number;  
};

export const GLSL_TYPES = {
    'number': { componentCount: 1 },
    'color': { componentCount: 4 },
    'display-size': { componentCount: 3 },
    'progress': { componentCount: 2 },
} satisfies { [key: string]: GlslTypeMetadata };

export type GlslType = keyof typeof GLSL_TYPES;

export type GraphicsAttributeType<T extends GraphicsAttributeMetadata<any, any>> =
    T extends GraphicsAttributeMetadata<infer U, true> ? GraphicsAttribute<U> : 
    T extends GraphicsAttributeMetadata<infer U, false> ? U :
    never;

export function staticValue<T>(defaultValue: T): GraphicsAttributeMetadata<T, false> {
    return { defaultValue, animatable: false, glsl: null };
}

export function aliasValue<T>(callback: GraphicsAttributeAlias<T>): GraphicsAttributeMetadata<T, true> {
    return {
        defaultValue: undefined as T,
        animatable: true,
        glsl: null,
        alias: callback
    };
}

export function animatableFloat(defaultValue: number): GraphicsAttributeMetadata<number, true> {
    return {
        defaultValue,
        animatable: true,
        baseAddValue: 0,
        baseMultValue: 1,
        glsl: {
            encodeFunction: x => x,
            type: 'number',
            defaultComponentValue: defaultValue,
            attributeIndex: -1,
        }
    };
}

export function animatableOptionalFloat(defaultValue: number | null): GraphicsAttributeMetadata<number | null, true> {
    return {
        defaultValue,
        animatable: true,
        baseAddValue: 0,
        baseMultValue: 1,
        glsl: {
            encodeFunction: x => x === null ? NaN : x,
            type: 'number',
            defaultComponentValue: 'NaN',
            attributeIndex: -1,
        }
    };
}

export function animatableDisplaySize(defaultValue: DisplaySizeLike, defaultPercentKey?: keyof DisplaySizeProperties): GraphicsAttributeMetadata<DisplaySizeLike, true> {
    return {
        defaultValue,
        animatable: true,
        baseAddValue: 0,
        baseMultValue: 1,
        glsl: {
            encodeFunction: (value, graphicsEngine) => graphicsEngine.encodeDisplaySizeToFloat32(value, defaultPercentKey),
            type: 'display-size',
            defaultComponentValue: defaultValue === '100%' ? 1 : 0, // TODO: improve this
            attributeIndex: -1,
        }
    };
}

export function animatableColor(defaultValue: ColorLike = null): GraphicsAttributeMetadata<ColorLike, true> {
    return {
        defaultValue,
        animatable: true,
        glsl: {
            encodeFunction: (value, graphicsEngine) => graphicsEngine.encodeColorToFloat32(value),
            type: 'color',
            defaultComponentValue: defaultValue === 'white' ? 1 : 0,
            attributeIndex: -1,
        }
    };
}

export function animatableProgress(defaultValue: Progress): GraphicsAttributeMetadata<Progress, true> {
    return {
        defaultValue,
        animatable: true,
        glsl: {
            encodeFunction: (value, graphicsEngine) => graphicsEngine.encodeProgressToFloat32(value),
            type: 'progress',
            defaultComponentValue: 0,
            attributeIndex: -1,
        }
    };
}