import { KeysFor } from '../../utils/language/types.ts';
import { RoomApi } from '../room/room-api.ts';
import { View } from '../view/view.ts';
import { Component } from './component.ts';

export type ComponentShape = 'rectangle' | 'circle';
export type ComponentInteractionCallback = (api: RoomApi) => Promise<void>;
export type ComponentHookMethodName = KeysFor<Component, (api: RoomApi) => void>;
export type ComponentHighlightMethodName = Extract<KeysFor<Component, (view: View) => void>, `highlight${string}`>;

export type ResolvableComponent = Component & Pick<Required<Component>, 'getResolvePromise'>;
export type GetComponentResolveType<T extends ResolvableComponent> = ReturnType<T['getResolvePromise']>;

export function isComponent(value: unknown): value is Component {
    return typeof value === 'object' && value !== null && 'render' in value && typeof value.render === 'function';
}