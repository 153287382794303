export function mix(a: number, b: number, t: number): number {
    return a + (b - a) * t;
}

export function clamp(n: number, min: number, max: number): number {
    return Math.max(Math.min(n, max), min);
}

export function clampAngle(angle: number): number {
    let a = angle;

    while (a > Math.PI) {
        a -= Math.PI * 2;
    }

    while (a < -Math.PI) {
        a += Math.PI * 2;
    }

    return a;
}

export function angleDif(start: number, end: number) {
    let s = Math.sign(end - start);

    while (Math.abs(end - start) > Math.PI) {
        start += (Math.PI * 2) * s;
    }

    return end - start;
}

export function getAbsoluteRatio(a: number, b: number): number {
    if (a > b) {
        return a / b;
    } else {
        return b / a;
    }
}

export function getNextPowerOfTwo(value: number) {
    return 2 ** Math.ceil(Math.log2(value + 1));
}