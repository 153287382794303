import { Constructor, ParametersExceptFirst, Properties } from '../../utils/language/types.ts';
import { FlexBuffer } from '../../utils/serialization/flex-buffer.ts';
import { Component } from '../component/component.ts';
import { EntityEventEmitter } from './entity-event-emitter.ts';
import { EntityEvent } from './entity-event.ts';
import { EntityWrapper } from './entity-wrapper.ts';
import { RoomApi } from './room-api.ts';
import { RoomClientListWrapper, SET_WRAPPED_CLIENTS } from './room-client-list-wrapper.ts';
import { AggregatedServerData, CreateRoomParams, RoomInfo, SerializedRoomSnapshot } from './room-manager-types.ts';
import { RoomManager } from './room-manager.ts';
import { GetRoomClient, Room, RoomHookMethodName } from './room.ts';

export class RoomWrapper<R extends Room = any> {
    room: R;
    roomId: string;
    clients: Map<string, GetRoomClient<R>> = new Map();

    private roomManager: RoomManager;
    private defaultRoomApi: RoomApi;

    private entities: Set<Component> = new Set();
    private entityEventEmitter: EntityEventEmitter = new EntityEventEmitter();

    constructor(roomManager: RoomManager, params: CreateRoomParams) {
        this.roomManager = roomManager;
        this.roomId = params.roomId;
        this.room = params.room as R;
        this.defaultRoomApi = new RoomApi(roomManager);

        for (let [clientId, clientData] of params.clients ?? []) {
            this.clients.set(clientId, clientData as GetRoomClient<R>);
        }
    }

    static fromSnapshot(roomManager: RoomManager, snapshot: SerializedRoomSnapshot) {
        let deserializer = roomManager.getDeserializer();
        let createParams = deserializer.deserialize(new FlexBuffer(snapshot));

        return new RoomWrapper(roomManager, createParams);
    }

    matchesConstructor(roomConstructor: Constructor<Room> | undefined): boolean {
        return !roomConstructor || this.room instanceof roomConstructor;
    }

    getSnapshot(): SerializedRoomSnapshot {
        let serializer = this.roomManager.getSerializer();
        let createParams: CreateRoomParams = {
            room: this.room,
            roomId: this.roomId,
            clients: [...this.clients.entries()],
        };

        return serializer.serialize(createParams).sliceUint8Array();
    }

    runHookMethod<K extends RoomHookMethodName>(
        aggregatedServerData: AggregatedServerData | null,
        methodName: K,
        ...args: ParametersExceptFirst<R[K]>
    ): boolean {
        if (!(methodName in this.room)) {
            return false;
        }

        let key = `${this.roomId}_${methodName}`;
        let api = this.defaultRoomApi.reset({
            roomWrapper: this,
            capabilities: 'server',
            serverData: aggregatedServerData?.[key]
        });

        (this.room[methodName] as any)(api, ...args);

        if (aggregatedServerData && !aggregatedServerData[key]) {
            aggregatedServerData[key] = api.getServerData();
        }

        return true;
    }

    initRoom() {
        for (let value of Object.values(this.room)) {
            if (value && value instanceof RoomClientListWrapper) {
                value[SET_WRAPPED_CLIENTS](this.clients);
            }
        }
    }

    getInfo(): RoomInfo {
        return {
            roomId: this.roomId,
            roomConstructor: this.room.constructor as Constructor<R>,
            clientIds: [...this.clients.keys()],
        };
    }

    spawnEntity(entity: Component) {
        this.entities.add(entity);
    }

    despawnEntity(entity: Component) {
        
    }

    addEntityEventListener<T extends Component, E extends EntityEvent>(source: T, callback: (this: T, evt: E, target: T) => void) {

    }

    removeEntityEventListener<T extends Component, E extends EntityEvent>(source: T, callback: (this: T, evt: E, target: T) => void) {

    }

    emitEntityEvent<E extends EntityEvent>(evtConstructor: new () => E, properties: Partial<Properties<E>>) {
        
    }
}
globalThis.ALL_FUNCTIONS.push(RoomWrapper);