#version 300 es

precision highp int;
precision lowp sampler2DArray;

#define PI 3.1415926535897932384626433832795

const float EPSILON = 0.000001f;
const float NaN = 0.f / 0.f;
const float MAX_TEXTURE_SIZE = 4096.0f;
const vec4 TRANSPARENT = vec4(0.f);

const uint ZERO = uint(0);
const uint MASK_1_BIT = uint(0x1);
const uint MASK_2_BITS = uint(0x3);
const uint MASK_3_BITS = uint(0x7);
const uint MASK_4_BITS = uint(0xf);
const uint MASK_5_BITS = uint(0x1f);
const uint MASK_6_BITS = uint(0x3f);
const uint MASK_7_BITS = uint(0x7f);
const uint MASK_8_BITS = uint(0xff);
const uint MASK_9_BIT = uint(0x1ff);
const uint MASK_10_BITS = uint(0x3ff);
const uint MASK_11_BITS = uint(0x7ff);
const uint MASK_12_BITS = uint(0xfff);
const uint MASK_13_BITS = uint(0x1fff);
const uint MASK_14_BITS = uint(0x3fff);
const uint MASK_15_BITS = uint(0x7fff);
const uint MASK_16_BITS = uint(0xffff);
const uint MASK_24_BITS = uint(0xffffff);

const float MAX_UINT_16_VALUE = pow(2.f, 16.f) - 1.f;

// CONSTANTS START
const uint MAX_ATTRIBUTE_BLOCK_COUNT = 64u;
// CONSTANTS END

// LOOP START
const uint LOOP_CLAMP = 0u;
const uint LOOP_REPEAT = 1u;
const uint LOOP_MIRROR = 2u;
// LOOP END

// EASING START
const uint EASING_LINEAR = 0u;
const uint EASING_QUADRATIC_IN = 1u;
const uint EASING_QUADRATIC_OUT = 2u;
const uint EASING_QUADRATIC_IN_OUT = 3u;
const uint EASING_CUBIC_IN = 4u;
const uint EASING_CUBIC_OUT = 5u;
const uint EASING_CUBIC_IN_OUT = 6u;
// EASING END

// OPERATION START
const uint OPERATION_IGNORE = 0u;
const uint OPERATION_SET = 1u;
const uint OPERATION_ADD = 2u;
const uint OPERATION_MULT = 3u;
// OPERATION END

// IMAGE FIT START
const uint FIT_CONTAIN = 0u;
const uint FIT_COVER = 1u;
const uint FIT_FILL = 2u;
const uint FIT_RAW = 3u;
// IMAGE FIT END

// TYPE START
const uint TYPE_NUMBER = 0u;
const uint TYPE_COLOR = 1u;
const uint TYPE_DISPLAY_SIZE = 2u;
const uint TYPE_PROGRESS = 3u;
// TYPE END

// ATTRIBUTE KIND START
const uint ATTRIBUTE_X = 0u;
const uint ATTRIBUTE_Y = 1u;
const uint ATTRIBUTE_WIDTH = 2u;
const uint ATTRIBUTE_HEIGHT = 3u;
const uint ATTRIBUTE_OFFSET_X = 4u;
const uint ATTRIBUTE_OFFSET_Y = 5u;
const uint ATTRIBUTE_ANCHOR_X = 6u;
const uint ATTRIBUTE_ANCHOR_Y = 7u;
const uint ATTRIBUTE_ANGLE = 8u;
const uint ATTRIBUTE_SCALE = 9u;
const uint ATTRIBUTE_BORDER_RADIUS = 10u;
const uint ATTRIBUTE_STROKE_SIZE = 11u;
const uint ATTRIBUTE_FILL_PERCENT_RADIAL = 12u;
const uint ATTRIBUTE_FILL_PERCENT_RADIAL_START_ANGLE = 13u;
const uint ATTRIBUTE_FILL_PERCENT_LINEAR = 14u;
const uint ATTRIBUTE_REPLACE_COLOR_SRC = 15u;
const uint ATTRIBUTE_REPLACE_COLOR_DST = 16u;
const uint ATTRIBUTE_ALPHA = 17u;
const uint ATTRIBUTE_BRIGHTNESS = 18u;
const uint ATTRIBUTE_GRAYSCALE = 19u;
const uint ATTRIBUTE_TINT = 20u;
const uint ATTRIBUTE_STRETCH = 21u;
const uint ATTRIBUTE_STRETCH_MULTIPLIER = 22u;
const uint ATTRIBUTE_COLOR = 23u;
const uint ATTRIBUTE_IMAGE_SPRITE = 24u;
const int ATTRIBUTE_COUNT = 25;
// ATTRIBUTE KIND END

// UNIFORMS START
uniform float u_currentTime;
uniform vec2 u_realCanvasSize;
uniform float u_virtualToRealRatio;
uniform vec2 u_cameraCenter;
uniform float u_cameraZoom;
uniform vec2 u_viewportCenter;
uniform sampler2DArray u_imageTexture;
uniform float u_imageColorReplaceDistanceThreshold;
uniform sampler2D u_attributesDataTexture;
// UNIFORMS END

// INSTANCE ATTRIBUTES START
in int a_attributesDataPointer;
in uint a_componentId;
// INSTANCE ATTRIBUTES END

// VERTEX ATTRIBUTES START
in vec2 a_vertexCoords;
// VERTEX ATTRIBUTES END

// VARYINGS START
out vec4 v_color;
out float v_strokeSize;
out float v_borderRadius;
out float v_borderSharp;
out float v_fillPercentRadial;
out float v_fillPercentRadialDirection;
out float v_fillPercentRadialStartAngle;
out float v_fillPercentLinearProgress;
out float v_fillPercentLinear;
out vec2 v_distanceToTopLeft;
out vec2 v_rectSize;
out vec3 v_imageTextureCoords;
out vec4 v_imageReplaceSourceColor;
out vec4 v_imageReplaceTargetColor;
out float v_alpha;
out float v_brightness;
out float v_grayscale;
out vec4 v_tint;
out vec4 v_componentId;
// VARYINGS END

struct InstanceData {
    uint attributeCount;
    float fillPercentRadialDirection;
    float fillPercentLinearVertical;
    float fillPercentLinearReverse;
    bool hasImage;
    uint imageFit;
    float imageHorizontalAlign;
    float imageVerticalAlign;
    float imageSpriteSheetRowSize;
    float imageSpriteSheetColumnSize;
    vec2 imageTextureCoords;
    vec2 imageTextureSize;

    // INSTANCE DATA START
    float x;
    float y;
    float width;
    float height;
    vec3 offsetX;
    vec3 offsetY;
    float anchorX;
    float anchorY;
    float angle;
    float scale;
    vec3 borderRadius;
    vec3 strokeSize;
    float fillPercentRadial;
    float fillPercentRadialStartAngle;
    float fillPercentLinear;
    vec4 replaceColorSrc;
    vec4 replaceColorDst;
    float alpha;
    float brightness;
    float grayscale;
    vec4 tint;
    float stretch;
    float stretchMultiplier;
    vec4 color;
    vec2 imageSprite;
    // INSTANCE DATA END
};

struct AttributeData {
    float start;
    float end;
    float duration;
    float delayBetweenLoops;
    float startTime;
    uint loop;
    uint easing;
    uint operation;
    uint type;
    bool reverse;
};

vec2 rotate(vec2 point, float angle, vec2 center) {
    vec2 vector = point - center;
    float cos = cos(angle);
    float sin = sin(angle);
    float x = cos * vector.x - sin * vector.y;
    float y = sin * vector.x + cos * vector.y;

    return vec2(x, y) + center;
}

vec2 scale(vec2 point, float scale, vec2 center) {
    return point + (point - center) * (scale - 1.f);
}

float bitToSign(uint bit) {
    return float(bit) * 2. - 1.;
}

vec4 unpackNumber(float value) {
    return vec4(value, 0.f, 0.f, 0.f);
}

vec4 unpackColor(float value) {
    uint bits = floatBitsToUint(value);
    float r = float((bits >> 24) & MASK_8_BITS) / 255.f;
    float g = float((bits >> 16) & MASK_8_BITS) / 255.f;
    float b = float((bits >> 8) & MASK_8_BITS) / 255.f;
    float a = float((bits >> 0) & MASK_8_BITS) / 255.f;

    return vec4(r, g, b, a);
}

vec4 unpackDisplaySize(float value) {
    uint bits = floatBitsToUint(value);
    float fixedSign = bitToSign((bits >> 31) & MASK_1_BIT);
    float scaledFromWidthSign = bitToSign((bits >> 30) & MASK_1_BIT);
    float scaledFromHeightSign = bitToSign((bits >> 29) & MASK_1_BIT);
    float fixedSize = float((bits >> 16) & MASK_13_BITS) * fixedSign;
    float scaledFromWidthSize = float((bits >> 8) & MASK_8_BITS) / 200.f * scaledFromWidthSign;
    float scaledFromHeightSize = float(bits & MASK_8_BITS) / 200.f * scaledFromHeightSign;

    return vec4(fixedSize, scaledFromWidthSize, scaledFromHeightSize, 0.f);
}

vec4 unpackProgress(float value) {
    uint bits = floatBitsToUint(value);
    float fixedProgress = float((bits >> 16) & MASK_16_BITS);
    float scaledProgress = float(bits & MASK_16_BITS) / MAX_UINT_16_VALUE;

    return vec4(fixedProgress, scaledProgress, 0.f, 0.f);
}

vec2 unpackUintVec2(float value) {
    uint bits = floatBitsToUint(value);
    uint xBits = (bits >> 16) & MASK_16_BITS;
    uint yBits = bits & MASK_16_BITS;
    float x = float(xBits);
    float y = float(yBits);

    return vec2(x, y);
}

float applyDisplaySize(vec3 displaySize, float width, float height) {
    return displaySize.x + displaySize.y * width + displaySize.z * height;
}

float applyProgress(vec2 progress, float maxValue) {
    return clamp(progress.x + progress.y * maxValue, 0.f, maxValue);
}

float getT(AttributeData attributeData, float currentTime) {
    if (attributeData.startTime > currentTime) {
        return 0.f;
    }

    if (attributeData.duration < EPSILON) {
        return 1.f;
    }

    float t = 1.f;
    uint loop = attributeData.loop;
    uint easing = attributeData.easing;
    float elapsedDuration = currentTime - attributeData.startTime;
    float animationDuration = attributeData.duration;
    float totalLoopDuration = attributeData.duration + attributeData.delayBetweenLoops;

    if (loop == LOOP_CLAMP) {
        t = clamp(elapsedDuration / attributeData.duration, 0.f, 1.f);
    } else if (loop == LOOP_REPEAT || loop == LOOP_MIRROR) {
        float elapsedInLoop = mod(elapsedDuration, totalLoopDuration);

        t = min(elapsedInLoop / animationDuration, 1.f);

        if (loop == LOOP_MIRROR && mod(elapsedDuration / totalLoopDuration, 2.f) > 1.f) {
            t = 1.f - t;
        }
    }

    float u = 1.f - t;

    if (easing == EASING_LINEAR) {
        t = t;
    } else if (easing == EASING_QUADRATIC_IN) {
        t = t * t;
    } else if (easing == EASING_QUADRATIC_OUT) {
        t = 1.f - u * u;
    } else if (easing == EASING_QUADRATIC_IN_OUT) {
        t = t < 0.5f ? t * t : 1.f - u * u;
    } else if (easing == EASING_CUBIC_IN) {
        t = t * t * t;
    } else if (easing == EASING_CUBIC_OUT) {
        t = 1.f - u * u * u;
    } else if (easing == EASING_CUBIC_IN_OUT) {
        t = t < 0.5f ? t * t * t : 1.f - u * u * u;
    }

    return t;
}

vec4 applyModifier(vec4 baseValue, vec4 newValue, uint operation) {
    switch (operation) {
        case OPERATION_SET:
            return newValue;
        case OPERATION_ADD:
            return baseValue + newValue;
        case OPERATION_MULT:
            return baseValue * newValue.x;
        default:
            return baseValue;
    }
}

vec4 decodeAttributeData(vec4 currentValue, AttributeData attributeData, float currentTime) {
    bool overrideStart = isnan(attributeData.start);
    vec4 start = vec4(0.f);
    vec4 end = vec4(0.f);

    switch (attributeData.type) {
        case TYPE_NUMBER:
            start = overrideStart ? currentValue : unpackNumber(attributeData.start);
            end = unpackNumber(attributeData.end);
            break;
        case TYPE_COLOR:
            start = overrideStart ? currentValue : unpackColor(attributeData.start);
            end = unpackColor(attributeData.end);
            break;
        case TYPE_DISPLAY_SIZE:
            start = overrideStart ? currentValue : unpackDisplaySize(attributeData.start);
            end = unpackDisplaySize(attributeData.end);
            break;
        case TYPE_PROGRESS:
            start = overrideStart ? currentValue : unpackProgress(attributeData.start);
            end = unpackProgress(attributeData.end);
            break;
    }

    float t = getT(attributeData, currentTime);
    vec4 value = mix(start, end, t);

    return applyModifier(currentValue, value, attributeData.operation);
}

float readFloatFromTexture(int index, sampler2D texture, ivec2 textureSize) {
    int x = index % textureSize.x;
    int y = index / textureSize.x;

    return texelFetch(texture, ivec2(x, y), 0).r;
}

uint readUintFromTexture(int index, sampler2D texture, ivec2 textureSize) {
    return floatBitsToUint(readFloatFromTexture(index, texture, textureSize));
}

int readInstanceHeader(int ptr, inout InstanceData instance, sampler2D attributesDataTexture, ivec2 attributesDataTextureSize) {
    uint bits = readUintFromTexture(ptr++, attributesDataTexture, attributesDataTextureSize);

    instance.attributeCount = (bits >> 24) & MASK_8_BITS;
    instance.imageSpriteSheetRowSize = float((bits >> 17) & MASK_7_BITS);
    instance.imageSpriteSheetColumnSize = float((bits >> 10) & MASK_7_BITS);
    instance.imageHorizontalAlign = float((bits >> 8) & MASK_2_BITS) / 2.f;
    instance.imageVerticalAlign = float((bits >> 6) & MASK_2_BITS) / 2.f;
    instance.imageFit = (bits >> 4) & MASK_2_BITS;
    instance.fillPercentLinearVertical = float((bits >> 3) & MASK_1_BIT);
    instance.fillPercentLinearReverse = float((bits >> 2) & MASK_1_BIT);
    instance.fillPercentRadialDirection = (float((bits >> 1) & MASK_1_BIT) * 2. - 1.) * -1.;
    instance.hasImage = (bits & MASK_1_BIT) == MASK_1_BIT;
    instance.imageTextureCoords = vec2(-1.f);
    instance.imageTextureSize = vec2(-1.f);

    if (instance.hasImage) {
        instance.imageTextureCoords = unpackUintVec2(readFloatFromTexture(ptr++, attributesDataTexture, attributesDataTextureSize));
        instance.imageTextureSize = unpackUintVec2(readFloatFromTexture(ptr++, attributesDataTexture, attributesDataTextureSize));
    }

    return ptr;
}

int readAttributeData(int ptr, inout AttributeData attrib, uint type, uint operation, bool isAnimated, sampler2D attributeDataTexture, ivec2 attributeDataTextureSize) {
    attrib.start = readFloatFromTexture(ptr++, attributeDataTexture, attributeDataTextureSize);

    if (!isAnimated) {
        attrib.end = attrib.start;
        attrib.startTime = 0.f;
        attrib.duration = 0.f;
        attrib.delayBetweenLoops = 0.f;
        attrib.loop = LOOP_CLAMP;
        attrib.easing = EASING_LINEAR;
        attrib.reverse = false;
    } else {
        attrib.end = readFloatFromTexture(ptr++, attributeDataTexture, attributeDataTextureSize);

        uint durationAndDelayBetweenLoopsBits = readUintFromTexture(ptr++, attributeDataTexture, attributeDataTextureSize);
        uint startTimeAndFlagsBits = readUintFromTexture(ptr++, attributeDataTexture, attributeDataTextureSize);

        attrib.duration = float((durationAndDelayBetweenLoopsBits >> 16) & MASK_16_BITS);
        attrib.delayBetweenLoops = float(durationAndDelayBetweenLoopsBits & MASK_16_BITS);
        attrib.startTime = float((startTimeAndFlagsBits >> 8) & MASK_24_BITS);
        attrib.easing = (startTimeAndFlagsBits >> 4) & MASK_4_BITS;
        attrib.loop = (startTimeAndFlagsBits >> 1) & MASK_2_BITS;
        attrib.reverse = (startTimeAndFlagsBits & MASK_1_BIT) == MASK_1_BIT;

        if (attrib.reverse) {
            float tmp = attrib.start;
            attrib.start = attrib.end;
            attrib.end = tmp;
        }
    }

    attrib.type = type;
    attrib.operation = operation;

    return ptr;
}

InstanceData readInstanceData(int dataPointer, sampler2D dataTexture, float currentTime) {
    InstanceData instance;
    ivec2 attributeDataTextureSize = textureSize(dataTexture, 0);
    int ptr = dataPointer;

    ptr = readInstanceHeader(ptr, instance, dataTexture, attributeDataTextureSize);

    uint attributeCount = instance.attributeCount;
    uint attributeKinds[MAX_ATTRIBUTE_BLOCK_COUNT];
    // DEFAULT VALUES START
    vec4 values[ATTRIBUTE_COUNT] = vec4[](vec4(0.), vec4(0.), vec4(0.), vec4(0.), vec4(0.), vec4(0.), vec4(NaN), vec4(NaN), vec4(0.), vec4(1.), vec4(0.), vec4(1.), vec4(1.), vec4(0.), vec4(1.), vec4(0.), vec4(0.), vec4(1.), vec4(1.), vec4(0.), vec4(1.), vec4(0.), vec4(1.), vec4(0.), vec4(0.));
    // DEFAULT VALUES END

    for (uint i = 0u; i < attributeCount; i += 2u) {
        uint metadata = readUintFromTexture(ptr++, dataTexture, attributeDataTextureSize);

        attributeKinds[i] = (metadata >> 16) & MASK_16_BITS;
        attributeKinds[i + 1u] = metadata & MASK_16_BITS;
    }

    for (uint i = 0u; i < attributeCount; ++i) {
        uint value = attributeKinds[i];
        uint kind = (value >> 8) & MASK_8_BITS;
        bool isAnimated = ((value >> 6) & MASK_1_BIT) == MASK_1_BIT;
        uint operation = (value >> 3) & MASK_3_BITS;
        uint type = value & MASK_3_BITS;
        AttributeData attrib;

        ptr = readAttributeData(ptr, attrib, type, operation, isAnimated, dataTexture, attributeDataTextureSize);

        values[kind] = decodeAttributeData(values[kind], attrib, currentTime);
    }

    // ASSIGN ATTRIBUTES START
    instance.x = values[ATTRIBUTE_X].x;
    instance.y = values[ATTRIBUTE_Y].x;
    instance.width = values[ATTRIBUTE_WIDTH].x;
    instance.height = values[ATTRIBUTE_HEIGHT].x;
    instance.offsetX = values[ATTRIBUTE_OFFSET_X].xyz;
    instance.offsetY = values[ATTRIBUTE_OFFSET_Y].xyz;
    instance.anchorX = values[ATTRIBUTE_ANCHOR_X].x;
    instance.anchorY = values[ATTRIBUTE_ANCHOR_Y].x;
    instance.angle = values[ATTRIBUTE_ANGLE].x;
    instance.scale = values[ATTRIBUTE_SCALE].x;
    instance.borderRadius = values[ATTRIBUTE_BORDER_RADIUS].xyz;
    instance.strokeSize = values[ATTRIBUTE_STROKE_SIZE].xyz;
    instance.fillPercentRadial = values[ATTRIBUTE_FILL_PERCENT_RADIAL].x;
    instance.fillPercentRadialStartAngle = values[ATTRIBUTE_FILL_PERCENT_RADIAL_START_ANGLE].x;
    instance.fillPercentLinear = values[ATTRIBUTE_FILL_PERCENT_LINEAR].x;
    instance.replaceColorSrc = values[ATTRIBUTE_REPLACE_COLOR_SRC].rgba;
    instance.replaceColorDst = values[ATTRIBUTE_REPLACE_COLOR_DST].rgba;
    instance.alpha = values[ATTRIBUTE_ALPHA].x;
    instance.brightness = values[ATTRIBUTE_BRIGHTNESS].x;
    instance.grayscale = values[ATTRIBUTE_GRAYSCALE].x;
    instance.tint = values[ATTRIBUTE_TINT].rgba;
    instance.stretch = values[ATTRIBUTE_STRETCH].x;
    instance.stretchMultiplier = values[ATTRIBUTE_STRETCH_MULTIPLIER].x;
    instance.color = values[ATTRIBUTE_COLOR].rgba;
    instance.imageSprite = values[ATTRIBUTE_IMAGE_SPRITE].xy;
    // ASSIGN ATTRIBUTES END

    return instance;
}

float computeSizeOnCanvas(float size) {
    return size * u_cameraZoom * u_virtualToRealRatio;
}

vec2 computeSizeOnCanvas(float width, float height) {
    return vec2(width, height) * u_cameraZoom * u_virtualToRealRatio;
}

vec2 computePositionOnCanvas(float x, float y) {
    return ((vec2(x, y) - u_cameraCenter) * u_cameraZoom + u_viewportCenter) * u_virtualToRealRatio;
}

vec4 computeComponentIdColor(uint componentId) {
    if (componentId == ZERO) {
        return vec4(0.f);
    } else {
        return vec4(float((componentId >> 16) & MASK_8_BITS) / 255.f, float((componentId >> 8) & MASK_8_BITS) / 255.f, float((componentId >> 0) & MASK_8_BITS) / 255.f, 1.f);
    }
}

vec2 computeStretchScale(float stretch, float multiplier) {
    float aspectRatio = sqrt(pow(2., stretch * multiplier));
    
    return vec2(aspectRatio, 1. / aspectRatio);
}

void main() {
    if (a_attributesDataPointer == 0) {
        gl_Position = vec4(10.f, 10.f, 0.f, 1.f);
        return;
    }

    InstanceData instance = readInstanceData(a_attributesDataPointer, u_attributesDataTexture, u_currentTime);

    float instanceAnchorX = isnan(instance.anchorX) ? instance.x : instance.anchorX;
    float instanceAnchorY = isnan(instance.anchorY) ? instance.y : instance.anchorY;

    instance.x += applyDisplaySize(instance.offsetX, instance.width, instance.height);
    instance.y += applyDisplaySize(instance.offsetY, instance.width, instance.height);

    vec2 stretchScale = computeStretchScale(instance.stretch, instance.stretchMultiplier);
    vec2 instanceSizeOnCanvas = computeSizeOnCanvas(instance.width, instance.height);
    vec2 instanceCenterOnCanvas = computePositionOnCanvas(instance.x, instance.y);
    vec2 instanceAnchorOnCanvas = computePositionOnCanvas(instanceAnchorX, instanceAnchorY);
    float instanceBorderRadius = applyDisplaySize(instance.borderRadius, instance.width, instance.height) * instance.scale;
    float instanceStrokeSize = applyDisplaySize(instance.strokeSize, instance.width, instance.height) * instance.scale;
    float instanceBorderRadiusOnCanvas = computeSizeOnCanvas(instanceBorderRadius);
    float instanceStrokeSizeOnCanvas = computeSizeOnCanvas(instanceStrokeSize);

    vec2 m = (a_vertexCoords + 1.f) / 2.f;
    vec2 p = vec2(0., 0.);

    vec3 imageTextureCoords = vec3(-1.f);

    if (instance.hasImage) {
        float spriteCountPerRow = instance.imageSpriteSheetRowSize;
        float spriteCountPerColumn = instance.imageSpriteSheetColumnSize;
        float spriteCount = spriteCountPerRow * spriteCountPerColumn;
        float spriteIndex = floor(applyProgress(instance.imageSprite, spriteCount - EPSILON));

        float xOffset = instance.imageTextureCoords.x;
        float yOffset = mod(instance.imageTextureCoords.y, MAX_TEXTURE_SIZE);
        float zOffset = floor(instance.imageTextureCoords.y / MAX_TEXTURE_SIZE);
        vec2 imageSize = instance.imageTextureSize;
        vec2 imageTopLeft = vec2(xOffset, yOffset);
        vec2 spriteSheetSize = vec2(spriteCountPerRow, spriteCountPerColumn);
        vec2 spriteSize = imageSize / spriteSheetSize;
        float spriteX = mod(spriteIndex, spriteCountPerRow);
        float spriteY = floor(spriteIndex / spriteCountPerRow);
        vec2 spriteTopLeft = imageTopLeft + vec2(spriteX, spriteY) * spriteSize;
        vec2 spriteCenter = spriteTopLeft + spriteSize / 2.f;
        vec3 textureSize = vec3(textureSize(u_imageTexture, 0));

        float imageAspectRatio = spriteSize.x / spriteSize.y;
        float instanceAspectRatio = instance.width / instance.height;

        vec2 newInstanceSizeOnCanvas = instanceSizeOnCanvas;
        float horizontalAlign = instance.imageHorizontalAlign - 0.5f;
        float verticalAlign = instance.imageVerticalAlign - 0.5f;

        // If there are multiple sprites on the same sheet, ensure we don't over overlap on neighbouring sprites
        spriteSize -= sign(spriteSheetSize - 1.);

        if (instance.imageFit == FIT_CONTAIN) {
            if (instanceAspectRatio > imageAspectRatio) {
                newInstanceSizeOnCanvas.x = instanceSizeOnCanvas.y * imageAspectRatio;
            } else {
                newInstanceSizeOnCanvas.y = instanceSizeOnCanvas.x / imageAspectRatio;
            }
        } else if (instance.imageFit == FIT_RAW) {
            p = (1. - spriteSize / instanceSizeOnCanvas) / 2.;

            newInstanceSizeOnCanvas = spriteSize;
            instance.scale = 1.;
            stretchScale = vec2(1., 1.);
        } else if (instance.imageFit == FIT_COVER) {
            vec2 newSpriteSize = spriteSize;

            if (imageAspectRatio > instanceAspectRatio) {
                newSpriteSize.x = spriteSize.y * instanceAspectRatio;
            } else {
                newSpriteSize.y = spriteSize.x / instanceAspectRatio;
            }

            spriteCenter.x += horizontalAlign * (spriteSize.x - newSpriteSize.x);
            spriteCenter.y += verticalAlign * (spriteSize.y - newSpriteSize.y);
            spriteSize = newSpriteSize;
        }

        instanceCenterOnCanvas.x += horizontalAlign * (instanceSizeOnCanvas.x - newInstanceSizeOnCanvas.x);
        instanceCenterOnCanvas.y += verticalAlign * (instanceSizeOnCanvas.y - newInstanceSizeOnCanvas.y);
        instanceSizeOnCanvas = newInstanceSizeOnCanvas;

        vec2 imageTextureXYCoords = (spriteCenter + spriteSize * a_vertexCoords * 0.4999f) / (textureSize.xy);
        float imageTextureZCoord = zOffset;

        imageTextureCoords = vec3(imageTextureXYCoords, imageTextureZCoord);
    }

    float minLinearProgress = mix(p.x, p.y, instance.fillPercentLinearVertical);
    float startLinearProgress = mix(minLinearProgress, 1. - minLinearProgress, instance.fillPercentLinearReverse);
    float vertexLinearProgressMultiplier = mix(m.x, m.y, instance.fillPercentLinearVertical);
    float vertexLinearProgress = mix(startLinearProgress, 1. - startLinearProgress, vertexLinearProgressMultiplier);

    instanceSizeOnCanvas *= stretchScale;

    vec2 topLeftOnCanvas = instanceCenterOnCanvas - instanceSizeOnCanvas / 2.f;
    vec2 bottomRightOnCanvas = instanceCenterOnCanvas + instanceSizeOnCanvas / 2.f;

    if (abs(instance.angle) < EPSILON) {
        bottomRightOnCanvas = round(topLeftOnCanvas + instanceSizeOnCanvas);
        topLeftOnCanvas = round(topLeftOnCanvas);
        // bottomRightOnCanvas = topLeftOnCanvas + round(instanceSizeOnCanvas);
    }

    vec2 vertexPositionOnCanvas = mix(topLeftOnCanvas, bottomRightOnCanvas, m);
    vec2 distanceToTopLeft = (vertexPositionOnCanvas - topLeftOnCanvas) * instance.scale;
    vec2 rectSize = (bottomRightOnCanvas - topLeftOnCanvas) * instance.scale;

    vertexPositionOnCanvas = scale(vertexPositionOnCanvas, instance.scale, instanceAnchorOnCanvas);
    vertexPositionOnCanvas = rotate(vertexPositionOnCanvas, instance.angle, instanceAnchorOnCanvas);

    vec2 vertexPositionOnClipSpace = ((vertexPositionOnCanvas / u_realCanvasSize) * 2.f - 1.f) * vec2(1.f, -1.f);

    gl_Position = vec4(vertexPositionOnClipSpace, 0.f, 1.f);

    v_color = instance.color;
    v_strokeSize = round(instanceStrokeSizeOnCanvas);
    v_borderRadius = instanceBorderRadiusOnCanvas;
    v_borderSharp = (abs(instance.angle) < EPSILON) ? 1.f : 0.f;
    v_distanceToTopLeft = distanceToTopLeft;
    v_rectSize = rectSize;
    v_imageTextureCoords = imageTextureCoords;
    v_imageReplaceSourceColor = instance.replaceColorSrc;
    v_imageReplaceTargetColor = instance.replaceColorDst;
    v_alpha = instance.alpha;
    v_brightness = instance.brightness;
    v_grayscale = instance.grayscale;
    v_tint = instance.tint;
    v_fillPercentRadial = instance.fillPercentRadial;
    v_fillPercentRadialStartAngle = instance.fillPercentRadialStartAngle;
    v_fillPercentRadialDirection = instance.fillPercentRadialDirection;
    v_fillPercentLinearProgress = vertexLinearProgress;
    v_fillPercentLinear = instance.fillPercentLinear;
    v_componentId = computeComponentIdColor(a_componentId);
}