import { Component } from '../component/component.ts';
import { View } from '../view/view.ts';

export class TooltipComponent implements Component {
    target: Component;
    constructor(target: Component) {
        this.target = target;
    }

    render(view: View): void {
        if (this.target?.tooltip) {
            view.paint({
                rect: view.getComponentView(this.target)?.getRect()
            });

            this.target.tooltip(view);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(TooltipComponent);