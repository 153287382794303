import seedrandom from 'seedrandom';
import { shuffleArray } from '../language/array.ts';

export class Rng {
    private seed: string;
    private rng: seedrandom.PRNG;
    private uint32Array = new Uint32Array(1);

    static instance: Rng = new Rng(Math.random().toString());

    constructor(seed: string = Math.random().toString()) {
        this.seed = seed;
        this.rng = seedrandom(seed);
    }

    getSeed(): any {
        return this.seed;
    }

    int(min: number, max: number): number {
        let dif = (max - min + 1);

        return Math.abs(this.rng.int32()) % dif + min;
    }

    float(min: number, max: number): number {
        let dif = (max - min) * 1000000;

        return (this.rng.int32() % dif) / 1000000 + min;
    }

    intCentered(center: number, rangeSize: number): number {
        return this.int(center - rangeSize / 2, center + rangeSize / 2);
    }

    floatCentered(center: number, rangeSize: number): number {
        return this.float(center - rangeSize / 2, center + rangeSize / 2);
    }

    private uint32(): number {
        this.uint32Array[0] = this.rng.int32();

        return this.uint32Array[0];
    }

    // TODO: return a uuid v4
    id(): string {
        return [
            this.uint32(),
            this.uint32(),
            this.uint32(),
            this.uint32(),
        ].map(n => n.toString(16).padStart(8, '0')).join('-');
    }

    prefixedId<T extends string>(prefix: T): `${T}${string}` {
        return (prefix + this.id()) as `${T}${string}`;
    }

    shuffle<T>(array: T[]) {
        shuffleArray(array, (min, max) => this.int(min, max));
    }
}
globalThis.ALL_FUNCTIONS.push(Rng);