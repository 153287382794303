import { DisplaySizeLike, DisplaySize } from '../geometry/display-size.ts';
import { GridLayoutParams } from '../geometry/rect-types.ts';
import { Rect } from '../geometry/rect.ts';
import { clamp } from '../language/math.ts';
import { LayoutDirection } from './layout-direction.ts';

export type ObjectToLayout<T, P> = {
    object: T | null,
    rect: Rect | null,
    properties: P | null;
};

export type LayoutKind = 'stack' | 'line' | 'grid';

export type GridSize = { min: number; max: number; };

export class LayoutItem<T, P> {
    parent: LayoutItem<T, P> | null = null;
    object: T | null = null;
    properties: P | null;
    rect: Rect | null = null;
    width: DisplaySizeLike | null = null;
    height: DisplaySizeLike | null = null;
    aspectRatio: number | null = null;
    force: number = 1;
    scale: number = 1;
    innerMargin: DisplaySizeLike = 0;
    outerMargin: DisplaySizeLike = 0;
    direction: LayoutDirection = 'left-to-right';
    horizontalAlign: number = 0.5;
    verticalAlign: number = 0.5;
    ignoreEmptyCells: boolean = false;
    childWidth: DisplaySizeLike | null = null;
    childHeight: DisplaySizeLike | null = null;
    childAspectRatio: number | null = null;
    childForce: number = 1;
    kind: LayoutKind = 'stack';
    rowSize: GridSize = { min: 1, max: Infinity };
    columnSize: GridSize = { min: 1, max: Infinity };
    scroll: number = 0;
    children: LayoutItem<T, P>[] = [];

    private _computedWidth: null | number = null;
    private _computedHeight: null | number = null;

    constructor(parent: LayoutItem<T, P> | null = null, object: T | null = null, properties: P | null = null) {
        this.parent = parent;
        this.object = object;
        this.properties = properties;
    }

    setRect(rect: Rect | null, rootRect: Rect) {
        this.rect = rect?.clone() ?? null;

        if (!this.children.length || !this.rect) {
            return;
        }

        switch (this.kind) {
            case 'stack':
                this.setRectAsStack(this.rect, rootRect);
                break;
            case 'line':
                this.setRectAsLine(this.rect, rootRect);
                break;
            case 'grid':
                this.setRectAsGrid(this.rect, rootRect);
                break;
        }
    }

    collect(accumulator: ObjectToLayout<T, P>[]) {
        accumulator.push({
            object: this.object,
            rect: this.rect,
            properties: this.properties
        });

        for (let child of this.children) {
            child.collect(accumulator);
        }
    }

    private setRectAsStack(rect: Rect, rootRect: Rect) {
        let outerMargin = DisplaySize.resolve(this.outerMargin, rootRect);
        let childRect = rect.strip(outerMargin);

        for (let child of this.children) {
            child.setRect(childRect, rootRect);
        }
    }

    private setRectAsLine(rect: Rect, rootRect: Rect) {
        let outerMargin = DisplaySize.resolve(this.outerMargin, rootRect);
        let innerMargin = DisplaySize.resolve(this.innerMargin, rootRect);

        let parentRect = rect.strip(outerMargin);
        let isHorizontal = this.direction === 'left-to-right' || this.direction === 'right-to-left';
        let isReverse = this.direction === 'right-to-left' || this.direction === 'bottom-to-top';
        let flexSize = isHorizontal ? parentRect.width : parentRect.height;
        let availableSize = flexSize - (this.children.length - 1) * innerMargin;
        let consumedSize = 0;
        let totalForce = 0;

        for (let child of this.children) {
            let childWidth: number | null = child.width !== null ? DisplaySize.resolve(child.width, rootRect, 'scaledFromParentWidth') * child.scale : null;
            let childHeight: number | null = child.height !== null ? DisplaySize.resolve(child.height, rootRect, 'scaledFromParentHeight') * child.scale : null;

            if (isHorizontal && childHeight === null && !child.aspectRatio) {
                childHeight = parentRect.height;
            } else if (!isHorizontal && childWidth === null && !child.aspectRatio) {
                childWidth = parentRect.width;
            }

            if (child.aspectRatio) {
                if (childHeight === null && childWidth !== null) {
                    childHeight = childWidth / child.aspectRatio;
                } else if (childWidth === null && childHeight !== null) {
                    childWidth = childHeight * child.aspectRatio;
                }
            }

            if (isHorizontal && childWidth !== null) {
                consumedSize += childWidth;
            } else if (!isHorizontal && childHeight !== null) {
                consumedSize += childHeight;
            } else {
                totalForce += child.force;
            }

            child._computedWidth = childWidth;
            child._computedHeight = childHeight;
        }

        let availableSizeForForce = availableSize - consumedSize;
        let x = parentRect.x1;
        let y = parentRect.y1;
        let progressMultiplier = 1;
        let reverseMultiplier = 0;
        let horizontalAlign = this.horizontalAlign;
        let verticalAlign = this.verticalAlign;

        if (isReverse) {
            progressMultiplier = -1;
            reverseMultiplier = 1;

            if (isHorizontal) {
                x = parentRect.x2;
            } else {
                y = parentRect.y2;
            }
        }

        if (totalForce == 0) {
            if (isHorizontal) {
                x += availableSizeForForce * horizontalAlign * progressMultiplier;
            } else {
                y += availableSizeForForce * verticalAlign * progressMultiplier;
            }
        }

        for (let child of this.children) {
            if (isHorizontal && child._computedWidth === null) {
                child._computedWidth = availableSizeForForce * child.force / totalForce;

                if (child._computedHeight === null) {
                    if (child.aspectRatio) {
                        child._computedHeight = child._computedWidth / child.aspectRatio;

                        if (child._computedHeight > parentRect.height) {
                            child._computedHeight = parentRect.height;
                            child._computedWidth = child._computedHeight * child.aspectRatio;
                        }
                    } else {
                        child._computedHeight = parentRect.height;
                    }
                }

            } else if (!isHorizontal && child._computedHeight === null) {
                child._computedHeight = availableSizeForForce * child.force / totalForce;

                if (child._computedWidth === null) {
                    if (child.aspectRatio) {
                        child._computedWidth = child._computedHeight * child.aspectRatio;

                        if (child._computedWidth > parentRect.width) {
                            child._computedWidth = parentRect.width;
                            child._computedHeight = child._computedWidth / child.aspectRatio;
                        }
                    } else {
                        child._computedWidth = parentRect.width;
                    }
                }
            }

            let childWidth = child._computedWidth ?? 0;
            let childHeight = child._computedHeight ?? 0;
            let childX = x;
            let childY = y;

            if (isHorizontal) {
                childX -= (childWidth * reverseMultiplier);
                childY += (parentRect.height - childHeight) * verticalAlign;
                x += (childWidth + innerMargin) * progressMultiplier;
            } else {
                childY -= (childHeight * reverseMultiplier);
                childX += (parentRect.width - childWidth) * horizontalAlign;
                y += (childHeight + innerMargin) * progressMultiplier;
            }

            let childRect = Rect.fromTopLeft(childX, childY, childWidth, childHeight);

            child.setRect(childRect, rootRect);
        }
    }

    private setRectAsGrid(rect: Rect, rootRect: Rect) {
        let appendHorizontalThenVertical = this.direction === 'left-to-right' || this.direction === 'right-to-left';

        let gridLayout: Partial<GridLayoutParams> = {
            itemCount: this.children.length,
            horizontalAlign: this.horizontalAlign,
            verticalAlign: this.verticalAlign,
            ignoreEmptyCells: this.ignoreEmptyCells,
            itemAspectRatio: this.childAspectRatio ?? 1,
            innerMargin: DisplaySize.resolve(this.innerMargin, rootRect),
            outerMargin: DisplaySize.resolve(this.outerMargin, rootRect),
            minRowSize: this.rowSize.min,
            maxRowSize: this.rowSize.max,
            minColumnSize: this.columnSize.min,
            maxColumnSize: this.columnSize.max,
            appendHorizontalThenVertical
        };

        let { cells, itemCountPerRow: rowSize, itemCountPerColumn: columnSize } = rect.computeGridLayout(gridLayout);
        let stepSize = appendHorizontalThenVertical ? rowSize : columnSize;
        let maxScroll = Math.max(0, Math.ceil((this.children.length - cells.length) / stepSize));
        let startIndex = Math.ceil(clamp(this.scroll, 0, maxScroll)) * stepSize;

        for (let i = 0; i < startIndex; ++i) {
            this.children[i].setRect(null, rootRect);
        }

        for (let i = startIndex; i < this.children.length; ++i) {
            let child = this.children[i];
            let childRect = cells[i - startIndex] ?? null;

            child.setRect(childRect, rootRect);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(LayoutItem);