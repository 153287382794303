import { ColorLike, Component, Line, Point, RoomClient, Vector, View } from 'outpost';
import { BOUNCE_SPEED_RATIO, MIN_BOUNCE_SPEED, PLAYER_HEIGHT, PLAYER_IMG, PLAYER_STARTING_POSITION, PLAYER_WIDTH } from './constants.ts';
import { MainRoom } from './main-room.ts';
import { getBounceAngle } from './physics.ts';
import { EngineStrength } from './physics/engine-strength.ts';
import { Strength } from './physics/strength.ts';
import { BounceStrength } from './physics/bounce-strength.ts';

export class Player implements Component, RoomClient {
    game!: MainRoom;
    id: string;
    name: string;
    position: Point = PLAYER_STARTING_POSITION;
    velocity: Vector = Vector.zero();
    bounceTimer: number = 0;
    engineStrength = new EngineStrength();
    bounceStrength = new BounceStrength();
    strengths: Strength[] = [this.engineStrength, this.bounceStrength];

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    setTurningDirection() {

    }

    update(elapsedSecs: number) {
        let velocity = Vector.zero();

        for (let strength of this.strengths) {
            strength.update(elapsedSecs);
            velocity = velocity.add(strength.getVelocity());
        }

        this.position = this.position.add(velocity.mult(elapsedSecs));
        this.velocity = velocity;
        this.bounceTimer -= elapsedSecs;
    }

    processBossCollisions(sides: Line[]) {
        if (this.bounceTimer > 0) {
            return;
        }

        let bounceAngle = 0;
        let bounceCount = 0;

        for (let side of sides) {
            let angle = getBounceAngle(this, side);

            if (angle !== null) {
                bounceAngle += angle;
                bounceCount += 1;
            }
        }

        if (bounceCount !== 0) {
            bounceAngle /= bounceCount;

            this.bounceStrength.speed = Math.max(Math.abs(this.engineStrength.speed * BOUNCE_SPEED_RATIO), 150);
            this.bounceStrength.angle = bounceAngle;
            this.engineStrength.speed = 0;
            this.bounceTimer = 0.2;
        }
    }

    render(view: View): void {
        let activePlayer = view.getActiveClient(Player);
        let color: ColorLike = activePlayer === this ? 'blue' : 'grey';

        view.paint({
            position: this.position,
            width: PLAYER_WIDTH,
            height: PLAYER_HEIGHT,
            color: color,
            angle: this.engineStrength.angle,
            replaceColorSrc: 'black',
            replaceColorDst: color,
            image: PLAYER_IMG
        });

        view.paint({
            key: 'name',
            text: this.name,
            position: this.position,
            height: 50,
            width: PLAYER_WIDTH * 2,
            offsetY: -PLAYER_HEIGHT / 2 - 20,
            angle: 0,
            replaceColorDst: null
        })
    }
}
globalThis.ALL_FUNCTIONS.push(Player);