import { Easing } from '../../../utils/time/easing.ts';
import { Loop } from '../../../utils/time/loop-rule.ts';

export type GraphicsAttributeOperation = 'ignore' | 'set' | 'add' | 'mult';

export type GraphicsAttribute<T> =
    | T
    | AddGraphicsAttribute<T>
    | MultGraphicsAttribute<T>
    | AnimatedGraphicsAttribute<T>

export type GraphicsAnimationProperties = {
    startTime?: number;
    duration?: number;
    delay?: number;
    delayBetweenLoops?: number;
    loop?: Loop;
    easing?: Easing;
    reverse?: boolean;
};

export type AnimatedGraphicsAttribute<T> = GraphicsAnimationProperties & {
    start?: T;
    end: T;
    operation?: GraphicsAttributeOperation;
};

export type AddGraphicsAttribute<T> = GraphicsAnimationProperties & {
    add: T;
};

export type MultGraphicsAttribute<T> = GraphicsAnimationProperties & {
    mult: T;
};

export type UnwrapAttribute<T> = T extends GraphicsAttribute<infer U> ? U : T;

export function isAddGraphicsAttribute<T>(value: object & GraphicsAttribute<T>): value is AddGraphicsAttribute<T> {
    return 'add' in value && typeof value.add !== 'function';
}

export function isMultGraphicsAttribute<T>(value: object & GraphicsAttribute<T>): value is MultGraphicsAttribute<T> {
    return 'mult' in value && typeof value.mult !== 'function';
}

export function isAnimatedGraphicsAttribute<T>(value: object & GraphicsAttribute<T>): value is AnimatedGraphicsAttribute<T> {
    return 'end' in value && typeof value.end !== 'function';
}

export function getGraphicsAttributeValue<T>(value: GraphicsAttribute<T> | undefined): T | undefined {
    if (!value || typeof value !== 'object') {
        return value;
    } else if (isAnimatedGraphicsAttribute(value)) {
        return value.end;
    } else if (!isAddGraphicsAttribute(value) && !isMultGraphicsAttribute(value)) {
        return value;
    } else {
        return undefined;
    }
}