import { DisplaySizeLike } from './display-size.ts';
import { HorizontalAlign } from './horizontal-align.ts';
import { Rect } from './rect.ts';
import { VerticalAlign } from './vertical-align.ts';

/**
 * Parameter of {@link Rect.computeGridLayout}.
 */
export class GridLayoutParams {
    /**
     * Number of items to be stored in the grid.
     */
    itemCount: number = 1;
    /**
     * Aspect ratio of an item.
     */
    itemAspectRatio: number = 1;
    /**
     * Minimum number of items a row can contain.
     */
    minRowSize: number = 1;
    /**
     * Maximum number of items a row can contain.
     */
    maxRowSize: number = Infinity;
    /**
     * Minimum number of items a column can contain.
     */
    minColumnSize: number = 1;
    /**
     * Maximum number of items a column can contain.
     */
    maxColumnSize: number = Infinity;
    /**
     * Empty space between items and between the parent rectangle and the items.
     */
    margin: DisplaySizeLike | null = null;
    /**
     * Empty space between the parent rectangle and the items. Overrides `margin`.
     */
    outerMargin: DisplaySizeLike | null = null;
    /**
     * Empty space between the items. Overrides `margin`.
     */
    innerMargin: DisplaySizeLike | null = null;
    /**
     * Empty space between the parent rectangle and the items at the left and right side. Overrides `outerMargin` and `margin`.
     */
    horizontalOuterMargin: DisplaySizeLike | null = null;
    /**
     * Empty space between the parent rectangle and the items at the top and bottom side. Overrides `outerMargin` and `margin`.
     */
    verticalOuterMargin: DisplaySizeLike | null = null;
    /**
     * Empty space between the items on a row. Overrides `innerMargin` and `margin`.
     */
    horizontalInnerMargin: DisplaySizeLike | null = null;
    /**
     * Empty space between the items on a column. Overrides `innerMargin` and `margin`.
     */
    verticalInnerMargin: DisplaySizeLike | null = null;
    /**
     * Indicates the overall direction of the grid:
     * - if `true`, rows are filled first and columns second.
     * - if `false`, columns are filled first and rows second.
     */
    appendHorizontalThenVertical: boolean = true;
    /**
     * If rows do not take the whole space horizontally, indicates where to position the cells horizontally.
     */
    horizontalAlign: HorizontalAlign | number = 'center';
    /**
     * If columns do not take the whole space vertically, indicates where to position the cells vertically.
     */
    verticalAlign: VerticalAlign | number = 'middle';
    ignoreEmptyCells: boolean = false;
};
globalThis.ALL_FUNCTIONS.push(GridLayoutParams);