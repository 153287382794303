import { ApiMethodName, isApiMethodName } from '../network/network-api.ts';
import { getSerializableAssets } from '../builtin-serializable-assets.ts';
import { Client } from '../client/client.ts';
import { DualMap } from '../../utils/data-structures/dual-map.ts';
import { Connection, initServer } from '../../utils/network/connection.ts';
import { NetworkServer, openServer } from '../../utils/network/network-server.ts';
import { Clock } from '../../utils/time/clock.ts';
import { DEFAULT_SERVER_UPDATE_INTERVAL_MS } from './server-constants.ts';
import { randomUUID } from 'crypto';
import { EventKindMapping, EventParams } from '../room/room-manager-types.ts';
import { StartGameParams } from '../start-game.ts';
import { RoomManager } from '../room/room-manager.ts';
import { Collection, getCollectionSize, iterateCollection } from '../../utils/language/collection.ts';
import { CLIENT_INTERACTION_SCHEMA, ConfigureServerParams, EmitEventParams, ProcessClientInteractionsParams, ServerOngoingInteraction } from './server-types.ts';
import { Counter } from '../../utils/data-structures/counter.ts';
import { Serializer } from '../../utils/serialization/serializer.ts';
import { FlexBuffer } from '../../utils/serialization/flex-buffer.ts';
import { Logger } from '../../utils/logging/logger.ts';
import { deserializeValueSafe } from '../../utils/type-schema/type-schema.ts';
import { Room } from '../room/room.ts';
import { SerializableAsset } from '../../utils/serialization/serializable-asset-index.ts';
import { PromiseWithResolvers, makePromise } from '../../utils/language/promise.ts';
import { CallbackLoop } from '../../utils/data-structures/callback-loop.ts';
import { Deserializer } from '../../utils/serialization/deserializer.ts';

export class Server {
    private params: StartGameParams;
    private roomManager: RoomManager;
    private networkServer: NetworkServer | null = null;
    private clientIdToConnection: DualMap<string, Connection> = new DualMap();
    private clock = new Clock();
    private buffer: FlexBuffer = new FlexBuffer();
    private serializer: Serializer;
    private deserializer: Deserializer;
    private updateLoop: CallbackLoop = new CallbackLoop(() => this.update(), DEFAULT_SERVER_UPDATE_INTERVAL_MS);
    private onStop: PromiseWithResolvers = makePromise();
    private predictableClientIdCounter: Counter | null = null;
    private predictableRoomIdCounter: Counter | null = null;

    constructor(params: StartGameParams, serializableAssets?: SerializableAsset[]) {
        this.params = params;
        this.serializer = new Serializer(serializableAssets ?? getSerializableAssets());
        this.deserializer = new Deserializer(serializableAssets ?? getSerializableAssets());
        this.roomManager = new RoomManager({
            client: null,
            server: this,
            serializer: this.serializer,
            deserializer: this.deserializer,
        });
    }

    async start() {
        this.networkServer = await openServer({ webSockets: true });

        initServer({
            webSocketServer: this.networkServer.webSocketServer!,
            onConnect: (connection) => this.onClientConnect(connection),
            onDisconnect: (connection) => this.onClientDisconnect(connection),
            onMessage: (connection, data) => this.onMessage(connection, data)
        });

        this.roomManager.start(this.params.createRootRoom);
        this.updateLoop.start();
        // Logger.debug('server start');
    }

    stop() {
        this.networkServer?.httpServer.close();
        this.networkServer?.webSocketServer?.close();

        this.updateLoop.stop();
        this.onStop.resolve();
    }

    updateClientId(currentClientId: string, newClientId: string): boolean {
        let connection = this.clientIdToConnection.getByKey(currentClientId);
        let targetConnection = this.clientIdToConnection.getByKey(newClientId);

        if (!connection || targetConnection) {
            return false;
        }

        this.clientIdToConnection.deleteByKey(currentClientId);
        this.clientIdToConnection.set(newClientId, connection);

        return true;
    }

    private generateRandomClientId(): string {
        if (this.predictableClientIdCounter) {
            return `#${this.predictableClientIdCounter.next()}`;
        }

        return `#${this.generateUuid()}`;
    }

    makePredictableUuids() {
        this.predictableClientIdCounter = new Counter();
        this.predictableRoomIdCounter = new Counter();
    }

    generateUuid(): string {
        return randomUUID();
    }

    generateRoomId(): string {
        if (this.predictableRoomIdCounter) {
            return this.predictableRoomIdCounter.next().toString();
        }

        return this.generateUuid();
    }

    private onClientConnect(connection: Connection) {
        let clientId = this.generateRandomClientId();

        this.clientIdToConnection.set(clientId, connection);
        this.roomManager.queueClientConnect({ clientId });
    }

    private onClientDisconnect(connection: Connection) {
        let clientId = this.clientIdToConnection.getByValue(connection)!;

        this.clientIdToConnection.deleteByKey(clientId);
        this.roomManager.queueClientDisconnect({ clientId });
    }

    private onMessage(connection: Connection, data: ArrayBuffer) {
        let clientId = this.clientIdToConnection.getByValue(connection)!;
        let buffer = new FlexBuffer(data);
        let methodName = buffer.readString();

        if (!isApiMethodName(methodName) || !(methodName in this)) {
            Logger.debug(`client ${clientId}: invalid server method "${methodName}"`);
            return;
        }

        (this as any)[methodName](clientId, buffer);
    }

    $processClientInteraction(clientId: string, buffer: FlexBuffer): ProcessClientInteractionsParams | undefined {
        let result = deserializeValueSafe(CLIENT_INTERACTION_SCHEMA, buffer);

        if (!result.isOk()) {
            return;
        }

        let params = result.data!;

        this.roomManager.queueClientInteraction({
            requestId: params.requestId,
            clientId,
            roomId: params.roomId,
            clientData: params.clientData,
            interactionPath: params.interactionPath,
            result: null
        });

        return params;
    }

    emitEvent<K extends keyof EventKindMapping>(params: EmitEventParams<K>) {
        this.sendMessageToClients('$queueEvent', params.clientIds, {
            kind: params.kind,
            data: params.data,
            eventId: params.eventId
        });
    }

    private sendMessageToClients<T extends (keyof Client) & ApiMethodName>(
        methodName: T,
        clientIds: Collection<string | Connection>,
        methodParams: Parameters<Client[T]>[0]
    ) {
        if (getCollectionSize(clientIds) === 0) {
            return;
        }

        this.buffer.reset();
        this.buffer.writeString(methodName);

        let buffer = this.serializer.serialize(methodParams, this.buffer).sliceUint8Array();

        for (let item of iterateCollection(clientIds)) {
            let connection: Connection | undefined = undefined;

            if (typeof item === 'string') {
                connection = this.clientIdToConnection.getByKey(item);
            } else {
                connection = item;
            }

            if (connection) {
                connection.sendMessage(buffer);
            }
        }
    }

    private update() {
        this.clock.tick();
        this.roomManager.update();
    }

    isClientConnected(clientId: string): boolean {
        return this.clientIdToConnection.hasKey(clientId);
    }

    getClock(): Clock {
        return this.clock;
    }

    getRoomManager(): RoomManager {
        return this.roomManager;
    }

    getAllRooms(): Room[] {
        return this.roomManager.getAllRooms();
    }

    waitForStop(): Promise<void> {
        return this.onStop.promise;
    }

    waitForFirstRoom() {
        return Promise.resolve();
    }

    configure(params: ConfigureServerParams) {
        if (params.updateInterval !== undefined) {
            this.updateLoop.setInterval(params.updateInterval);
        }
    }
}

export function isPersistentClientId(clientId: string): boolean {
    return !clientId.startsWith('#');
}
globalThis.ALL_FUNCTIONS.push(Server);