import { Component } from '../../framework/component/component.ts';
import { RoomApi } from '../../framework/room/room-api.ts';
import { View } from '../../framework/view/view.ts';

export class FocusController implements Component {
    async $controller(api: RoomApi): Promise<void> {
        let input = await api.waitForUserInput({
            shortcuts: {
                'Tab': () => api.focusNext(),
                'Shift_Tab': () => api.focusPrev(),
                'Escape': () => api.clearFocus(),
            }
        });

        input.selection();
    }

    render(view: View) {
        
    }
}
globalThis.ALL_FUNCTIONS.push(FocusController);